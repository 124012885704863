import React, { ReactNode } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';

type TableCellMenuProps = {
    icon: ReactNode;
    rowMenuItems: {
        name: string;
        clickHandler: VoidFunction;
    }[];
};

const TableCellMenu = ({ icon, rowMenuItems }: TableCellMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpenRowMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseRowMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (handler: VoidFunction) => {
        handler();
        handleCloseRowMenu();
    };

    return (
        <>
            <IconButton onClick={handleOpenRowMenu}>{icon}</IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleCloseRowMenu}>
                {rowMenuItems.map(item => (
                    <MenuItem key={item.name} onClick={() => handleClick(item.clickHandler)}>
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default TableCellMenu;
