import { useState } from 'react';
import { Typography, MenuItem, SelectChangeEvent, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import useStyles from './ReplyTemplateButtons.styles';
import ReplyTemplateButton from '../ReplyTemplateButton';
import SelectField from '../../SelectField/SelectField';
import { MessageTemplate } from '../../../../utils/types';

type Props = {
    selectTemplate: (value: string) => void;
    templates: MessageTemplate[];
};

const ReplyTemplates = ({ selectTemplate, templates }: Props) => {
    const { classes } = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [selected, setSelected] = useState('all');

    let favoriteTemplates: MessageTemplate[] = templates.filter((temp: MessageTemplate) => temp.favorite);
    if (isMobile) favoriteTemplates = favoriteTemplates.slice(0, 1);

    function handleChange(event: SelectChangeEvent) {
        setSelected(event.target.value);
        selectTemplate(event.target.value);
    }

    return (
        <div className={classes.root}>
            {!isMobile && (
                <div className={classes.templates}>
                    <Typography className={classes.description}>Maler:</Typography>
                    {favoriteTemplates.map((temp: MessageTemplate) => (
                        <ReplyTemplateButton selectTemplate={selectTemplate} key={temp.id + '-button'} value={temp.id}>
                            {temp.templateName}
                        </ReplyTemplateButton>
                    ))}
                </div>
            )}
            <div>
                {templates.length > 0 && (
                    <SelectField
                        handleChange={handleChange}
                        selected={selected}
                        className={classes.select}
                        rounded={false}
                        dense={true}
                    >
                        {templates.map((temp: MessageTemplate) => (
                            <MenuItem key={temp.id} value={temp.id}>
                                {temp.templateName}
                            </MenuItem>
                        ))}
                    </SelectField>
                )}
            </div>
        </div>
    );
};

export default ReplyTemplates;
