import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import _findIndex from 'lodash/findIndex';

import ConversationToolbar from '../../../components/Form/ConversationToolbar/ConversationToolbar';
import PhoneNumberDropdown from '../../../components/Form/PhoneNumberDropdown/PhoneNumberDropdown';
import { toggleFlagConversation } from '../../../state/ducks/conversations/FlagConversation';
import { setActivePhoneNumber } from '../../../state/ducks/conversations/ActiveConversation';
import { useActiveConversation, useSelector } from '../../../state/hooks';
import { SelectChangeEvent } from '@mui/material';
import { useParams } from 'react-router-dom';

const ConversationToolbarContainer = () => {
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const conversation = useActiveConversation('toolbarActiveConversation', customerId);
    const activePhoneNumber = useSelector(state => state.conversations.activePhoneNumber);
    const selected = useSelector(state => state.conversations.activePhoneNumber?.get());

    useEffect(() => {
        if (conversation) {
            const phones = conversation.customer.getPhoneNumbers();
            if (!phones) dispatch(setActivePhoneNumber(null));

            // Only set active phone if the current active phone doesnt belong to active conversation
            if (phones && _findIndex(phones, phone => phone.equals(activePhoneNumber)) === -1) {
                dispatch(setActivePhoneNumber(phones[0]));
            }
        }
    }, [conversation, activePhoneNumber, dispatch]);

    function handleChange(event: SelectChangeEvent) {
        if (conversation) {
            const phoneNumbers = conversation.customer?.getPhoneNumbers() ?? [];
            const found = phoneNumbers.find(phoneNumber => phoneNumber?.get() === event.target.value);

            if (found) dispatch(setActivePhoneNumber(found));
        }
    }

    const handleFlag = () => {
        if (conversation) dispatch(toggleFlagConversation(conversation.customer.customerNumber));
    };

    const flagged = conversation?.flagged ? true : false;

    // Create dropdown element
    let dropdown: ReactNode | null = null;
    if (conversation) {
        const phoneNumbers = conversation.customer?.getPhoneNumbers() ?? [];

        /* Workaround to mitigate selected out-of-range error (Material-UI) */
        if (selected && phoneNumbers.map(phone => phone.get()).includes(selected)) {
            dropdown = (
                <PhoneNumberDropdown phoneNumbers={phoneNumbers} selected={selected} handleChange={handleChange} />
            );
        }
    }

    if (conversation) {
        return (
            <ConversationToolbar
                flagged={flagged}
                handleFlag={handleFlag}
                hideFlagButton={isEmpty(conversation)}
                isEmpty={isEmpty(conversation)}
            >
                {dropdown}
            </ConversationToolbar>
        );
    }
    return null;
};

export default ConversationToolbarContainer;
