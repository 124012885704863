import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _findIndex from 'lodash/findIndex';

import { useFeatureFlags, useNotifications, useSelector } from '../../../../state/hooks';
import { getFutureOrders } from '../../../../state/ducks/pck/GetFutureOrders';
import Order from '../../../../models/Order';
import PhoneNumber from '../../../../models/PhoneNumber';
import { ThemePaletteMainColors } from '../../../../utils/types';
import SidebarLinkContainer from '../../../Generic/SidebarLinkContainer/SidebarLinkContainer';

type Props = {
    link: string;
    text: string;
    isActive: boolean;
    notificationColor: ThemePaletteMainColors;
    onClick?: VoidFunction;
};

const OrdersNotificationContainer = ({ link, text, isActive, notificationColor, onClick }: Props) => {
    const dispatch = useDispatch();
    const featureFlags = useFeatureFlags();
    const futureOrders: Order[] | null = useSelector(state => state.pck.unsortedFutureOrders);
    const notifications = useNotifications();

    useEffect(() => {
        if (notifications?.length > 0 && featureFlags && featureFlags['messageSystem'].isActive)
            dispatch(getFutureOrders(false));
    }, [notifications, dispatch]);

    let notificationNumber = 0;
    if (notifications && futureOrders) {
        const phoneNumbers: PhoneNumber[] = [];

        futureOrders.forEach(order => {
            if (!order.webbooking) {
                phoneNumbers.push.apply(phoneNumbers, order.customer?.getPhoneNumbers() ?? []);
            }
        });

        for (const noti of notifications) {
            if (_findIndex(phoneNumbers, num => num.get() === noti.phoneNumber.get()) !== -1) notificationNumber++;
        }
    }

    return (
        <SidebarLinkContainer
            link={link}
            text={text}
            isActive={isActive}
            onClick={onClick}
            notifications={[{ count: notificationNumber }]}
        />
    );
};

export default OrdersNotificationContainer;
