import { ReactNode } from 'react';
import { DialogProps, Typography } from '@mui/material';

import { makeStyles } from '../../../state/hooks';
import Modal from '../Modal/Modal';

export type Props = {
    openModal: boolean;
    maxWidth: DialogProps['maxWidth'];
    title: string;
    description: string;
    leftButton: ReactNode;
    rightButton: ReactNode;
    toggleModal: () => void;
};

/**
 * Generic prompt modal component for prompting the user with a choice.
 */
const PromptModal = ({ openModal, maxWidth, title, description, leftButton, rightButton, toggleModal }: Props) => {
    const { classes } = useStyles();

    return (
        <Modal toggleModal={toggleModal} openModal={openModal} maxWidth={maxWidth}>
            <div className={classes.root}>
                <Typography variant='h3' className={classes.modalTitle}>
                    {title}
                </Typography>
                <Typography className={classes.modalBodyText}>{description}</Typography>
                <div className={classes.buttons}>
                    {leftButton}
                    {rightButton}
                </div>
            </div>
        </Modal>
    );
};
const useStyles = makeStyles()({
    root: {
        padding: 30,
    },
    modalTitle: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    modalBodyText: {
        margin: '8px 0 20px 0',
    },
    buttons: {
        '& > *': {
            marginRight: 10,
        },
    },
});

export default PromptModal;
