import { OrderTemplate } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';
import { authFetch, createFailedAction } from '../../../utils/helpers';

export const GET_ORDER_TEMPLATES = 'GET_ORDER_TEMPLATES';
export const GET_ORDER_TEMPLATES_SUCCESS = 'GET_ORDER_TEMPLATES_SUCCESS';
export const GET_ORDER_TEMPLATES_FAILED = 'GET_ORDER_TEMPLATES_FAILED';

const getOrderTemplates = () => ({
    type: GET_ORDER_TEMPLATES,
});

export const getOrderTemplatesSuccess = (templates: OrderTemplate[]) => ({
    type: GET_ORDER_TEMPLATES_SUCCESS,
    payload: { templates },
});

const getOrderTemplatesFailed = createFailedAction(GET_ORDER_TEMPLATES_FAILED);

export const getOrdersTemplates = (): AppThunk => async (dispatch, getState, fb) => {
    dispatch(getOrderTemplates());
    const apiUrl = getState().system.apiURL;
    if (!apiUrl) throw new Error('Could not fetch API URL.');
    try {
        const response = await authFetch(fb, '/orderTemplates', 'GET', undefined, apiUrl);
        const templates: OrderTemplate[] = await response.json();

        dispatch(getOrderTemplatesSuccess(templates));
    } catch (error) {
        dispatch(getOrderTemplatesFailed(error));
    }
};
