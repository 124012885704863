import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import InventorySearchCard from '../../../components/Cards/InventorySearchCard/InventorySearchCard';
import InventorySearchResultsCard from '../../../components/Cards/InventorySearchResultsCard/InventorySearchResultsCard';
import Row from '../../../components/Layouts/Row/Row';
import ArticleModal from '../../../components/Modals/ArticleModal/ArticleModal';
import { Article } from '../../../models/Article';
import { inventorySearch, resetInventorySearch } from '../../../state/ducks/inventory/InventorySearch';
import { setArticleModal } from '../../../state/ducks/inventory/SetArticleModal';
import { getArticleGroups } from '../../../state/ducks/statistics/StatisticsFilter';
import { closeArticleModal, openArticleModal } from '../../../state/ducks/system/ModalControl';
import { useSelector } from '../../../state/hooks';
import { InventorySearchObject } from '../../../utils/types';
import useStyles from './InventorySearch.styles';
import { getArticleAttributes } from '../../../state/ducks/inventory/ArticleAttributes';
import { useGetArticleManufacturersQuery } from '../../../services/ArticleManufacturers';
import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';

const InventorySearch = () => {
    const { classes } = useStyles();
    const openModal = useSelector(state => state.system.openArticleModal);
    const {
        articleAttributes,
        articleModal: article,
        loadingArticleModal: loadingArticle,
        inventorySearchResults: searchResults,
    } = useSelector(state => state.inventory);
    const { primaryArticleGroups, secondaryArticleGroups, tertiaryArticleGroups } = useSelector(
        state => state.statistics
    );
    const dispatch = useDispatch();

    const { data, isLoading, isSuccess } = useGetArticleManufacturersQuery();

    const mockHandleOpenArticleModal = (article: Article) => {
        dispatch(openArticleModal());
        dispatch(setArticleModal(article));
    };

    const handleToggleModal = () => {
        dispatch(closeArticleModal());
    };

    const handleSearch = (searchObject: InventorySearchObject) => {
        dispatch(inventorySearch(searchObject));
    };

    const handleGetArticleAttributes = (articleGroup1Id: number, articleGroup2Id: number, articleGroup3Id: number) => {
        dispatch(
            getArticleAttributes(
                articleGroup1Id === 0 ? null : articleGroup1Id,
                articleGroup2Id === 0 ? null : articleGroup2Id,
                articleGroup3Id === 0 ? null : articleGroup3Id
            )
        );
    };

    const handleResetSearch = () => {
        dispatch(resetInventorySearch());
    };

    useEffect(() => {
        dispatch(getArticleGroups());
    }, [dispatch]);

    if (isLoading && !isSuccess) return <SubtleSpinner />;

    return (
        <Box className={classes.root}>
            <Row gap={4}>
                <InventorySearchResultsCard
                    results={searchResults}
                    handleOpenArticleModal={mockHandleOpenArticleModal}
                />
                {data && articleAttributes && primaryArticleGroups && secondaryArticleGroups && tertiaryArticleGroups && (
                    <InventorySearchCard
                        articleGroups={{
                            1: primaryArticleGroups,
                            2: secondaryArticleGroups,
                            3: tertiaryArticleGroups,
                        }}
                        articleAttributes={articleAttributes}
                        articleManufacturers={data}
                        handleSearch={handleSearch}
                        handleGetArticleAttributes={handleGetArticleAttributes}
                        handleResetSearch={handleResetSearch}
                    />
                )}
                {article && (
                    <ArticleModal
                        open={openModal}
                        loading={loadingArticle}
                        handleToggleModal={handleToggleModal}
                        article={article}
                    />
                )}
            </Row>
        </Box>
    );
};

export default InventorySearch;
