import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    tableRow: {
        cursor: 'pointer',
        backgroundColor: '#fff',
        transition: `background ${theme.transitions.easing.easeOut} ${theme.transitions.duration.standard}ms`,
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    expandedCell: {
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: '#f5f5f5',
    },
    closedRow: {
        borderBottom: 'none',
    },
    expandedRowBox: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));
