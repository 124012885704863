import { Box, Paper, Typography } from '@mui/material';

import Column from '../../Layouts/Column/Column';
import useStyles from './AllocatedPlacementCard.styles';

export type Props = {
    title?: string;
    variant: 'normal' | 'compact';
    articleNumber: string;
    articleName: string;
    locationName: string;
    placementName: string | null;
    handleUndo: VoidFunction;
};

const AllocatedPlacementCard = ({
    title,
    variant,
    articleName,
    articleNumber,
    locationName,
    placementName,
    handleUndo,
}: Props) => {
    const { classes } = useStyles();

    return (
        <Paper className={classes.root}>
            {title && (
                <Typography variant='h3' className={classes.title}>
                    {title}
                </Typography>
            )}
            <Column gap={2}>
                <Box>
                    <Typography variant='caption'>{articleNumber}</Typography>
                    <Typography variant='h5'>{articleName}</Typography>
                </Box>
                <Box className={classes.locationsBox}>
                    <Box>
                        <Typography variant='subtitle2' sx={{ mb: 1 }}>
                            Plassering:
                        </Typography>
                        <Typography variant='body1' className={variant === 'normal' ? classes.placementText : ''}>
                            {placementName ?? locationName}
                        </Typography>
                    </Box>
                    {placementName && (
                        <Box>
                            <Typography variant='subtitle2'>Lokasjon:</Typography>
                            <Typography variant='body1'>{locationName}</Typography>
                        </Box>
                    )}
                </Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color='error' onClick={handleUndo}>
                        Angre
                    </Button>
                </Box> */}
            </Column>
        </Paper>
    );
};

export default AllocatedPlacementCard;
