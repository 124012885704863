import { Box, Fab, Paper, SwipeableDrawer, Theme, useMediaQuery } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Filter } from 'react-feather';

import useStyles from './SwipeableDrawerContainer.styles';

type Props = {
    inlineComponent: ReactNode;
    floatingButtonText: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const SwipeableDrawerContainer = ({ inlineComponent, floatingButtonText, open, setOpen }: Props) => {
    const { classes, cx } = useStyles();

    const displayInDrawer = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
    return (
        <>
            {displayInDrawer ? (
                <>
                    <Fab
                        className={classes.floatingActionButton}
                        onClick={() => setOpen(!open)}
                        color='primary'
                        variant='extended'
                    >
                        <Filter size={20} className={classes.filterIcon} />
                        {floatingButtonText}
                    </Fab>
                    <SwipeableDrawer
                        anchor={'right'}
                        open={open}
                        onClose={() => setOpen(false)}
                        style={{ width: 300 }}
                        onOpen={() => setOpen(true)}
                        PaperProps={{ className: classes.drawerPaper }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <Box className={cx(classes.drawerLayout, classes.filterLayout)}>{inlineComponent}</Box>
                    </SwipeableDrawer>
                </>
            ) : (
                <Paper className={cx(classes.root, classes.filterLayout)}>{inlineComponent}</Paper>
            )}
        </>
    );
};

export default SwipeableDrawerContainer;
