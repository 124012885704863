import { makeStyles } from '../../../state/hooks';
import constants from '../../../styles/styleConstants';

export default makeStyles()(theme => ({
    root: {
        padding: '10px 30px',
        boxShadow: constants.lightBoxShadow,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
    },
    hideFlagButton: {
        justifyContent: 'flex-end',
    },
    deleteButton: {
        color: 'red',
        borderColor: 'red',
        marginRight: 10,
    },
    modalTitle: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    modalBodyText: {
        margin: '8px 0 20px 0',
    },
    button: {
        fontSize: '0.95rem',
        marginRight: 10,
    },
    flagButton: {
        backgroundColor: 'white',
    },
    flagged: {
        backgroundColor: theme.palette.warning.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
    unflagged: {
        color: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
    },
}));
