import { Button, ButtonProps, Paper, Popover, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useState } from 'react';
import Axis from '../../Layouts/Axis/Axis';

import useStyles from './PopoverPromptButton.styles';

export type Props = {
    buttonProps: ButtonProps;
    buttonText: string;
    popoverTitle: string;
    children: ReactNode;
    confirmText: string;
    onConfirm: VoidFunction;
};

const PopoverPromptButton = ({ buttonProps, buttonText, popoverTitle, children, confirmText, onConfirm }: Props) => {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirm = () => {
        onConfirm();
        handleClose();
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button {...buttonProps} onClick={handleClick}>
                {buttonText}
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Paper className={classes.popoverBox} elevation={2}>
                    <Typography variant='h3' className={classes.title}>
                        {popoverTitle}
                    </Typography>
                    <Box className={classes.children}>{children}</Box>
                    <Axis gap={1}>
                        <Button onClick={handleClose}>Avbryt</Button>
                        <Button variant='contained' onClick={handleConfirm}>
                            {confirmText}
                        </Button>
                    </Axis>
                </Paper>
            </Popover>
        </>
    );
};

export default PopoverPromptButton;
