import { authFetch } from '../../../utils/helpers';
import { AppThunk } from '../../reduxTypes';
import Customer from '../../../models/Customer';

export const CUSTOMER_SEARCH_STARTED = 'CUSTOMER_SEARCH_STARTED';
export const CUSTOMER_SEARCH_SUCCESS = 'CUSTOMER_SEARCH_SUCCESS';
export const CUSTOMER_SEARCH_FAILED = 'CUSTOMER_SEARCH_FAILED';
export const CLEAR_CUSTOMER_SEARCH = 'CLEAR_CUSTOMER_SEARCH';

const customerSearchStarted = () => ({
    type: CUSTOMER_SEARCH_STARTED,
});

const customerSearchSuccess = (customerSearchResult: Customer[]) => ({
    type: CUSTOMER_SEARCH_SUCCESS,
    payload: {
        customerSearchResult,
    },
});

const customerSearchFailed = (error: unknown) => ({
    type: CUSTOMER_SEARCH_FAILED,
    payload: {
        error,
    },
});

export const clearCustomerSearch = () => ({
    type: CLEAR_CUSTOMER_SEARCH,
});

/**
 * Ducks that searches for a customer in the PCK-database.
 * @param query search query can contain name, phone numbers or customer number
 */
export const customerSearch =
    (query: string): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(customerSearchStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const response = await authFetch(fb, '/customers?query=' + query, 'GET', undefined, apiUrl);
            const results: unknown[] = await response.json();

            const customerList = results.map(customer => new Customer(customer));
            dispatch(customerSearchSuccess(customerList));
        } catch (error) {
            dispatch(customerSearchFailed(error));
        }
    };
