import {
    Box,
    Button,
    Collapse,
    Divider,
    FormHelperText,
    IconButton,
    InputAdornment,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Delete, X } from 'react-feather';

import SwipeableDrawerContainer from '../../../containers/Generic/SwipeableDrawerContainer/SwipeableDrawerContainer';
import { ArticleAttribute, ArticleGroup, ArticleManufacturer, InventorySearchObject } from '../../../utils/types';
import MultipleSelectDropdown from '../../Form/MultipleSelectDropdown/MultipleSelectDropdown';
import Column from '../../Layouts/Column/Column';
import InventorySearchAttributes from './InventorySearchAttributes/InventorySearchAttributes';
import useStyles from './InventorySearchCard.styles';

const initialSearchValues: InventorySearchObject = {
    searchQuery: '',
    primaryArticleGroups: [0],
    secondaryArticleGroups: [0],
    tertiaryArticleGroups: [0],
    articleAttributes: [],
    manufacturers: [0],
};

export type Props = {
    articleGroups: {
        1: ArticleGroup[];
        2: ArticleGroup[];
        3: ArticleGroup[];
    };
    articleAttributes: ArticleAttribute[];
    articleManufacturers: ArticleManufacturer[];
    handleSearch: (searchObject: InventorySearchObject) => void;
    handleGetArticleAttributes: (articleGroup1Id: number, articleGroup2Id: number, articleGroup3Id: number) => void;
    handleResetSearch: VoidFunction;
};

const InventorySearchCard = ({
    articleGroups,
    articleAttributes,
    articleManufacturers,
    handleSearch,
    handleGetArticleAttributes,
    handleResetSearch,
}: Props) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const [searchObject, setSearchObject] = useState<InventorySearchObject>(initialSearchValues);
    const inputRef = useRef<HTMLInputElement>(null);
    const [openArticleAttribute, setOpenArticleAttribute] = useState(false);

    const onSearch = () => {
        if (initialSearchValues !== searchObject) {
            handleSearch(searchObject);
            setOpen(false);
        }
    };

    const onChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchPropKey = event.target.name as keyof InventorySearchObject;
        if (searchPropKey) {
            const obj = {
                ...searchObject,
                [searchPropKey]: event.target.value,
            };
            setSearchObject(obj);
        }
    };

    const onChangeMultipleSelect = (event: SelectChangeEvent<number[]>) => {
        const targetValues = event.target.value;
        const targetName = event.target.name;
        if (Array.isArray(targetValues)) {
            let activeIds = targetValues.filter(id => id !== 0);
            if (!activeIds.length) activeIds = [0];
            setSearchObject({
                ...searchObject,
                [targetName]: activeIds,
            });
        }
    };

    const handleChangeArticleAttributes = (articleAttributeId: number, event: SelectChangeEvent<number[]>) => {
        let targetValues = event.target.value;
        if (Array.isArray(targetValues)) {
            let activeIds = targetValues.filter(id => id !== 0);
            if (!activeIds.length) activeIds = [0];
            const activeAttribute = searchObject.articleAttributes.find(
                a => a.articleAttributeId === articleAttributeId
            );
            if (activeAttribute) {
                const index = searchObject.articleAttributes.indexOf(activeAttribute);

                const newActiveAttributes = [...searchObject.articleAttributes];

                if (!activeIds.length) activeIds = [0];

                newActiveAttributes[index] = {
                    articleAttributeId,
                    articleAttributeValues: activeIds,
                };
                setSearchObject({
                    ...searchObject,
                    articleAttributes: newActiveAttributes,
                });
            } else {
                const newActiveAttributes = [...searchObject.articleAttributes];
                newActiveAttributes.push({
                    articleAttributeId: articleAttributeId,
                    articleAttributeValues: activeIds,
                });
                setSearchObject({
                    ...searchObject,
                    articleAttributes: newActiveAttributes,
                });
            }
        }
    };

    const onReset = () => {
        setSearchObject(initialSearchValues);
        handleResetSearch();
        setOpenArticleAttribute(false);
        inputRef.current?.focus();
        //setOpen(false);
    };

    useEffect(() => {
        if (
            searchObject.primaryArticleGroups.length === 1 &&
            searchObject.secondaryArticleGroups.length === 1 &&
            searchObject.tertiaryArticleGroups.length === 1 &&
            !isSearchObjectEmpty
        ) {
            handleGetArticleAttributes(
                searchObject.primaryArticleGroups[0],
                searchObject.secondaryArticleGroups[0],
                searchObject.tertiaryArticleGroups[0]
            );
        }
    }, [searchObject.primaryArticleGroups, searchObject.secondaryArticleGroups, searchObject.tertiaryArticleGroups]);

    const isSearchObjectEmpty = Object.keys(searchObject).every(key => {
        if (key === 'primaryArticleGroups' || key === 'secondaryArticleGroups' || key === 'tertiaryArticleGroups') {
            return JSON.stringify(searchObject[key]) === JSON.stringify(initialSearchValues[key]);
        } else return true;
    });

    const inlineComponent = (
        <>
            <Typography variant='h3' className={classes.title}>
                Søk etter vare
            </Typography>
            <Column gap={3}>
                <TextField
                    ref={inputRef}
                    label='Søk'
                    variant='outlined'
                    value={searchObject.searchQuery}
                    placeholder='Søk etter navn'
                    autoComplete='off'
                    name='searchQuery'
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            onSearch();
                        }
                    }}
                    onChange={event => onChangeInput(event)}
                    InputProps={{
                        endAdornment: (
                            <>
                                {searchObject.searchQuery !== '' && (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            size='medium'
                                            onClick={() => {
                                                inputRef.current?.focus();
                                                setSearchObject({ ...searchObject, searchQuery: '' });
                                            }}
                                        >
                                            <X size={20} />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
                <MultipleSelectDropdown
                    label='Varegruppe 1'
                    options={articleGroups[1].map(ag => ({ id: ag.articleGroupId, value: ag.articleGroupName }))}
                    name={'primaryArticleGroups'}
                    defaultValueName='Alle varegrupper'
                    activeOptions={searchObject.primaryArticleGroups}
                    handleChange={onChangeMultipleSelect}
                />
                <MultipleSelectDropdown
                    label='Varegruppe 2'
                    options={articleGroups[2].map(ag => ({ id: ag.articleGroupId, value: ag.articleGroupName }))}
                    name={'secondaryArticleGroups'}
                    defaultValueName='Alle varegrupper'
                    activeOptions={searchObject.secondaryArticleGroups}
                    handleChange={onChangeMultipleSelect}
                />
                <MultipleSelectDropdown
                    label='Varegruppe 3'
                    options={articleGroups[3].map(ag => ({ id: ag.articleGroupId, value: ag.articleGroupName }))}
                    name={'tertiaryArticleGroups'}
                    defaultValueName='Alle varegrupper'
                    activeOptions={searchObject.tertiaryArticleGroups}
                    handleChange={onChangeMultipleSelect}
                />
                <MultipleSelectDropdown
                    label='Vareprodusenter'
                    options={articleManufacturers.map(am => ({
                        id: am.manufacturerId,
                        value: am.manufacturerName,
                    }))}
                    name={'manufacturers'}
                    defaultValueName='Alle vareprodusenter'
                    activeOptions={searchObject.manufacturers}
                    handleChange={onChangeMultipleSelect}
                />
                {!isSearchObjectEmpty && articleAttributes.length > 0 && (
                    <>
                        <Box>
                            <Divider />
                        </Box>
                        <Box>
                            <Typography variant='h5'>Vareattributter</Typography>
                            <InventorySearchAttributes
                                handleChange={handleChangeArticleAttributes}
                                activeAttributesValues={searchObject.articleAttributes}
                                articleAttributes={articleAttributes}
                            />
                        </Box>
                    </>
                )}
                <Box className={classes.bottomButtons}>
                    <Button onClick={onReset} variant='text' color='primary'>
                        Nullstill
                    </Button>
                    <Button onClick={onSearch} variant='contained' color='primary'>
                        Søk
                    </Button>
                </Box>
            </Column>
        </>
    );

    return (
        <SwipeableDrawerContainer
            inlineComponent={inlineComponent}
            floatingButtonText='Søk etter vare'
            open={open}
            setOpen={setOpen}
        />
    );
};

export default InventorySearchCard;
