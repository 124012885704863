import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';

import { ArticleAttribute, ProductAttribute } from '../../../utils/types';
import Axis from '../../Layouts/Axis/Axis';

export type Props = {
    attributes: ArticleAttribute[];
    productAttributes: ProductAttribute[];
    handleSaveAttributes: (selectedAttributes: SelectAttribute[]) => void;
};

export type SelectAttribute = {
    attributeId: number;
    attributeValueId: number | 'noValue';
    status: 'edited' | 'pristine';
};

const ArticleAttributesForm = ({ attributes, productAttributes, handleSaveAttributes }: Props) => {
    const [selectedAttributes, setSelectedAttributes] = useState<SelectAttribute[]>([]);
    const [pristineAttributes, setPristineAttributes] = useState<SelectAttribute[]>([]);
    const [isPristine, setIsPristine] = useState(true);

    const handleChange = (event: SelectChangeEvent<number | string>, attributeId: number) => {
        const attributeValueId = typeof event.target.value === 'number' ? event.target.value : 'noValue';

        const newSelectedAttributes: SelectAttribute[] = selectedAttributes.map(attribute => {
            if (attribute.attributeId === attributeId) {
                return { ...attribute, attributeValueId, status: 'edited' };
            }
            return attribute;
        });
        setSelectedAttributes(newSelectedAttributes);
    };

    const handleSave = () => {
        handleSaveAttributes(selectedAttributes);
    };

    useEffect(() => {
        setIsPristine(JSON.stringify(selectedAttributes) === JSON.stringify(pristineAttributes));
    }, [selectedAttributes, pristineAttributes]);

    useEffect(() => {
        const result: SelectAttribute[] = attributes.map(attr => {
            const productAttribute = productAttributes.find(x => x.articleAttributeId === attr.articleAttributeId);
            if (productAttribute !== undefined) {
                return {
                    attributeId: attr.articleAttributeId,
                    attributeValueId: productAttribute.articleAttributeValueId,
                    status: 'pristine',
                };
            } else {
                return { attributeId: attr.articleAttributeId, attributeValueId: 'noValue', status: 'pristine' };
            }
        });

        setSelectedAttributes(result);
        setPristineAttributes(result);
    }, [attributes, productAttributes]);

    return (
        <>
            <Grid container spacing={2}>
                {attributes.map(attribute => {
                    const activeAttributeValueId = selectedAttributes.find(
                        x => x.attributeId === attribute.articleAttributeId
                    )?.attributeValueId;
                    const inputId = 'attributeInput_' + attribute.articleAttributeId;
                    return (
                        <>
                            <Grid item sm='auto' xs={12} key={attribute.articleAttributeId}>
                                <FormControl sx={{ minWidth: 100 }}>
                                    <InputLabel id={inputId}>{attribute.name}</InputLabel>
                                    <Select
                                        labelId={inputId}
                                        name={attribute.name}
                                        label={attribute.name}
                                        value={activeAttributeValueId ?? 'noValue'}
                                        onChange={event => handleChange(event, attribute.articleAttributeId)}
                                    >
                                        <MenuItem value='noValue'>(ingen verdi)</MenuItem>
                                        {attribute.articleAttributeValues &&
                                            attribute.articleAttributeValues.map(value => (
                                                <MenuItem
                                                    key={
                                                        attribute.articleAttributeId +
                                                        '_' +
                                                        value.articleAttributeValueId
                                                    }
                                                    value={value.articleAttributeValueId}
                                                >
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    );
                })}
            </Grid>
            {!attributes.length && <Typography variant='body1'>Ingen attributter funnet</Typography>}
            {!isPristine && (
                <Box mt={2}>
                    <Axis isResponsive>
                        <Button onClick={() => setSelectedAttributes(pristineAttributes)}>Nullstill</Button>
                        <Button variant='contained' onClick={handleSave}>
                            Lagre endringer
                        </Button>
                    </Axis>
                </Box>
            )}
        </>
    );
};

export default ArticleAttributesForm;
