import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {},
    calendar: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridGap: 10,
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            gridGap: 5,
        },
    },
}));
