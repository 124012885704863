import { findDatesFromTimePeriod } from '../../../utils/date';
import { authFetch, createFailedAction, createUrlParamsFromArray } from '../../../utils/helpers';
import { AggregateTimePeriods, DataPointSeries } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const GET_GRAPH_DATA_STARTED = 'GET_GRAPH_DATA_STARTED';
export const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS';
export const GET_GRAPH_DATA_FAILED = 'GET_GRAPH_DATA_FAILED';

const getGraphDataStarted = () => ({
    type: GET_GRAPH_DATA_STARTED,
});

const getGraphDataSuccess = (data: DataPointSeries[]) => ({
    type: GET_GRAPH_DATA_SUCCESS,
    payload: { data },
});

const getGraphDataFailed = createFailedAction(GET_GRAPH_DATA_FAILED);

export const getGraphData = (): AppThunk => async (dispatch, getState, fb) => {
    dispatch(getGraphDataStarted());
    const apiUrl = getState().system.apiURL;
    if (!apiUrl) throw new Error('Could not fetch API URL.');
    try {
        const { years, timePeriod, timePeriodScope, activeArticleGroupIds, orderTemplateIds } =
            getState().statistics.filter;

        if (!years.length) throw new Error('Choose at least one year in the turnover filter');

        const { fromDate, toDate } = findDatesFromTimePeriod(timePeriod, timePeriodScope, years);
        const aggregateOn = AggregateTimePeriods[timePeriod];

        const articleGroupsParams = getArticleGroupsParams(activeArticleGroupIds);

        const params = new URLSearchParams();
        params.append('fromDate', fromDate);
        params.append('toDate', toDate);
        params.append('aggregateOn', aggregateOn);
        years.forEach(year => params.append('years', year.toString()));
        if (orderTemplateIds !== null)
            orderTemplateIds.forEach(id => params.append('orderTemplateIds[]', id.toString()));

        const encodedParams = params.toString();

        const url = `/turnover?${encodedParams}${articleGroupsParams}`;
        const response = await authFetch(fb, url, 'GET', undefined, apiUrl);
        const data: DataPointSeries[] = await response.json();
        data.sort((a: DataPointSeries, b: DataPointSeries) => a.year - b.year).reverse();

        dispatch(getGraphDataSuccess(data));
    } catch (error) {
        dispatch(getGraphDataFailed(error));
    }
};

export const GET_TURNOVER_COMPARISON_DATA_STARTED = 'GET_TURNOVER_COMPARISON_DATA_STARTED';
export const GET_TURNOVER_COMPARISON_DATA_SUCCESS = 'GET_TURNOVER_COMPARISON_DATA_SUCCESS';
export const GET_TURNOVER_COMPARISON_DATA_FAILED = 'GET_TURNOVER_COMPARISON_DATA_FAILED';

const getTurnoverComparisonDataStarted = () => ({
    type: GET_TURNOVER_COMPARISON_DATA_STARTED,
});

const getTurnoverComparisonDataSuccess = (data: DataPointSeries[]) => ({
    type: GET_TURNOVER_COMPARISON_DATA_SUCCESS,
    payload: { data },
});

const getTurnoverComparisonDataFailed = createFailedAction(GET_TURNOVER_COMPARISON_DATA_FAILED);

export const getTurnoverComparisonData = (): AppThunk => async (dispatch, getState, fb) => {
    dispatch(getTurnoverComparisonDataStarted());
    const apiUrl = getState().system.apiURL;
    if (!apiUrl) throw new Error('Could not fetch API URL.');
    try {
        const { years, timePeriod, timePeriodScope, activeArticleGroupIds, orderTemplateIds } =
            getState().statistics.filter;

        if (!years.length) throw new Error('Choose at least one year in the turnover filter');

        const { fromDate, toDate } = findDatesFromTimePeriod(timePeriod, timePeriodScope, years);
        const aggregateOn = 'year';

        const articleGroupsParams = getArticleGroupsParams(activeArticleGroupIds);

        const params = new URLSearchParams();
        params.append('fromDate', fromDate);
        params.append('toDate', toDate);
        params.append('aggregateOn', aggregateOn);
        years.forEach(year => params.append('years', year.toString()));
        if (orderTemplateIds !== null)
            orderTemplateIds.forEach(id => params.append('orderTemplateIds[]', id.toString()));

        const encodedParams = params.toString();

        const url = `/turnover?${encodedParams}${articleGroupsParams}`;

        const response = await authFetch(fb, url, 'GET', undefined, apiUrl);

        const data: DataPointSeries[] = await response.json();
        data.sort((a: DataPointSeries, b: DataPointSeries) => a.year - b.year).reverse();

        dispatch(getTurnoverComparisonDataSuccess(data));
    } catch (error) {
        dispatch(getTurnoverComparisonDataFailed(error));
    }
};

const getArticleGroupsParams = (activeArticleGroupIds: {
    primaryArticleGroups: number[] | null;
    secondaryArticleGroups: number[] | null;
    tertiaryArticleGroups: number[] | null;
}): string => {
    let articleGroup1Param = '';
    if (activeArticleGroupIds.primaryArticleGroups !== null)
        articleGroup1Param = '&' + createUrlParamsFromArray(activeArticleGroupIds.primaryArticleGroups, 'mainGroupIds');
    let articleGroup2Param = '';
    if (activeArticleGroupIds.secondaryArticleGroups !== null)
        articleGroup1Param =
            '&' + createUrlParamsFromArray(activeArticleGroupIds.secondaryArticleGroups, 'articleGroup2Ids');
    let articleGroup3Param = '';
    if (activeArticleGroupIds.tertiaryArticleGroups !== null)
        articleGroup1Param =
            '&' + createUrlParamsFromArray(activeArticleGroupIds.tertiaryArticleGroups, 'articleGroup3Ids');

    return [articleGroup1Param, articleGroup2Param, articleGroup3Param].join('');
};
