import { isRejectedWithValue } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { getFirebase } from 'react-redux-firebase';
import { Middleware, MiddlewareAPI } from 'redux';

import { RootState } from '../state/reduxTypes';
import { isProdEnvironment } from '../utils/helpers';

const rawBaseQuery = (baseUrl: string) =>
    fetchBaseQuery({
        baseUrl,
        responseHandler: async (response: Response) => {
            if (response.ok) {
                const responseData = await response.json();
                if (responseData.error) throw new Error(responseData.error.title);
                return responseData;
            } else {
                throw Error(response.statusText);
            }
        },
        validateStatus: (response, result) => {
            if (result.error && result.error !== null) return false;
            return response.status < 400;
        },
        prepareHeaders: async headers => {
            const token = await getFirebase().auth().currentUser?.getIdToken();
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    });

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const state = api.getState() as RootState;
    const apiUrl = isProdEnvironment() ? state.system.apiURL : 'http://localhost:3001/v1';
    if (!apiUrl)
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'No API URL found.',
            },
        };
    return rawBaseQuery(apiUrl)(args, api, extraOptions);
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
    tagTypes: ['Orders', 'Bookings', 'BookingCapacity', 'SpecialCapacity', 'BikeBoxes', 'BikeBoxAvailability'],
});

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
        api.dispatch({
            type: 'FETCH_FAILED',
            payload: {
                error: action.payload.data.error,
            },
        });
    }

    return next(action);
};
