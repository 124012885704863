import { OrderLine } from '../utils/types';
import OrderLineError from './OrderLineError';
import Customer from './Customer';

/**
 * Represents an order
 */
export default class Order {
    orderId: number;
    templateId: number;
    templateName: string;
    customer: Customer | null;
    labled: string;
    theirRef: string;
    price: number;
    orderDate: Date;
    deliveryDate: Date | null;
    internalInfo: string | null;
    externalInfo: string | null;
    employeeName: string;
    employeeId: number;
    webbooking: boolean;
    hashedId: string | null;
    description: string | null;
    promoCode?: string;
    soldHere?: boolean;
    invoiceDate?: Date | null; // Only used in customer history
    orderHistory?: Order[];
    orderLines?: OrderLine[] | null; // Used in customer history
    errors?: {
        orderErrorIds: string[];
        orderLinesErrors: {
            [key: string]: OrderLineError;
        };
    };

    constructor(rawOrder: any) {
        this.orderId = rawOrder?.orderId;
        this.templateId = rawOrder?.templateId;
        this.templateName = rawOrder?.templateName;
        this.customer = new Customer(rawOrder);
        this.labled = rawOrder?.labled;
        this.theirRef = rawOrder?.theirRef;
        this.price = rawOrder?.price;
        this.orderDate = new Date(rawOrder?.orderDate);
        this.deliveryDate = rawOrder?.deliveryDate ? new Date(rawOrder.deliveryDate) : null;
        this.internalInfo = rawOrder?.internalInfo;
        this.externalInfo = rawOrder?.externalInfo;
        this.employeeName = rawOrder?.employeeName;
        this.employeeId = rawOrder?.employeeId;
        this.webbooking = rawOrder?.webbooking ?? false;
        this.hashedId = rawOrder?.hashedId ?? null;
        this.description = rawOrder?.description ?? null;
        this.invoiceDate = new Date(rawOrder?.invoiceDate) ?? null;
        this.promoCode = rawOrder?.promoCode ?? undefined;
        this.soldHere = rawOrder?.soldHere ?? undefined;

        if (rawOrder?.orderErrorIds) {
            this.errors = {
                orderErrorIds: rawOrder?.orderErrorIds,
                orderLinesErrors: rawOrder?.orderLineErrors,
            };
        }
    }
}
