import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { formatDateToString } from '../../../utils/date';
import { getLockEventMessage } from '../../../utils/helpers';
import { BikeBoxLockEvent } from '../../../utils/types';
import useStyles from './LockEventHistoryTable.styles';

export type Props = {
    lockEventHistory: BikeBoxLockEvent[];
};

const LockEventHistoryTable = ({ lockEventHistory }: Props) => {
    const { classes } = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Dato</TableCell>
                    <TableCell>Klokkeslett</TableCell>
                    <TableCell>Navn på person</TableCell>
                    <TableCell>Hendelsestype</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {lockEventHistory.map((lockEvent, index) => {
                    const lockEventMessage = getLockEventMessage(lockEvent.lockEventMessage);
                    return (
                        <TableRow key={lockEvent.timestamp}>
                            <TableCell>
                                {formatDateToString(new Date(lockEvent.timestamp), 'eeee do MMMM yyyy')}
                            </TableCell>
                            <TableCell>{formatDateToString(new Date(lockEvent.timestamp), 'kk:mm')}</TableCell>
                            <TableCell>{lockEvent.personName}</TableCell>
                            <TableCell>{lockEventMessage}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default LockEventHistoryTable;
