import { AppThunk } from '../../reduxTypes';
import { OrderLine } from '../../../utils/types';
import { authFetch } from '../../../utils/helpers';

export const GET_ORDER_LINES = 'GET_ORDER_LINES';
export const GET_ORDER_LINES_SUCCESS = 'GET_ORDER_LINES_SUCCESS';
export const GET_ORDER_LINES_FAILED = 'GET_ORDER_LINES_FAILED';
export const RESET_FETCHING_ORDERLINES = 'RESET_FETCHING_ORDERLINES';

const gettingOrderLines = (id: number) => ({
    type: GET_ORDER_LINES,
    payload: { id },
});

const getOrderLinesSuccess = (orderLines: OrderLine[], id: number, storeInOrderHistory: boolean) => ({
    type: GET_ORDER_LINES_SUCCESS,
    payload: {
        orderLines,
        id,
        storeInOrderHistory,
    },
});

const getOrderLinesFailed = (error: unknown, id: number) => ({
    type: GET_ORDER_LINES_FAILED,
    payload: { error, id },
});

export const resetFetchingOrderlines = () => ({
    type: RESET_FETCHING_ORDERLINES,
});

export const getOrderLines =
    (orderId: number, storeInOrderHistory: boolean = true): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(gettingOrderLines(orderId));
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const response = await authFetch(fb, `/orders/${orderId}/orderLines`, 'GET', undefined, apiUrl);
            const orderLines: any = await response.json(); // TODO make orderLines type safe
            dispatch(getOrderLinesSuccess(orderLines, orderId, storeInOrderHistory));
        } catch (error) {
            dispatch(getOrderLinesFailed(error, orderId));
        }
    };
