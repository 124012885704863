import { Color } from '@mui/material';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    // Adding custom colors (typescript)
    interface Palette {
        neutrals: Partial<Color>;
    }
    interface PaletteOptions {
        neutrals: Partial<Color>;
    }
}

declare module '@mui/material/styles/createPalette' {
    interface PaletteColor extends ColorPartial {}
}

export const baseTheme = createTheme({
    palette: {
        mode: 'light',

        // Brand colors
        primary: {
            light: '#d5e6ed',
            main: '#30565f',
            50: '#DAE7ED',
            100: '#c1d8dd',
            300: '#7DA1AA',
            800: '#223C43',
            900: '#23434B',
        },
        secondary: {
            main: '#f88275',
            50: '#FFEDEF',
            300: '#FBC2BB',
            600: '#DF7569',
            800: '#AE5B52',
        },

        // Semantic colors
        info: {
            main: '#0094E0',
        },
        warning: {
            main: '#EEAB3A',
        },
        error: {
            main: '#D1492A',
        },
        success: {
            main: '#16A24E',
        },

        // Neutrals
        neutrals: {
            50: '#F2F2F2',
            100: '#EDEDED',
            200: '#DEDEDE',
            300: '#B8B8B8',
            400: '#939393',
            500: '#6E6E6E',
            600: '#4A4A4A',
            700: '#252525',
            800: '#1A1A1A',
            900: '#000000',
        },

        background: {
            default: '#F2F2F2',
            paper: '#fff',
        },
    },
    typography: {
        fontFamily: 'inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        h1: {
            fontSize: '2.3rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '1.8rem',
            fontWeight: 500,
            color: '#4A4A4A',
        },
        h3: {
            fontSize: '1.35rem',
            fontWeight: 500,
            color: '#333333',
        },
        h4: {
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        h5: {
            fontSize: '1.1rem',
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: '1rem',
            textTransform: 'uppercase',
            fontWeight: 500,
            letterSpacing: 0.5,
            color: '#6E6E6E',
        },
        subtitle2: {
            fontSize: '0.75rem',
            textTransform: 'uppercase',
            fontWeight: 500,
            letterSpacing: 0.5,
            color: '#6E6E6E', // Hard coded! (neutrals.500) Should reference pallette (see trello)
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            color: '#383838',
        },
        body2: {
            fontSize: '0.875rem',
        },
        button: {
            textTransform: 'none',
            fontWeight: 400,
            fontSize: '1rem',
        },
        caption: {
            fontSize: '0.75rem',
        },
        overline: {
            fontSize: '0.625rem',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 500,
            md: 768,
            lg: 1200,
            xl: 1550,
        },
    },
    spacing: [2, 5, 10, 15, 20, 30, 40, 50, 60],
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: '6px 20px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '0.75rem',
                    fontWeight: 400,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
                },
            },
        },
    },
});
