import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { InventoryLocation } from '../../../utils/types';

import Axis from '../../Layouts/Axis/Axis';
import Column from '../../Layouts/Column/Column';
import useStyles from './PlaceInventoryCard.styles';

export type Props = {
    locations: InventoryLocation[];
    handleAllocateArticle: (barcode: string, locationId: number | null, onSuccess: VoidFunction) => void;
    loading: boolean;
};

const PlaceInventoryCard = ({ locations, handleAllocateArticle, loading }: Props) => {
    const { classes } = useStyles();

    const [inputFieldValue, setInputFieldValue] = useState('');
    const [selectedLocation, setSelectedLocation] = useState<string | number>('_firstLocationAvailable');
    const inputRef = useRef<HTMLInputElement>();

    const onKeypress = (event: any) => {
        if (event.keyCode === 13) {
            const locationId = typeof selectedLocation === 'number' ? selectedLocation : null;
            handleAllocateArticle(inputFieldValue, locationId, resetInputField);
        }
    };

    // Use when item is successfully placed
    const resetInputField = () => {
        setInputFieldValue('');
        inputRef.current?.focus();
    };

    return (
        <Paper className={classes.root}>
            <Axis direction='row' gap={3}>
                <Typography className={classes.header} variant='h3'>
                    Plasser vare
                </Typography>
                {loading && <CircularProgress size={25} thickness={4.5} />}
            </Axis>
            <Column gap={2}>
                <TextField
                    inputRef={inputRef}
                    disabled={loading}
                    autoFocus
                    variant='outlined'
                    value={inputFieldValue}
                    placeholder='Scann strekkoden inn her'
                    autoComplete='off'
                    onKeyDown={onKeypress}
                    onChange={event => setInputFieldValue(event.target.value)}
                />
                <FormControl>
                    <InputLabel id='location'>Lokasjon</InputLabel>
                    <Select
                        disabled={loading}
                        labelId='location'
                        value={selectedLocation}
                        label='Lokasjon'
                        onChange={event => setSelectedLocation(event.target.value)}
                        data-cy='location-dropdown'
                    >
                        {<MenuItem value={'_firstLocationAvailable'}>Finn første ledige</MenuItem>}
                        {locations.map(location => {
                            return (
                                <MenuItem key={location.inventoryLocationId} value={location.inventoryLocationId}>
                                    {location.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Column>
        </Paper>
    );
};

export default PlaceInventoryCard;
