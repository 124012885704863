import { makeStyles } from '../../../../state/hooks';

export default makeStyles()(theme => ({
    dropdown: {
        minWidth: '200px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));
