import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ReactNode } from 'react';
import useStyles from './MobileTableRow.styles';

export type RowData = {
    key: string;
    value: string | number;
    displayFormat: 'text' | 'percentage' | 'currency';
    rowValueIcon?: ReactNode;
};

export type Props = {
    headerRow: RowData;
    bodyRows: ReactNode;
    isExpandable: boolean;
    children?: ReactNode;
};

const MobileTableRow = ({ headerRow, bodyRows, isExpandable, children }: Props) => {
    const { classes } = useStyles();

    return (
        <TableContainer component={Paper} elevation={1}>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        <TableCell>
                            <Typography variant='subtitle2' color={'#fff'}>
                                {headerRow.key}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='subtitle2' color={'#fff'}>
                                {headerRow.value}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bodyRows}
                    {isExpandable && children}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MobileTableRow;
