import { Box, Button, FormHelperText, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useState } from 'react';

import { ArticleAttribute, ArticleGroup } from '../../../utils/types';
import ArticleGroupsSelectors from '../../Form/ArticleGroupsSelectors';
import Column from '../../Layouts/Column/Column';
import Modal from '../Modal/Modal';

export type Props = {
    open: boolean;
    title: string;
    primaryArticleGroups: ArticleGroup[];
    secondaryArticleGroups: ArticleGroup[];
    tertiaryArticleGroups: ArticleGroup[];
    handleToggleModal: () => void;
    handleSaveAttribute: (attribute: ArticleAttribute) => void;
    initialValues?: IntialValues;
};

export interface IntialValues {
    name: {
        value: string;
        disabled?: boolean;
    };
    attributeValues: {
        value: string[];
        disabled?: boolean;
    };
    primaryArticleGroup: {
        value: number | null;
        disabled?: boolean;
    };
    secondaryArticleGroup: {
        value: number | null;
        disabled?: boolean;
    };
    tertiaryArticleGroup: {
        value: number | null;
        disabled?: boolean;
    };
}

const AttributeModal = ({
    open,
    title,
    primaryArticleGroups,
    secondaryArticleGroups,
    tertiaryArticleGroups,
    handleToggleModal,
    handleSaveAttribute,
    initialValues,
}: Props) => {
    const [nameError, setNameError] = useState(false);
    const [name, setName] = useState(initialValues?.name.value ?? '');

    const [primaryArticleGroup, setPrimaryArticleGroup] = useState<number | string>(
        initialValues?.primaryArticleGroup.value ?? 'null'
    );
    const [secondaryArticleGroup, setSecondaryArticleGroup] = useState<number | string>(
        initialValues?.secondaryArticleGroup.value ?? 'null'
    );
    const [tertiaryArticleGroup, setTertiaryArticleGroup] = useState<number | string>(
        initialValues?.tertiaryArticleGroup.value ?? 'null'
    );

    const handleSave = () => {
        if (name.length === 0) {
            setNameError(true);
            return;
        }

        handleSaveAttribute({
            articleAttributeId: 0,
            name,
            articleGroup1Id: typeof primaryArticleGroup === 'number' ? primaryArticleGroup : null,
            articleGroup2Id: typeof secondaryArticleGroup === 'number' ? secondaryArticleGroup : null,
            articleGroup3Id: typeof tertiaryArticleGroup === 'number' ? tertiaryArticleGroup : null,
            articleAttributeValues: [],
        });
    };

    const handleChangeArticleGroup = (event: SelectChangeEvent<string | number>) => {
        const { name, value } = event.target;

        switch (name) {
            case 'primaryArticleGroup':
                setPrimaryArticleGroup(value);
                break;
            case 'secondaryArticleGroup':
                setSecondaryArticleGroup(value);
                break;
            case 'tertiaryArticleGroup':
                setTertiaryArticleGroup(value);
                break;
            default:
                break;
        }
    };
    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(false);
    };

    return (
        <Modal openModal={open} maxWidth='sm' toggleModal={handleToggleModal}>
            <Box sx={{ p: 5 }}>
                <Column gap={3}>
                    <div>
                        <Typography variant='h3'>{title}</Typography>
                    </div>
                    <TextField
                        error={nameError}
                        label='Navn'
                        id='my-input'
                        variant='outlined'
                        value={name}
                        name='locationName'
                        disabled={initialValues?.name.disabled}
                        onChange={onChangeName}
                    />
                    {nameError && <FormHelperText error> Navn kan ikke være tomt.</FormHelperText>}
                    <Column gap={3}>
                        <ArticleGroupsSelectors
                            primaryArticleGroup={primaryArticleGroup}
                            secondaryArticleGroup={secondaryArticleGroup}
                            tertiaryArticleGroup={tertiaryArticleGroup}
                            primaryArticleGroups={primaryArticleGroups}
                            secondaryArticleGroups={secondaryArticleGroups}
                            tertiaryArticleGroups={tertiaryArticleGroups}
                            handleChange={handleChangeArticleGroup}
                        />
                    </Column>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleToggleModal}>Avbryt</Button>
                        <Button onClick={handleSave} variant='contained'>
                            Lagre
                        </Button>
                    </Box>
                </Column>
            </Box>
        </Modal>
    );
};

export default AttributeModal;
