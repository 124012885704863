import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';

import Modal from '../Modal/Modal';
import InputField from '../../Form/InputField/InputField';
import { formatDateToString } from '../../../utils/date';
import { CurrentDay } from '../../../containers/PageSpecific/Bookings/BookingCalendarContainer/BookingCalendarContainer';
import { RotateCw } from 'react-feather';
import useStyles from './BookingCalendarModal.styles';

export type Props = {
    open: boolean;
    details: CurrentDay;
    handleOpen: () => void;
    handleUpdate: (capacity: number) => void;
    handleReset: (id: string) => void;
};

/**
 * Modal for when changing the special capacity of a given date.
 */
const BookingCalendarModal = ({ open, details, handleOpen, handleUpdate, handleReset }: Props) => {
    const { date, capacity, specialCapacityId } = details;
    const { classes } = useStyles();
    const [value, setValue] = useState<number | null>(null);

    const hasSpecialCapacity = specialCapacityId !== null;

    useEffect(() => {
        setValue(capacity);
    }, [capacity]);

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const valueNumber = Number(event.target.value);
        if (valueNumber > 0) setValue(valueNumber);
        else setValue(0);
    };

    const handleResetClose = () => {
        if (hasSpecialCapacity) {
            handleOpen();
            handleReset(specialCapacityId);
        }
    };

    const handleSave = () => {
        if (value !== null) {
            const newValue = Number(value);
            if (capacity !== newValue) handleUpdate(newValue);
        }
        handleOpen();
    };

    return (
        <Modal openModal={open} toggleModal={handleOpen} maxWidth='xs'>
            {date && (
                <div className={classes.root}>
                    <Typography variant='h6' className={classes.heading}>
                        {formatDateToString(date, 'cccc do MMM yyyy')}
                    </Typography>
                    <Typography className={classes.subHeading}>
                        Nåværende kapasitet: <strong>{capacity}</strong>
                    </Typography>
                    <Typography className={classes.inputText}>Ny kapasitet</Typography>
                    <div className={classes.inputContainer}>
                        <InputField
                            id='new-capacity'
                            className={classes.input}
                            value={value}
                            type='number'
                            placeholder='Ny kapasitet'
                            onChange={handleChange}
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button disableElevation variant='text' onClick={handleOpen}>
                            Avbryt
                        </Button>
                        <Button disableElevation color='primary' variant='contained' onClick={handleSave}>
                            Lagre
                        </Button>
                    </div>
                    {hasSpecialCapacity && (
                        <div className={classes.resetContainer}>
                            <Button
                                className={classes.reset}
                                startIcon={<RotateCw size={18} />}
                                onClick={handleResetClose}
                            >
                                Tilbakestill til ukemal
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default BookingCalendarModal;
