import { Box, Button, Typography, Paper, SwipeableDrawer } from '@mui/material';
import { ReactNode } from 'react';
import { Menu } from 'react-feather';
import { Link } from 'react-router-dom';

import useStyles from './MobileNavbar.styles';

export type Props = {
    open: boolean;
    toggleModal: VoidFunction;
    children: ReactNode;
    logo: ReactNode;
};

const MobileNavbar = ({ children, open, toggleModal, logo }: Props) => {
    const { classes } = useStyles();

    return (
        <>
            <Paper className={classes.root} sx={{ px: 2, py: 2 }}>
                <Box className={classes.menu}>
                    <Box style={{ paddingLeft: 10, height: '30px' }}>
                        <Link to={'/'}>{logo}</Link>
                    </Box>
                    <Button onClick={toggleModal}>
                        <Typography sx={{ mr: 2 }}>Meny</Typography>
                        <Menu />
                    </Button>
                </Box>
            </Paper>
            <SwipeableDrawer
                className={classes.drawer}
                anchor='top'
                open={open}
                onOpen={toggleModal}
                onClose={toggleModal}
                PaperProps={{ className: classes.drawer }}
            >
                <Box className={classes.links}>{children}</Box>
            </SwipeableDrawer>
        </>
    );
};

export default MobileNavbar;
