import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        padding: theme.spacing(5),
    },
    dropdowns: {
        minWidth: 150,
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
