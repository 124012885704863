import { useEffect, useState } from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';
import { Box, Button, Fade, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { makeStyles, useActiveConversation, useSelector } from '../../../../state/hooks';
import { createConversation } from '../../../../state/ducks/conversations/CreateConversation';
import ContactReplyContainer from '../ContactReplyContainer/ContactReplyContainer';
import MessageContainer from '../MessageContainer/MessageContainer';
import SubtleSpinner from '../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import HelpButton from '../../../../components/Buttons/HelpButton/HelpButton';
import Customer from '../../../../models/Customer';

type Props = {
    employeeName?: string;
    customer?: Customer | null;
    contactInput: string;
    setContactInput: (contactInput: string) => void;
    useConversationIdFromState?: boolean;
};

// TODO REfactor styles from this component
const ContactContainer = ({
    employeeName = '',
    customer,
    contactInput,
    setContactInput,
    useConversationIdFromState = false,
}: Props) => {
    const defaultLimit = 7;

    const [limit, setLimit] = useState(defaultLimit);
    const { customerId } = useParams();

    const dispatch = useDispatch();
    const messages = useSelector(state => state.firestore.ordered.messages);
    const { activeConversationId } = useSelector(state => state.conversations);
    const activePhoneNumber = useSelector(state => state.conversations.activePhoneNumber);
    const conversation = useActiveConversation(
        'activeConversationContactContainer',
        useConversationIdFromState ? activeConversationId : customerId
    );
    const customerNumber = conversation?.customer.customerNumber;

    const { classes } = useStyles();

    useFirestoreConnect(
        conversation && conversation?.customer?.customerNumber && activePhoneNumber
            ? [
                  {
                      collection: `customers/${conversation.customer.customerNumber}/messages`,
                      where: ['associatedWith', '==', activePhoneNumber.get()],
                      orderBy: ['date', 'desc'],
                      storeAs: 'messages',
                      limit,
                  },
              ]
            : []
    );

    useEffect(() => {
        setLimit(defaultLimit);
    }, [customerNumber]);

    const handleLoadMore = () => setLimit(prevState => prevState + 10);

    const handleStartConversation = () => {
        if (customer) dispatch(createConversation(customer));
    };

    if (activePhoneNumber && conversation) {
        return (
            <>
                {isLoaded(messages) && conversation.customer.customerName ? (
                    <Fade in={true}>
                        <div>
                            <ContactReplyContainer
                                customerName={conversation.customer.customerName}
                                employeeName={employeeName}
                                customerNumber={conversation.customer.customerNumber}
                                phoneNumber={activePhoneNumber}
                                inputValue={contactInput}
                                setContactInput={setContactInput}
                            >
                                <MessageContainer
                                    limit={limit}
                                    messages={messages}
                                    phoneNumber={activePhoneNumber}
                                    loadMore={handleLoadMore}
                                />
                            </ContactReplyContainer>
                        </div>
                    </Fade>
                ) : (
                    <div className={classes.spinnerContainer}>
                        <SubtleSpinner />
                    </div>
                )}
            </>
        );
    }

    // If customer has a phone number
    if (customer?.getFirstPhoneNumber()) {
        return (
            <div className={classes.newConversationContainer}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant='h3'>Start samtalen med kunden</Typography>
                    <HelpButton
                        helpText='Vi har ikke sendt melding til denne kunden før. Trykk på knappen under, 
                        og du vil deretter ha mulighet for å sende meldinger til kunden.'
                    />
                </Box>
                <Button onClick={handleStartConversation} variant='contained' color='primary'>
                    Start samtale
                </Button>
            </div>
        );
    }

    if (isLoaded(messages)) {
        return <p className={classes.emptyMessage}>Finner ikke noe telefonnummer til kunden</p>;
    } else {
        return <SubtleSpinner style={{ marginTop: 50 }} />;
    }
};

const useStyles = makeStyles()({
    spinnerContainer: {
        marginTop: 50,
    },
    emptyMessage: {
        textAlign: 'center',
    },
    newConversationContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

export default ContactContainer;
