export default class PhoneNumber {
    private pretty: string;
    private clean: string;

    constructor(input: string) {
        this.checkInvariant(input);

        const num = this.extractUsableNumber(input);

        this.pretty = this.makePretty(num);
        this.clean = this.cleanNumber(num);
    }

    /**
     * Invariant: Check that the input is nonempty
     * @param {string} input the input given to the constructor
     * @throws Error if the string is empty
     */
    private checkInvariant(input: string) {
        if (!input) throw new Error('Could not create a number from given input');
    }

    /**
     * Extract numbers from the input (and possibly the + charactor)
     * @param {string} input raw phone number
     * @returns {string} numbers and possibly + charactor
     */
    private extractUsableNumber(input: string) {
        const matches = input.match(/[0-9]+/g); // Find all the numbers in the string
        if (!matches) throw new Error('Could not extract numbers from given phone number');

        const num = matches.join('');

        return input.includes('+') ? '+' + num : num; // Add a + in front if present in input
    }

    /**
     * Cleans up phone numbers.
     * @param num - the phonenumber to be cleaned
     */
    private cleanNumber(num: string) {
        let cleaned = num;

        if (num.substr(0, 1) === '+') return num.substring(1);

        // Remove 00 in front if present
        if (num.substr(0, 2) === '00') cleaned = cleaned.substring(2);

        // Check if number is norwegian
        // If it is 8 digit and does not have a country code in front
        if (cleaned?.length === 8 && !num.includes('+') && num.substr(0, 2) !== '00') {
            return '47' + cleaned;
        }

        return cleaned;
    }

    /**
     * Convert the given number into a "pretty" number that can be displayed to the user
     * @param {string} num number to prettify
     * @returns {string} pretty number
     */
    private makePretty(num: string) {
        // Remove +, +47 or 0047 on norwegian numbers
        if (num.substr(0, 2) === '47' && num.length === 10) return num.substring(2);
        if (num.substr(0, 3) === '+47' && num.length === 11) return num.substring(3);
        if (num.substr(0, 4) === '0047' && num.length === 12) return num.substring(4);

        return num;
    }

    /**
     * Gets the formatted and comparable phone number with country code prefixed
     * @returns {string} the cleaned phone number
     */
    get() {
        return this.clean;
    }

    /**
     * Gets the pristine and unformatted phone number, as it is returned from the database.
     * @returns {string} pristine phone number
     */
    getPretty() {
        return this.pretty;
    }

    /**
     * Compare to phone number by their cleaned value.
     * See {@link cleanNumber} for specifics
     * @param obj {any} object this instance is to be compared to
     * @returns {boolean} true if equal, false if not
     */
    equals(obj: any): boolean {
        return obj instanceof PhoneNumber && this.clean === obj?.clean;
    }
}
