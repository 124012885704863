import { Article, ArticleVariant, InventoryLocation } from '../../../models/Article';
import { authFetch, createFailedAction } from '../../../utils/helpers';
import { ProductLocation } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const SET_ARTICLE_MODAL_STARTED = 'SET_ARTICLE_MODAL_STARTED';
export const SET_ARTICLE_MODAL_SUCCESS = 'SET_ARTICLE_MODAL_SUCCESS';
export const SET_ARTICLE_MODAL_FAILED = 'SET_ARTICLE_MODAL_FAILED';

const fetchFailedAction = createFailedAction(SET_ARTICLE_MODAL_FAILED);

export const setArticleModal =
    (article: Article): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch({ type: SET_ARTICLE_MODAL_STARTED });
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const url = '/article?articleId=' + article.articleId;
            const results = await authFetch(fb, url, 'GET', undefined, apiUrl);
            const variants: ArticleVariant[] = await results.json();

            const modalArticle: Article = { ...article, variants };

            dispatch({
                type: SET_ARTICLE_MODAL_SUCCESS,
                payload: { article: modalArticle },
            });
        } catch (error) {
            dispatch(fetchFailedAction(error));
        }
    };

export const GET_ARTICLE_LOCATIONS_STARTED = 'GET_ARTICLE_LOCATIONS_STARTED';
export const GET_ARTICLE_LOCATIONS_SUCCESS = 'GET_ARTICLE_LOCATIONS_SUCCESS';
export const GET_ARTICLE_LOCATIONS_FAILED = 'GET_ARTICLE_LOCATIONS_FAILED';

const getArticleLocationsStarted = (id: number) => ({
    type: GET_ARTICLE_LOCATIONS_STARTED,
    id,
});

const getArticleLocationsSuccess = (variants: ArticleVariant[]) => ({
    type: GET_ARTICLE_LOCATIONS_SUCCESS,
    payload: { variants },
});

const getArticleLocationsFailed = createFailedAction(GET_ARTICLE_LOCATIONS_FAILED);

export const getArticleLocations =
    (article: Article, sizeColorId: number | null): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(getArticleLocationsStarted(article.articleId));
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const url = `/productLocations?articleId=${article.articleId}&sizeColorId=${sizeColorId}`;
            const data = await authFetch(fb, url, 'GET', undefined, apiUrl);

            const { result: articleLocations, error }: { result: ProductLocation[]; error: any } = await data.json();
            if (error !== null) dispatch(getArticleLocationsFailed(error));
            else {
                if (article.variants) {
                    const newVariants: ArticleVariant[] = [...article.variants];

                    const variantIndex = newVariants.findIndex(v => {
                        if (article.hasVariants) {
                            return v.sizeColorId === sizeColorId;
                        } else {
                            return v.articleNumber === article.articleNo;
                        }
                    });

                    const newLocations: InventoryLocation[] = articleLocations.map(loc => {
                        return {
                            locationId: loc.locationId,
                            locationName: loc.locationName,
                            placementId: loc.placementId,
                            placementName: loc.placementName,
                            count: loc.count,
                        };
                    });

                    newVariants[variantIndex].inventoryLocations = newLocations;

                    dispatch(getArticleLocationsSuccess(newVariants));
                }
            }
        } catch (error) {
            dispatch(getArticleLocationsFailed(error));
        }
    };

export const REMOVE_PRODUCT_LOCATION_STARTED = 'REMOVE_PRODUCT_LOCATION_STARTED';
export const REMOVE_PRODUCT_LOCATION_SUCCESS = 'REMOVE_PRODUCT_LOCATION_SUCCESS';
export const REMOVE_PRODUCT_LOCATION_FAILED = 'REMOVE_PRODUCT_LOCATION_FAILED';

const removeProductLocationFailed = createFailedAction(REMOVE_PRODUCT_LOCATION_FAILED);

export const removeProductLocation =
    (articleId: number, locationId: number, sizeColorId: number | null, placementId: number | null): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch({ type: REMOVE_PRODUCT_LOCATION_STARTED });
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const url = `/productLocations?articleId=${articleId}&sizeColorId=${sizeColorId}&locationId=${locationId}&placementId=${placementId}&count=1`;
            await authFetch(fb, url, 'DELETE', undefined, apiUrl);
            //const deletedLocation: any = await results.json();
            dispatch({ type: REMOVE_PRODUCT_LOCATION_SUCCESS });

            const article: Article = getState().inventory.articleModal;
            dispatch(getArticleLocations(article, sizeColorId));
        } catch (error) {
            dispatch(removeProductLocationFailed(error));
        }
    };
