import { Box, Theme, useMediaQuery } from '@mui/material';

import { calculateBlankCalendarDays } from '../../../utils/date';
import { BikeBoxAvailability } from '../../../utils/types';
import ReservationCalendarCard from '../../Cards/ReservationCalendarCard/ReservationCalendarCard';
import MonthPicker from '../../Navigation/MonthPicker/MonthPicker';
import useStyles from './BikeBoxCalendar.styles';

export type Props = {
    disabled: boolean;
    reservationAvailability: BikeBoxAvailability;
    onDateClick: (date: Date) => void;
    currentMonth: number;
    setCurrentMonth: React.Dispatch<React.SetStateAction<number>>;
};

const BikeBoxCalendar = ({ disabled, reservationAvailability, onDateClick, currentMonth, setCurrentMonth }: Props) => {
    const { classes } = useStyles();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    function handleNextMonth() {
        setCurrentMonth(prev => (prev < 12 ? prev + 1 : prev));
    }
    function handlePrevMonth() {
        setCurrentMonth(prev => (prev > 0 ? prev - 1 : prev));
    }

    const firstDay = new Date(reservationAvailability[currentMonth][0].date);

    const year = firstDay.getFullYear();
    const month = firstDay.getMonth();

    const [beforeBlankNum, afterBlankNum] = calculateBlankCalendarDays(year, month);

    const controlsActive = {
        left: currentMonth > 0,
        right: reservationAvailability.length !== currentMonth + 1,
    };

    const days = reservationAvailability[currentMonth].map((dayOfMonth, i) => {
        const date = new Date(dayOfMonth.date);
        return (
            <ReservationCalendarCard
                key={i}
                isDisabled={disabled}
                available={dayOfMonth.available}
                selected={dayOfMonth.selected}
                rangeSelected={dayOfMonth.rangeSelected}
                onClick={dayOfMonth.available ? onDateClick : () => {}}
                date={date}
                isMobile={isMobile}
            />
        );
    });

    // TODO: Add weekday headers

    const blanks = [...Array(beforeBlankNum + afterBlankNum)].map((_, i) => (
        <ReservationCalendarCard key={i + 'blanks'} isDisabled={disabled} date={undefined} onClick={() => {}} />
    ));

    const calendar = [...blanks.slice(0, beforeBlankNum), ...days, ...blanks.slice(beforeBlankNum)];

    return (
        <div className={classes.root}>
            <MonthPicker
                month={month}
                year={year}
                previousMonth={handlePrevMonth}
                nextMonth={handleNextMonth}
                controlsActive={controlsActive}
            />
            <Box className={classes.calendar}>{calendar}</Box>
        </div>
    );
};

export default BikeBoxCalendar;
