import { Typography } from '@mui/material';

import { makeStyles } from '../../../state/hooks';

type Props = {
    text: string | number;
    error?: boolean;
    className?: string;
    style?: object;
};

/**
 * Component representing a label, often used to label a short text or point of information.
 */
const SmallLabel = ({ text, error = false, className, style }: Props) => {
    const { classes, cx } = useStyles();

    return (
        <Typography className={error ? cx(classes.error, className) : className} style={style} variant='subtitle2'>
            {text}
        </Typography>
    );
};

const useStyles = makeStyles()(theme => ({
    error: {
        color: theme.palette.error.main,
        fontWeight: 'bold',
    },
}));

export default SmallLabel;
