const constants = {
    cardWidth: 270,
    calendarGridGap: 20,
    calendarPadding: 30,
    lightBoxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    longLightBoxShadow: '0 3px 8px 0 rgba(0,0,0,0.12), 0 3px 1px 0 rgba(0,0,0,0.04)',
    reverselongLightBoxShadow: '0 -3px 8px 0 rgba(0,0,0,0.12), 0 -3px 1px 0 rgba(0,0,0,0.04)',
    lightBlueMain: '#CEE3F9',
    lightBlueDark: '#c3dbf5',
    backgroundColor: '#EDEDED',
    errorLight: '#FBE2E2',
    grey350: '#DEDEDE',
    normalBorderRadius: 8,
};

export default constants;
