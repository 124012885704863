import Order from '../../../models/Order';
import { AppThunk } from '../../reduxTypes';
import { authFetch, createFailedAction } from '../../../utils/helpers';

export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAILED = 'GET_ORDER_HISTORY_FAILED';

const getOrderHistory = () => ({
    type: GET_ORDER_HISTORY,
});

const getOrderHistorySuccess = (orderHistory: Order[]) => ({
    type: GET_ORDER_HISTORY_SUCCESS,
    payload: { orderHistory },
});

const getOrderHistoryFailed = createFailedAction(GET_ORDER_HISTORY_FAILED);

export const getCustomerHistory =
    (customerNumber: string): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(getOrderHistory());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');

        try {
            const response = await authFetch(fb, `/customers/${customerNumber}/orderHistory`, 'GET', undefined, apiUrl);
            const orders: unknown[] = await response.json();

            const orderHistory = orders.map(order => new Order(order));

            dispatch(getOrderHistorySuccess(orderHistory));
        } catch (error) {
            dispatch(getOrderHistoryFailed(error));
        }
    };
