import { FormControl, TextField, FormHelperText } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

import { GeneratePlacementFormData } from '../../../utils/types';
import Column from '../../Layouts/Column/Column';

type Props = {
    control: Control<GeneratePlacementFormData, any>;
    isLoading: boolean;
};

const GeneratePlacementForm = ({ control, isLoading }: Props) => {
    return (
        <Column gap={2}>
            <Controller
                control={control}
                name='letterPrefix'
                rules={{
                    required: true,
                    maxLength: 5,
                }}
                defaultValue={''}
                render={({ field: { ref, ...field }, fieldState: { isTouched, isDirty, error }, formState }) => (
                    <FormControl>
                        <TextField
                            {...field}
                            disabled={isLoading}
                            label='Bokstav-prefix'
                            placeholder='"A", eller "T"'
                            variant='outlined'
                            autoComplete='off'
                            inputRef={ref}
                        />
                        {error?.type === 'required' && (
                            <FormHelperText error>Bokstav prefix kan ikke være tom.</FormHelperText>
                        )}
                        {error?.type === 'maxLength' && (
                            <FormHelperText error>Maks-lengde på bokstavprefix er 5 tegn.</FormHelperText>
                        )}
                    </FormControl>
                )}
            />
            <Controller
                control={control}
                name='startValue'
                rules={{
                    required: true,
                    pattern: /\d+/gi,
                    min: 0,
                    maxLength: 3,
                }}
                defaultValue={1}
                render={({ field: { ref, ...field }, fieldState: { isTouched, isDirty, error }, formState }) => (
                    <FormControl>
                        <TextField
                            {...field}
                            disabled={isLoading}
                            label='Start-verdi'
                            placeholder='"1"'
                            type='number'
                            variant='outlined'
                            autoComplete='off'
                            inputRef={ref}
                        />
                        {error?.type === 'required' && (
                            <FormHelperText error>Start-verdi kan ikke være tom.</FormHelperText>
                        )}
                        {error?.type === 'min' && (
                            <FormHelperText error>Start-verdi kan ikke være mindre enn 0.</FormHelperText>
                        )}
                        {error?.type === 'maxLength' && (
                            <FormHelperText error>Start-verdi kan maks være tre-sifret.</FormHelperText>
                        )}
                    </FormControl>
                )}
            />
            <Controller
                control={control}
                name='endValue'
                rules={{
                    required: true,
                    pattern: /\d+/gi,
                    maxLength: 3,
                    min: 0,
                }}
                defaultValue={10}
                render={({ field: { ref, ...field }, fieldState: { isTouched, isDirty, error }, formState }) => (
                    <FormControl>
                        <TextField
                            {...field}
                            disabled={isLoading}
                            label='Slutt-verdi'
                            placeholder='"20"'
                            type='number'
                            variant='outlined'
                            autoComplete='off'
                            inputRef={ref}
                        />
                        {error?.type === 'required' && (
                            <FormHelperText error>Slutt-verdi kan ikke være tom.</FormHelperText>
                        )}
                        {error?.type === 'min' && (
                            <FormHelperText error>Slutt-verdi kan ikke være mindre enn 0.</FormHelperText>
                        )}
                        {error?.type === 'maxLength' && (
                            <FormHelperText error>Slutt-verdi kan maks være tre-sifret.</FormHelperText>
                        )}
                    </FormControl>
                )}
            />
        </Column>
    );
};

export default GeneratePlacementForm;
