import { ButtonBase, Chip, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { formatDateToString } from '../../../utils/date';
import { BikeBox } from '../../../utils/types';
import Row from '../../Layouts/Row/Row';
import useStyles from './BikeBoxCard.styles';

export type Props = {
    bikeBox: BikeBox;
};

const BikeBoxCard = ({ bikeBox }: Props) => {
    const { classes } = useStyles();

    const activeReservation = bikeBox.activeReservation;
    const isActive = activeReservation !== null;

    const reservationDates = isActive ? (
        <>
            <Typography className={classes.boxBodyText} variant='body1'>
                {activeReservation.customerName}
            </Typography>
            <Typography className={classes.boxBodyText} variant='body1'>
                {formatDateToString(new Date(activeReservation?.fromDate) ?? null, 'do MMMM')} -{' '}
                {formatDateToString(new Date(activeReservation?.toDate) ?? null, 'do MMMM')}
            </Typography>
        </>
    ) : null;

    return (
        <ButtonBase disableRipple>
            <Paper className={classes.root}>
                <Box className={classes.titleRow}>
                    <Typography variant='h3' className={classes.title}>
                        {bikeBox.name}
                    </Typography>
                    <Chip label={isActive ? 'Opptatt' : 'Ledig'} color={isActive ? 'error' : 'success'} />
                </Box>
                <Box>{isActive && reservationDates}</Box>
            </Paper>
        </ButtonBase>
    );
};

export default BikeBoxCard;
