import { ReactNode } from 'react';
import { Box, Button, Chip } from '@mui/material';
import { AlertTriangle, Calendar, Settings, MessageCircle, BarChart, Inbox, Package } from 'react-feather';

import { ThemePaletteMainColors } from '../../../utils/types';
import useStyles from './MenuNavigationButton.styles';

export type Props = {
    children: ReactNode;
    isActive: boolean;
    linkPath: string;
    notifications?: {
        count: number;
        color?: ThemePaletteMainColors;
    }[];
};

const MenuNavigationButton = ({ children, isActive, linkPath, notifications }: Props) => {
    const { classes, cx } = useStyles();

    const classNames = { button: classes.button, icon: classes.icon, chip: classes.chip };
    if (isActive) {
        classNames.button = cx(classes.button, classes.activeButton);
        classNames.icon = cx(classes.icon, classes.activeIcon);
    }

    let icon: any;

    switch (linkPath) {
        case '/oppfolging':
            icon = <AlertTriangle className={classNames.icon} />;
            break;
        case '/bookinger':
            icon = <Calendar className={classNames.icon} />;
            break;
        case '/settings':
            icon = <Settings className={classNames.icon} />;
            break;
        case '/meldinger':
            icon = <MessageCircle className={classNames.icon} />;
            break;
        case '/statistikk':
            icon = <BarChart className={classNames.icon} />;
            break;
        case '/varelager':
            icon = <Package className={classNames.icon} />;
            break;
        case '/':
            icon = <Inbox className={classNames.icon} />;
            break;
    }

    return (
        <Box>
            <Button
                className={classNames.button}
                classes={{ startIcon: classes.iconAlign }}
                variant='contained'
                startIcon={icon}
                disableElevation
                disableTouchRipple
                fullWidth
            >
                {children}
                <Box sx={{ marginLeft: 'auto' }}>
                    {notifications &&
                        notifications
                            .filter(noti => noti.count > 0)
                            .map((noti, index) => (
                                <Chip
                                    key={linkPath + index}
                                    label={noti.count}
                                    className={classes.chip}
                                    classes={{ label: classes.chipLabel }}
                                    color={noti.color ?? 'primary'}
                                />
                            ))}
                </Box>
            </Button>
        </Box>
    );
};

export default MenuNavigationButton;
