import { ReactNode } from 'react';

import { makeStyles } from '../../../state/hooks';

type Props = {
    children: ReactNode;
};

const WithSidebarLayout = ({ children }: Props) => {
    const { classes } = useStyles();
    return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles()(theme => ({
    root: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    },
}));

export default WithSidebarLayout;
