import { authFetch, createFailedAction, toArticlePlacement } from '../../../utils/helpers';
import { ArticlePlacement, InventoryLocation, LocationType, NewLocation, ProductLocation } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const CREATE_LOCATION_STARTED = 'CREATE_LOCATION_STARTED';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILED = 'CREATE_LOCATION_FAILED';

const createLocationStarted = () => ({
    type: CREATE_LOCATION_STARTED,
});

const createLocationSuccess = () => ({
    type: CREATE_LOCATION_SUCCESS,
});

const createLocationFailed = createFailedAction(CREATE_LOCATION_FAILED);

export const createLocation =
    (location: NewLocation): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(createLocationStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const url =
                '/inventoryLocations' +
                `?name=${location.name}&locationType=${location.locationType}&maxSize=${location.maxSize}&articleGroup1Id=${location.articleGroup1Id}
                &articleGroup2Id=${location.articleGroup2Id}&articleGroup3Id=${location.articleGroup3Id}`;
            await authFetch(fb, url, 'POST', undefined, apiUrl);

            dispatch(createLocationSuccess());
            dispatch(getLocations());
        } catch (error) {
            dispatch(createLocationFailed(error));
        }
    };

export const GET_LOCATIONS_STARTED = 'GET_LOCATIONS_STARTED';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILED = 'GET_LOCATIONS_FAILED';

const getLocationsStarted = () => ({
    type: GET_LOCATIONS_STARTED,
});

const getLocationsSuccess = (locations: InventoryLocation) => ({
    type: GET_LOCATIONS_SUCCESS,
    payload: { locations },
});

const getLocationsFailed = createFailedAction(GET_LOCATIONS_FAILED);

export const getLocations = (): AppThunk => async (dispatch, getState, fb) => {
    dispatch(getLocationsStarted());
    const apiUrl = getState().system.apiURL;
    if (!apiUrl) throw new Error('Could not fetch API URL.');
    try {
        const url = '/inventoryLocations';
        const data = await authFetch(fb, url, 'GET', undefined, apiUrl);

        const { result: locations, error }: { result: InventoryLocation; error: any } = await data.json();
        if (error !== null) dispatch(getLocationsFailed(error));
        else {
            dispatch(getLocationsSuccess(locations));
        }
    } catch (error) {
        dispatch(getLocationsFailed(error));
    }
};

export const GET_PRODUCT_LOCATIONS_STARTED = 'GET_PRODUCT_LOCATIONS_STARTED';
export const GET_PRODUCT_LOCATIONS_SUCCESS = 'GET_PRODUCT_LOCATIONS_SUCCESS';
export const GET_PRODUCT_LOCATIONS_FAILED = 'GET_PRODUCT_LOCATIONS_FAILED';

const getProductLocationsStarted = () => ({
    type: GET_PRODUCT_LOCATIONS_STARTED,
});

const getProductLocationsSuccess = (locationId: number, productLocations: ArticlePlacement[]) => ({
    type: GET_PRODUCT_LOCATIONS_SUCCESS,
    payload: { productLocations, locationId },
});

const getProductLocationsFailed = createFailedAction(GET_PRODUCT_LOCATIONS_FAILED);

export const getProductLocations =
    (locationId: number, locationType: LocationType): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(getProductLocationsStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            let url = '/productLocations?locationId=' + locationId;
            if (locationType === 'one-to-one') url = '/inventoryPlacements?locationId=' + locationId;
            const data = await authFetch(fb, url, 'GET', undefined, apiUrl);

            const { result: placements, error }: { result: ProductLocation[]; error: any } = await data.json();
            if (error !== null) dispatch(getProductLocationsFailed(error));
            else {
                const productLocations = placements.map(toArticlePlacement);
                dispatch(getProductLocationsSuccess(locationId, productLocations));
            }
        } catch (error) {
            dispatch(getProductLocationsFailed(error));
        }
    };

export const CREATE_PLACEMENTS_STARTED = 'CREATE_PLACEMENTS_STARTED';
export const CREATE_PLACEMENTS_SUCCESS = 'CREATE_PLACEMENTS_SUCCESS';
export const CREATE_PLACEMENTS_FAILED = 'CREATE_PLACEMENTS_FAILED';

const createPlacementsStarted = () => ({
    type: CREATE_PLACEMENTS_STARTED,
});

const createPlacementsSuccess = () => ({
    type: CREATE_PLACEMENTS_SUCCESS,
});

const createPlacementsFailed = createFailedAction(CREATE_PLACEMENTS_FAILED);

export const createPlacements =
    (placements: string[], locationId: number, locationType: LocationType, callbackFn?: VoidFunction): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(createPlacementsStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            let url = `/inventoryPlacements?locationId=${locationId}&`;
            const placementNames = placements.map((p, i) => 'placementNames[]=' + p);
            url += placementNames.join('&');
            console.log(url);

            // Send API request
            await authFetch(fb, url, 'POST', undefined, apiUrl);
            dispatch(createPlacementsSuccess());
            if (callbackFn) callbackFn();

            dispatch(getProductLocations(locationId, locationType));
        } catch (error) {
            dispatch(createPlacementsFailed(error));
        }
    };

export const CLEAR_PLACEMENT_STARTED = 'CLEAR_PLACEMENT_STARTED';
export const CLEAR_PLACEMENT_SUCCESS = 'CLEAR_PLACEMENT_SUCCESS';
export const CLEAR_PLACEMENT_FAILED = 'CLEAR_PLACEMENT_FAILED';

const clearPlacementStarted = () => ({
    type: CLEAR_PLACEMENT_STARTED,
});

const clearPlacementSuccess = () => ({
    type: CLEAR_PLACEMENT_SUCCESS,
});

export const clearPlacements =
    (
        placements: { articlePlacement: ArticlePlacement; count: number }[],
        locationId: number,
        locationType: LocationType,
        count: number = 1,
        callbackFn?: VoidFunction
    ): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(clearPlacementStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const promises: Promise<Response>[] = [];
            placements.forEach(placement => {
                const url = `/productLocations?articleId=${placement.articlePlacement.articleId}&sizeColorId=${placement.articlePlacement.sizeColorId}&locationId=${locationId}&placementId=${placement.articlePlacement.placementId}&count=${placement.count}`;
                const promise = authFetch(fb, url, 'DELETE', undefined, apiUrl);
                promises.push(promise);
            });
            await Promise.all(promises);
            //const deletedLocation: any = await results.json();
            dispatch(clearPlacementSuccess());
            if (callbackFn) callbackFn();
            dispatch(getProductLocations(locationId, locationType));
        } catch (error) {}
    };

export const EDIT_LOCATION_STARTED = 'EDIT_LOCATION_STARTED';
export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_FAILED = 'EDIT_LOCATION_FAILED';

const editLocationStarted = () => ({
    type: EDIT_LOCATION_STARTED,
});

const editLocationSuccess = () => ({
    type: EDIT_LOCATION_SUCCESS,
});

const editLocationFailed = createFailedAction(EDIT_LOCATION_FAILED);

export const editLocation =
    (location: NewLocation, callbackFn?: VoidFunction): AppThunk =>
    async dispatch => {
        dispatch(editLocationStarted());
        try {
            dispatch(editLocationSuccess());
            if (callbackFn) callbackFn();
            dispatch(getLocations());
        } catch (error) {
            dispatch(editLocationFailed(error));
        }
    };

export const DELETE_LOCATION_STARTED = 'DELETE_LOCATION_STARTED';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILED = 'DELETE_LOCATION_FAILED';

const deleteLocationStarted = () => ({
    type: DELETE_LOCATION_STARTED,
});

const deleteLocationSuccess = () => ({
    type: DELETE_LOCATION_SUCCESS,
});

const deleteLocationFailed = createFailedAction(DELETE_LOCATION_FAILED);

export const deleteLocation =
    (locationId: number, callbackFn?: VoidFunction): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(deleteLocationStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const url = `/inventoryLocations?locationId=${locationId}`;
            await authFetch(fb, url, 'DELETE', undefined, apiUrl);

            dispatch(deleteLocationSuccess());
            if (callbackFn) callbackFn();
        } catch (error) {
            dispatch(deleteLocationFailed(error));
        }
    };
