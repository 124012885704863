import { authFetch, createFailedAction } from '../../../utils/helpers';
import { ArticleGroup, TimePeriod, TimePeriodScope } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const SET_TIME_PERIOD = 'SET_TIME_PERIOD';
export const SET_TIME_PERIOD_SCOPE = 'SET_TIME_PERIOD_SCOPE';
export const SET_YEARS = 'SET_YEARS';

export const RESET_STATISTICS_FILTER = 'RESET_STATISTICS_FILTER';

export const GET_ARTICLE_GROUPS_STARTED = 'GET_ARTICLE_GROUPS_STARTED';
export const GET_ARTICLE_GROUPS_SUCCESS = 'GET_ARTICLE_GROUPS_SUCCESS';
export const GET_ARTICLE_GROUPS_FAILED = 'GET_ARTICLE_GROUPS_FAILED';

export const SET_ACTIVE_ARTICLE_GROUPS = 'SET_ACTIVE_ARTICLE_GROUPS';

export const SET_ORDER_TEMPLATE_IDS = 'SET_ORDER_TEMPLATE_IDS';

export const setTimePeriod = (timePeriod: TimePeriod) => ({
    type: SET_TIME_PERIOD,
    payload: { timePeriod },
});

export const setTimePeriodScope = (timePeriodScope: TimePeriodScope) => ({
    type: SET_TIME_PERIOD_SCOPE,
    payload: { timePeriodScope },
});

export const setYears = (years: string[]) => ({
    type: SET_YEARS,
    payload: { years },
});

/**
 * Sets the article groups for the given level
 * @param articleGroupLevel 1, 2 or 3
 * @param activeArticleGroupIds The ids of the article groups to set
 */
export const setActiveArticleGroups = (articleGroupLevel: number, activeArticleGroupIds: number[] | null) => ({
    type: SET_ACTIVE_ARTICLE_GROUPS,
    payload: { articleGroupLevel, activeArticleGroupIds },
});

export const setOrderTemplateIds = (orderTemplateIds: number[] | null) => ({
    type: SET_ORDER_TEMPLATE_IDS,
    payload: { orderTemplateIds },
});

export const resetStatisticsFilter = () => ({
    type: RESET_STATISTICS_FILTER,
});

const getArticleGroupsStarted = () => ({
    type: GET_ARTICLE_GROUPS_STARTED,
});

const getArticleGroupsSuccess = (articleGroupLevel: number, articleGroups: ArticleGroup[]) => ({
    type: GET_ARTICLE_GROUPS_SUCCESS,
    payload: { articleGroupLevel, articleGroups },
});

const getArticleGroupsFailed = createFailedAction(GET_ARTICLE_GROUPS_FAILED);

export const getArticleGroups = (): AppThunk => async (dispatch, getState, fb) => {
    dispatch(getArticleGroupsStarted());
    const apiUrl = getState().system.apiURL;
    if (!apiUrl) throw new Error('Could not fetch API URL.');
    try {
        const urls = [
            `/articleGroups?articleGroupLevel=1`,
            `/articleGroups?articleGroupLevel=2`,
            `/articleGroups?articleGroupLevel=3`,
        ];

        const promises: Promise<Response>[] = urls.map(url => authFetch(fb, url, 'GET', undefined, apiUrl));

        promises.map(async (promise, i) => {
            const result = await (await promise).json();
            dispatch(getArticleGroupsSuccess(i + 1, result));
        });
    } catch (error) {
        dispatch(getArticleGroupsFailed(error));
    }
};
