import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AllocatedPlacementCard from '../../../../components/Cards/AllocatedPlacementCard/AllocatedPlacementCard';
import PlaceInventoryCard from '../../../../components/Cards/PlaceInventoryCard/PlaceInventoryCard';
import Column from '../../../../components/Layouts/Column/Column';
import { getLocations } from '../../../../state/ducks/inventory/Locations';
import { placeInventory } from '../../../../state/ducks/inventory/PlaceInventory';
import { useSelector } from '../../../../state/hooks';

const PlaceInventoryItemContainer = () => {
    const dispatch = useDispatch();
    const { allocatedPlacement, allocatingPlacement, allocationHistory, locations } = useSelector(
        state => state.inventory
    );

    const maxAllocationHistoryShowing = 10;

    const handleAllocateArticle = (barcode: string, locationId: number | null, onSuccess: VoidFunction) => {
        dispatch(placeInventory(barcode, locationId, null, onSuccess));
    };

    const handleUndoAllocation = () => {};

    useEffect(() => {
        dispatch(getLocations());
    }, [dispatch]);

    return (
        <>
            <PlaceInventoryCard
                loading={allocatingPlacement}
                locations={locations}
                handleAllocateArticle={handleAllocateArticle}
            />
            <Column gap={4}>
                {allocatedPlacement && (
                    <AllocatedPlacementCard
                        articleName={allocatedPlacement.articleName}
                        articleNumber={allocatedPlacement.articleNumber}
                        locationName={allocatedPlacement.locationName}
                        placementName={allocatedPlacement.placementName}
                        handleUndo={handleUndoAllocation}
                        variant='normal'
                        title='Tildelt plassering'
                    />
                )}
                {allocationHistory && allocationHistory.length > 0 && (
                    <Box>
                        <Typography variant='subtitle1' sx={{ mb: 1 }}>
                            Tidligere plasseringer
                        </Typography>
                        <Column gap={2}>
                            {allocationHistory.slice(0, maxAllocationHistoryShowing).map(allocatedItem => {
                                return (
                                    <AllocatedPlacementCard
                                        key={allocatedItem.productLocationId}
                                        articleName={allocatedItem.articleName}
                                        articleNumber={allocatedItem.articleNumber}
                                        locationName={allocatedItem.locationName}
                                        placementName={allocatedItem.placementName}
                                        handleUndo={handleUndoAllocation}
                                        variant='compact'
                                    />
                                );
                            })}
                        </Column>
                    </Box>
                )}
            </Column>
        </>
    );
};

export default PlaceInventoryItemContainer;
