import { OrderTemplate } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';

export const setSelectedTemplateStarted = (template: object | null) => ({
    type: SET_SELECTED_TEMPLATE,
    payload: { template },
});

export const setSelectedTemplate =
    (templateId: number): AppThunk =>
    (dispatch, getState) => {
        if (templateId === 0) dispatch(setSelectedTemplateStarted(null));
        else {
            const state = getState();
            const orderTemplates: OrderTemplate[] | null = state.pck.orderTemplates;
            const orderTemplate: OrderTemplate | undefined = orderTemplates?.find(
                (ot: OrderTemplate) => ot.orderTemplateId === templateId
            );
            if (orderTemplate) {
                dispatch(
                    setSelectedTemplateStarted({
                        id: orderTemplate.orderTemplateId,
                        name: orderTemplate.orderTemplateName,
                    })
                );
            }
        }
    };
