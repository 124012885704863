import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Delete, Plus, Trash, Trash2 } from 'react-feather';
import { customAlphabet } from 'nanoid';

import { ArticleAttributeValue } from '../../../utils/types';
import Modal from '../Modal/Modal';

type Props = {
    open: boolean;
    initialValues: ArticleAttributeValue[];
    toggleOpen: () => void;
    handleSaveAttribute: (attributeValues: ArticleAttributeValueWithStatus[]) => void;
};

export type ArticleAttributeValueWithStatus = ArticleAttributeValue & {
    status: 'new' | 'pristine' | 'edited' | 'deleted';
};

const ArticleAttributeValuesModal = ({ open, initialValues, toggleOpen, handleSaveAttribute }: Props) => {
    const [values, setValues] = useState<ArticleAttributeValueWithStatus[]>([]);
    const nanoid = customAlphabet('1234567890', 10);

    const handleAddValue = () => {
        const newValue: ArticleAttributeValueWithStatus = {
            articleAttributeValueId: parseInt(nanoid()),
            name: '',
            status: 'new',
        };

        setValues([...values, newValue]);
    };

    const handleRemoveValue = (articleAttributeValueId: number) => {
        const index = values.findIndex(value => value.articleAttributeValueId === articleAttributeValueId);
        if (values[index].status === 'new') {
            setValues(values.filter(value => value.articleAttributeValueId !== articleAttributeValueId));
        } else {
            setValues(
                values.map(value => {
                    if (value.articleAttributeValueId === articleAttributeValueId) {
                        return {
                            ...value,
                            status: 'deleted',
                        };
                    }
                    return value;
                })
            );
        }
    };

    const handleChangeValue = (articleAttributeValueId: number, name: string) => {
        setValues(
            values.map(value => {
                if (value.articleAttributeValueId === articleAttributeValueId) {
                    const status = value.status === 'new' ? 'new' : 'edited';
                    return {
                        ...value,
                        name,
                        status,
                    };
                }
                return value;
            })
        );
    };

    const handleSave = () => {
        handleSaveAttribute(values);
    };

    useEffect(() => {
        setValues(
            initialValues.map(value => {
                return {
                    ...value,
                    status: 'pristine',
                };
            })
        );
    }, [initialValues, open]);

    return (
        <Modal openModal={open} toggleModal={toggleOpen} maxWidth='xs'>
            <Stack sx={{ p: 5 }} spacing={3}>
                <Typography variant='h3'>Legg til attributtverdier</Typography>
                <Stack spacing={2}>
                    {values
                        .filter(v => v.status !== 'deleted')
                        .map(attributeValue => {
                            return (
                                <AttributeValue
                                    key={attributeValue.articleAttributeValueId}
                                    attributeValue={attributeValue}
                                    handleChange={handleChangeValue}
                                    handleDelete={handleRemoveValue}
                                />
                            );
                        })}
                    <Button onClick={handleAddValue} variant='outlined' startIcon={<Plus />}>
                        Legg til verdier
                    </Button>
                </Stack>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={toggleOpen}>Avbryt</Button>
                    <Button variant='contained' onClick={handleSave}>
                        Lagre
                    </Button>
                </Box>
            </Stack>
        </Modal>
    );
};

export default ArticleAttributeValuesModal;

type AttributeValueProps = {
    attributeValue: ArticleAttributeValue;
    handleChange: (articleAttributeValueId: number, name: string) => void;
    handleDelete: (attributeValueId: ArticleAttributeValue['articleAttributeValueId']) => void;
};

const AttributeValue = ({ attributeValue, handleChange, handleDelete }: AttributeValueProps) => {
    return (
        <Stack direction='row' alignItems='center' spacing={2}>
            <TextField
                label='Navn'
                value={attributeValue.name}
                onChange={event => handleChange(attributeValue.articleAttributeValueId, event.target.value)}
            />
            <IconButton size='large' onClick={() => handleDelete(attributeValue.articleAttributeValueId)} color='error'>
                <Trash2 size={22} />
            </IconButton>
            {/* <Button onClick={() => handleDelete(attributeValue.articleAttributeValueId)} color='error' variant='text'>
                Slett verdi
            </Button> */}
        </Stack>
    );
};
