import { Box, Button, OutlinedInputProps, Paper } from '@mui/material';
import { ChangeEvent } from 'react';

import InputField from '../Form/InputField/InputField';
import Row from '../Layouts/Row/Row';

export type Props = {
    prioritizedValue: number;
    standardValue: number;
    isPristine: boolean;
    handleChangePrioritizedValue: (event: ChangeEvent<{ value: unknown; id: string; type: string }>) => void;
    handleChangeStandardValue: (event: ChangeEvent<{ value: unknown; id: string; type: string }>) => void;
    handleSave: () => void;
};

const PrioritizedBookingsDivisionCard = ({
    prioritizedValue,
    standardValue,
    isPristine,
    handleChangePrioritizedValue,
    handleChangeStandardValue,
    handleSave,
}: Props) => {
    const inputProps: OutlinedInputProps = {
        fullWidth: false,
        endAdornment: '%',
    };

    return (
        <>
            <Paper sx={{ p: 4, width: 'fit-content' }}>
                <Row gap={2}>
                    <InputField
                        label='Reservert prioriterte'
                        id='prioritizedInput'
                        value={prioritizedValue}
                        type='number'
                        onChange={handleChangePrioritizedValue}
                        textInputProps={inputProps}
                    />
                    {/* <InputField
                        label='Standard'
                        id='standardInput'
                        value={standardValue}
                        type='number'
                        onChange={handleChangeStandardValue}
                        textInputProps={inputProps}
                    /> */}
                </Row>
            </Paper>
            {!isPristine && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                    <Button variant='contained' color='primary' onClick={handleSave}>
                        Lagre
                    </Button>
                </Box>
            )}
        </>
    );
};

export default PrioritizedBookingsDivisionCard;
