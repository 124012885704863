import { ReactNode } from 'react';

import { makeStyles } from '../../../state/hooks';

export type Props = {
    header: ReactNode;
    children: ReactNode;
};

const HeaderBox = ({ header, children }: Props) => {
    const { classes } = useStyles();
    return (
        <div className={classes.root}>
            <div>
                <>{header}</>
            </div>
            <div className={classes.content}>
                <>{children}</>
            </div>
        </div>
    );
};

const useStyles = makeStyles()(theme => ({
    root: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
    },
    content: {
        padding: '30px 90px',
        overflowY: 'scroll',
        [theme.breakpoints.down('lg')]: {
            padding: 0,
        },
    },
}));

export default HeaderBox;
