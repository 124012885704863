import logo from '../../../assets/logo-black.png';
import { makeStyles } from '../../../state/hooks';

type Props = {
    className?: string;
    style?: object;
};

const Logo = ({ className, style }: Props) => {
    const { classes, cx } = useStyles();

    return <img className={cx(classes.logo, className)} src={logo} style={style} alt='Trek Logo' />;
};
const useStyles = makeStyles()(theme => ({
    logo: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
}));

export default Logo;
