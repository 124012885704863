import { Paper } from '@mui/material';

import { ReactNode } from 'react';

import image from '../../../assets/onboarding.jpg';
import { makeStyles } from '../../../state/hooks';

export type Props = {
    children: ReactNode;
    className?: string;
};

const BackgroundPaper = ({ children, className }: Props) => {
    const { classes, cx } = useStyles();

    return (
        <Paper elevation={2} className={cx(classes.root, className)}>
            {children}
        </Paper>
    );
};

const useStyles = makeStyles()({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -10,
    },
});

export default BackgroundPaper;
