import dotProp from 'dot-prop-immutable';

import { Article } from '../../../models/Article';
import {
    AllocatedPlacement,
    ArticleAttribute,
    InventoryLocation,
    InventoryProductType,
    ProductAttribute,
} from '../../../utils/types';
import * as PlaceInventoryTypes from './PlaceInventory';
import * as SetArticleModalTypes from './SetArticleModal';
import * as InventorySearchTypes from './InventorySearch';
import * as LocationsTypes from './Locations';
import * as ProductTypes from './ProductTypes';
import * as ArticleAttributesTypes from './ArticleAttributes';

interface InventoryState {
    allocatedPlacement: AllocatedPlacement | null;
    allocatingPlacement: boolean;
    allocationHistory: AllocatedPlacement[];
    articleModal: Article | null;
    loadingArticleModal: boolean;
    inventorySearchResults: Article[];
    locations: InventoryLocation[];
    loadingLocations: boolean;
    creatingPlacements: boolean;
    articleAttributes: ArticleAttribute[];
    productAttributes: ProductAttribute[];
}

const initialState: InventoryState = {
    allocatedPlacement: null,
    allocatingPlacement: false,
    allocationHistory: [],
    articleModal: null,
    loadingArticleModal: false,
    inventorySearchResults: [],
    locations: [],
    loadingLocations: false,
    creatingPlacements: false,
    articleAttributes: [],
    productAttributes: [],
};

export default function inventoryReducer(state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case PlaceInventoryTypes.PLACE_INVENTORY_STARTED: {
            return {
                ...state,
                allocatingPlacement: true,
            };
        }
        case PlaceInventoryTypes.PLACE_INVENTORY_SUCCESS: {
            return {
                ...state,
                allocatingPlacement: false,
                allocatedPlacement: payload.allocatedPlacement as AllocatedPlacement,
            };
        }
        case PlaceInventoryTypes.PLACE_INVENTORY_FAILED: {
            return {
                ...state,
                allocatingPlacement: false,
            };
        }

        case PlaceInventoryTypes.SET_ALLOCATION_HISTORY: {
            return {
                ...state,
                allocationHistory: payload.allocationHistory as AllocatedPlacement[],
            };
        }

        case SetArticleModalTypes.SET_ARTICLE_MODAL_STARTED: {
            return {
                ...state,
                loadingArticleModal: true,
            };
        }

        case SetArticleModalTypes.SET_ARTICLE_MODAL_SUCCESS: {
            return {
                ...state,
                articleModal: payload.article, // as Article,
                loadingArticleModal: false,
            };
        }

        case SetArticleModalTypes.GET_ARTICLE_LOCATIONS_SUCCESS: {
            return {
                ...state,
                articleModal: {
                    ...state.articleModal,
                    variants: payload.variants,
                },
            };
        }

        case InventorySearchTypes.INVENTORY_SEARCH_SUCCESS: {
            return {
                ...state,
                inventorySearchResults: payload.results,
            };
        }

        case InventorySearchTypes.RESET_INVENTORY_SEARCH: {
            return {
                ...state,
                inventorySearchResults: [],
            };
        }

        case LocationsTypes.GET_LOCATIONS_STARTED: {
            return {
                ...state,
                loadingLocations: true,
            };
        }

        case LocationsTypes.GET_LOCATIONS_SUCCESS: {
            return {
                ...state,
                loadingLocations: false,
                locations: payload.locations as InventoryLocation[],
            };
        }

        case LocationsTypes.GET_PRODUCT_LOCATIONS_STARTED: {
            return {
                ...state,
            };
        }

        case LocationsTypes.GET_PRODUCT_LOCATIONS_SUCCESS: {
            const index = state.locations.findIndex(location => location.inventoryLocationId === payload.locationId);
            const newState: InventoryState = dotProp.set(
                state,
                `locations.${index}.productLocations`,
                payload.productLocations
            );
            return {
                ...state,
                locations: newState.locations,
            };
        }

        case LocationsTypes.CREATE_PLACEMENTS_STARTED: {
            return {
                ...state,
                creatingPlacements: true,
            };
        }
        case LocationsTypes.CREATE_PLACEMENTS_SUCCESS: {
            return {
                ...state,
                creatingPlacements: false,
            };
        }

        case ProductTypes.GET_PRODUCT_TYPES_STARTED: {
            return {
                ...state,
                loadingProductTypes: true,
            };
        }
        case ProductTypes.GET_PRODUCT_TYPES_SUCCESS: {
            return {
                ...state,
                loadingProductTypes: false,
                productTypes: payload.productTypes as InventoryProductType[],
            };
        }

        case ArticleAttributesTypes.GET_ARTICLE_ATTRIBUTES_STARTED: {
            return {
                ...state,
                loadingArticleAttributes: true,
            };
        }
        case ArticleAttributesTypes.GET_ARTICLE_ATTRIBUTES_SUCCESS: {
            return {
                ...state,
                loadingArticleAttributes: false,
                articleAttributes: payload.articleAttributes as ArticleAttribute[],
            };
        }

        case ArticleAttributesTypes.GET_PRODUCT_ATTRIBUTES_STARTED: {
            return {
                ...state,
                loadingProductAttributes: true,
            };
        }

        case ArticleAttributesTypes.GET_PRODUCT_ATTRIBUTES_SUCCESS: {
            return {
                ...state,
                loadingProductAttributes: false,
                productAttributes: payload.productAttributes as ProductAttribute[],
            };
        }

        default: {
            return state;
        }
    }
}
