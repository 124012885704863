import { formatISO } from 'date-fns';
import _flatten from 'lodash/flatten';

import { AppThunk } from '../../reduxTypes';
import { Holidays } from '../../../utils/types';
import { FetchError } from '../../../models/AppError';
import { createFailedAction } from '../../../utils/helpers';

export const GET_HOLIDAYS_STARTED = 'GET_HOLIDAYS_STARTED';
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
export const GET_HOLIDAYS_FAILED = 'GET_HOLIDAYS_FAILED';

const getHolidaysStarted = () => ({
    type: GET_HOLIDAYS_STARTED,
});

const getHolidaysSuccess = (holidays: Holidays) => ({
    type: GET_HOLIDAYS_SUCCESS,
    payload: { holidays },
});

const getHolidaysFailed = createFailedAction(GET_HOLIDAYS_FAILED);

export const getHolidays = (): AppThunk => async dispatch => {
    dispatch(getHolidaysStarted());
    try {
        const thisYear = new Date().getFullYear();
        const baseUrl = 'https://webapi.no/api/v1/holidays/';
        const urls = [baseUrl + thisYear, baseUrl + (thisYear + 1)];

        const data = await Promise.all(
            urls.map(async url => {
                const response = await fetch(url);
                const obj = await response.json();
                return obj.data;
            })
        );

        // Format holidays
        const holidays: Holidays = {};
        for (const day of _flatten(data)) {
            const iso = formatISO(new Date(day.date), { representation: 'date' });
            holidays[iso] = day.description;
        }

        dispatch(getHolidaysSuccess(holidays));
    } catch (error) {
        dispatch(getHolidaysFailed(new FetchError(error)));
    }
};
