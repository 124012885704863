import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import LocationCard from '../../../components/Cards/LocationCard/LocationCard';
import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import LocationModal from '../../../components/Modals/LocationModal/LocationModal';
import { createLocation, getLocations } from '../../../state/ducks/inventory/Locations';
import { getArticleGroups } from '../../../state/ducks/statistics/StatisticsFilter';
import { useSelector } from '../../../state/hooks';
import { InventoryLocation, LocationTypes, NewLocation } from '../../../utils/types';

const InventoryLocations = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { locations, loadingLocations } = useSelector(state => state.inventory);
    const { primaryArticleGroups, secondaryArticleGroups, tertiaryArticleGroups } = useSelector(
        state => state.statistics
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLocations());
        dispatch(getArticleGroups());
    }, [dispatch]);

    const onCreateLocation = (location: NewLocation) => {
        dispatch(createLocation(location));
        setOpenModal(false);
    };

    return (
        <>
            <Box sx={{ mt: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h3'>Lokasjoner</Typography>
                </Box>
                {loadingLocations ? (
                    <SubtleSpinner text='Henter lokasjoner...' />
                ) : (
                    <>
                        <Box sx={{ my: 3 }}>
                            {locations.length ? (
                                <Grid container spacing={2}>
                                    {locations.map((location: InventoryLocation) => {
                                        return (
                                            <Grid key={location.inventoryLocationId} item xs={12} sm={'auto'}>
                                                <Link
                                                    to={location.inventoryLocationId.toString()}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <LocationCard
                                                        name={location.name}
                                                        locationType={LocationTypes[location.locationType]}
                                                        productType={'-'}
                                                    />
                                                </Link>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            ) : (
                                <Typography>Ingen lokasjoner</Typography>
                            )}
                        </Box>
                    </>
                )}
                <Button onClick={() => setOpenModal(!openModal)} variant='contained' startIcon={<Plus />}>
                    Opprett ny lokasjon
                </Button>
            </Box>
            <LocationModal
                open={openModal}
                title='Opprett ny lokasjon'
                handleToggleModal={() => setOpenModal(!openModal)}
                handleSaveLocation={onCreateLocation}
                primaryArticleGroups={primaryArticleGroups}
                secondaryArticleGroups={secondaryArticleGroups}
                tertiaryArticleGroups={tertiaryArticleGroups}
            />
        </>
    );
};

export default InventoryLocations;
