import { ReactNode } from 'react';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '../../../state/hooks';

export type Props = {
    children: ReactNode;
};

const BookingCalendar = ({ children }: Props) => {
    const { classes } = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const weekdays = ['mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag', 'søndag'];
    const weekdayHeader = weekdays.map(day => {
        let text = isMobile ? day.slice(0, 3) : day;
        if (isSmall) text = day.slice(0, 1);
        return (
            <Typography variant='subtitle1' className={classes.weekydaName} key={day + '_wd'}>
                {text}
            </Typography>
        );
    });

    return (
        <div>
            <div className={classes.header}>{weekdayHeader}</div>
            <div className={classes.calendar}>{children}</div>
        </div>
    );
};
const useStyles = makeStyles()(theme => ({
    header: {
        borderBottom: '2px solid #E5E5E5',
        display: 'grid',
        gridGap: 10,
        gridTemplateColumns: 'repeat(7, 1fr)',
        paddingBottom: 8,
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            paddingBottom: theme.spacing(1),
        },
    },
    calendar: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridGap: 10,
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            gridGap: 5,
        },
    },
    weekydaName: {
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
}));

export default BookingCalendar;
