import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button } from '@mui/material';
import { ChevronUp } from 'react-feather';

import MessageBubble from '../../../../components/Extras/MessageBubble/MessageBubble';
import { Message } from '../../../../utils/types';
import { makeStyles, useNotifications } from '../../../../state/hooks';
import { markMessagesAsRead } from '../../../../state/ducks/sms/MarkAsRead';
import PhoneNumber from '../../../../models/PhoneNumber';
import { concatenateMessages } from '../../../../utils/helpers';

type Props = {
    phoneNumber: PhoneNumber;
    messages: Message[];
    loadMore: () => void;
    limit: number;
};

// TODO Refactor styles from this container
const MessageContainer = ({ phoneNumber, messages, loadMore, limit }: Props) => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const notifications = useNotifications();

    useEffect(() => {
        if (phoneNumber) {
            dispatch(markMessagesAsRead(phoneNumber));
        }
    }, [notifications, messages, phoneNumber, dispatch]);

    const messagesContent = concatenateMessages([...messages].reverse()).map((message: Message, index) => {
        let isRead = false;
        for (const notification of notifications) {
            if (notification.messages.includes(message.messageId) && notification.phoneNumber.equals(phoneNumber)) {
                isRead = true;
                break;
            }
        }

        return <MessageBubble message={message} key={'message' + index} read={isRead} />;
    });

    const handleClick = () => loadMore();

    return (
        <div className={classes.messagesContainer}>
            {messagesContent.length > 0 ? (
                <>
                    {messages?.length === limit && (
                        <div className={classes.buttonContainer}>
                            <Button
                                className={classes.button}
                                onClick={handleClick}
                                endIcon={<ChevronUp color='#b3b3b3' />}
                            >
                                Tidligere meldinger
                            </Button>
                        </div>
                    )}
                    {messagesContent}
                </>
            ) : (
                <Typography className={classes.noMessages}>Ingen meldinger å vise</Typography>
            )}
        </div>
    );
};

const useStyles = makeStyles()({
    messagesContainer: {
        //maxWidth: 600,
        margin: 'auto',
        marginBottom: 30,
        padding: 10,
    },
    noMessages: {
        opacity: 0.5,
        textAlign: 'center',
    },
    buttonContainer: {
        marginTop: -8,
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        margin: 'auto',
        marginBottom: 16,
    },
});

export default MessageContainer;
