import { useMemo, useState } from 'react';

import BikeBoxCalendar from '../../../../../components/Extras/BikeBoxCalendar/BikeBoxCalendar';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { useGetBikeBoxAvailabilityByIdQuery } from '../../../../../services/BikeBoxAvailability';
import NewReservationFormContainer from '../NewReservationFormContainer/NewReservationFormContainer';

type Props = {
    bikeBoxId: string;
    activeStoreLocation: string;
};

const BikeBoxCalendarContainer = ({ bikeBoxId, activeStoreLocation }: Props) => {
    const [showNewReservationModal, setShowNewReservationModal] = useState(true);
    const [modalDate, setModalDate] = useState<string | null>(null);
    const [currentMonth, setCurrentMonth] = useState(0);

    const {
        data: reservationAvailability,
        isLoading: loadingAvailability,
        isSuccess: loadingAvailibilitySuccess,
    } = useGetBikeBoxAvailabilityByIdQuery({ id: bikeBoxId, storeName: activeStoreLocation });

    const handleDateClicked = (date: Date) => {
        setModalDate(date.toISOString());
        setShowNewReservationModal(true);
    };

    const bikeBoxAvailability = useMemo(() => {
        if (loadingAvailability || !loadingAvailibilitySuccess) return [];
        return reservationAvailability.map((monthAvailability, index) => {
            return monthAvailability.map((dayAvailability, dayIndex) => {
                return {
                    date: dayAvailability.date,
                    available: dayAvailability.available,
                    selected: false,
                    rangeSelected: false,
                };
            });
        });
    }, [reservationAvailability]);

    const toggleModal = () => {
        if (modalDate) setModalDate(null);
        setShowNewReservationModal(!showNewReservationModal);
    };

    return (
        <>
            {bikeBoxAvailability.length > 0 ? (
                <BikeBoxCalendar
                    disabled={loadingAvailability}
                    currentMonth={currentMonth}
                    setCurrentMonth={setCurrentMonth}
                    reservationAvailability={bikeBoxAvailability}
                    onDateClick={handleDateClicked}
                />
            ) : (
                <SubtleSpinner />
            )}
            {modalDate && (
                <NewReservationFormContainer
                    openModal={showNewReservationModal}
                    month={currentMonth}
                    toggleModal={toggleModal}
                    defaultValues={{ toDate: modalDate }}
                    bikeBoxId={bikeBoxId}
                    activeStoreLocation={activeStoreLocation}
                />
            )}
        </>
    );
};

export default BikeBoxCalendarContainer;
