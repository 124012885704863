import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import BikeBoxCard from '../../../../../components/Cards/BikeBoxCard/BikeBoxCard';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { useGetBikeBoxesQuery } from '../../../../../services/BikeBoxes';

type Props = {
    activeStoreLocation: string;
};

const BikeBoxesContainer = ({ activeStoreLocation }: Props) => {
    const { data, isLoading, isSuccess } = useGetBikeBoxesQuery({ storeName: activeStoreLocation });

    return (
        <>
            {/* <Box sx={{ mt: 4 }}>
                {!isLoading && isSuccess && data ? (
                    <>
                        <Typography variant='h3'>Aktive bokser</Typography>
                        <Grid container sx={{ mt: 1 }} spacing={2}>
                            {data
                                .filter(box => box.activeReservation !== null)
                                .map(bikeBox => (
                                    <Grid item key={bikeBox.id} xs={12} sm={'auto'}>
                                        <Link to={bikeBox.id.toString()} style={{ textDecoration: 'none' }}>
                                            <BikeBoxCard bikeBox={bikeBox} />
                                        </Link>
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                ) : (
                    <SubtleSpinner />
                )}
            </Box> */}
            <Box sx={{ mt: 4 }}>
                {/* <Typography variant='h3'>Alle bokser</Typography> */}
                {!isLoading && isSuccess && data ? (
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        {data.map(bikeBox => (
                            <Grid item key={bikeBox.id} xs={12} sm={'auto'}>
                                <Link to={bikeBox.id.toString()} style={{ textDecoration: 'none' }}>
                                    <BikeBoxCard bikeBox={bikeBox} />
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <SubtleSpinner />
                )}
            </Box>
        </>
    );
};

export default BikeBoxesContainer;
