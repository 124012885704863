import { authFetch, createFailedAction } from '../../../utils/helpers';
import { InventoryProductType } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const GET_PRODUCT_TYPES_STARTED = 'GET_PRODUCT_TYPES_STARTED';
export const GET_PRODUCT_TYPES_SUCCESS = 'GET_PRODUCT_TYPES_SUCCESS';
export const GET_PRODUCT_TYPES_FAILED = 'GET_PRODUCT_TYPES_FAILED';

const getProductTypesStarted = () => ({
    type: GET_PRODUCT_TYPES_STARTED,
});

const getProductTypesSuccess = (productTypes: InventoryProductType[]) => ({
    type: GET_PRODUCT_TYPES_SUCCESS,
    payload: { productTypes },
});

const getProductTypesFailed = createFailedAction(GET_PRODUCT_TYPES_FAILED);

export const getProductTypes = (): AppThunk => async (dispatch, getState, fb) => {
    dispatch(getProductTypesStarted());
    const apiUrl = getState().system.apiURL;
    if (!apiUrl) throw new Error('Could not fetch API URL.');
    try {
        const url = '/inventoryProductTypes';
        const result = await authFetch(fb, url, 'GET', undefined, apiUrl);
        const data: InventoryProductType[] = await result.json();

        dispatch(getProductTypesSuccess(data));
    } catch (error) {
        dispatch(getProductTypesFailed(error));
    }
};
