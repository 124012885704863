import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        minHeight: '80vh',
    },
    loadingBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
    },
    spacer: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        },
    },
    tabContent: {
        marginTop: theme.spacing(3),
    },
}));
