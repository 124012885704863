import dotProp from 'dot-prop-immutable';

import PhoneNumber from '../../../models/PhoneNumber';
import * as DeleteConversationTypes from './DeleteConversations';
import * as ConversationSearchTypes from './ConversationSearch';
import * as ActiveConversationTypes from './ActiveConversation';
import * as CustomerSearchTypes from './CustomerSearch';
import * as UpdateCustomerTypes from './UpdateCustomerInfo';
import { ConversationSearchResults } from '../../../utils/types';
import Conversation from '../../../models/Conversation';
import Customer from '../../../models/Customer';

interface ConversationsState {
    activeConversationId: string | null; // Customer number
    activePhoneNumber: PhoneNumber | null;
    searchResults: ConversationSearchResults | null;
    conversationsearch: boolean;
    customerSearch: boolean;
    customerSearchResult: Customer[] | null;
    isUpdatingCustomer: boolean;
}

const initialState: ConversationsState = {
    activeConversationId: null, // Default to newest message
    activePhoneNumber: null,
    searchResults: null,
    conversationsearch: false,
    customerSearch: false,
    customerSearchResult: null,
    isUpdatingCustomer: false,
};

export default function conversationsReducer(state = initialState, action: any): ConversationsState {
    const { type, payload } = action;

    switch (type) {
        // Set active conversation
        case ActiveConversationTypes.SET_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversationId: payload.customerNumber,
            };

        // Set active phone number
        case ActiveConversationTypes.SET_ACTIVE_PHONE_NUMBER:
            return {
                ...state,
                activePhoneNumber: payload.phoneNumber,
            };

        // Toggle the flag in the conversation in local redux state (search results)
        case ConversationSearchTypes.FLAG_SEARCH_RESULT_CONVERSATION:
            if (state.searchResults?.hits) {
                const index = state.searchResults.hits.findIndex(
                    (item: Conversation) => item.customer?.customerNumber === payload.customerNumber
                );

                if (index >= 0) {
                    const currentFlagValue = state.searchResults.hits[index].flagged;
                    const updatedResults = dotProp.set(state.searchResults, `hits.${index}.flagged`, !currentFlagValue);

                    return {
                        ...state,
                        searchResults: updatedResults,
                    };
                }
            }
            return state;

        // Delete a conversation
        case DeleteConversationTypes.DELETE_CONVERSATION_STARTED:
            return {
                ...state,
            };
        case DeleteConversationTypes.DELETE_CONVERSATION_SUCCESS:
            return {
                ...state,
                activeConversationId: null,
                activePhoneNumber: null,
            };

        // Search after conversation
        case ConversationSearchTypes.CONVERSATION_SEARCH_STARTED:
            return {
                ...state,
                conversationsearch: true,
            };

        case ConversationSearchTypes.CONVERSATION_SEARCH_SUCCESS:
            return {
                ...state,
                searchResults: payload.results,
                conversationsearch: false,
            };

        case ConversationSearchTypes.CONVERSATION_SEARCH_FAILED:
        case ConversationSearchTypes.CLEAR_CONVERSATION_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: null,
                conversationsearch: false,
            };

        // Search after customers
        case CustomerSearchTypes.CUSTOMER_SEARCH_STARTED:
            return {
                ...state,
                customerSearch: true,
            };
        case CustomerSearchTypes.CUSTOMER_SEARCH_SUCCESS:
            return {
                ...state,
                customerSearchResult: payload.customerSearchResult,
                customerSearch: false,
            };
        case CustomerSearchTypes.CUSTOMER_SEARCH_FAILED:
            return {
                ...state,
                customerSearch: false,
                customerSearchResult: null,
            };

        case CustomerSearchTypes.CLEAR_CUSTOMER_SEARCH:
            return {
                ...state,
                customerSearch: false,
                customerSearchResult: null,
            };

        case UpdateCustomerTypes.UPDATE_CUSTOMER_INFO_STARTED:
            return {
                ...state,
                isUpdatingCustomer: true,
            };

        case UpdateCustomerTypes.UPDATE_CUSTOMER_INFO_SUCCESS:
        case UpdateCustomerTypes.UPDATE_CUSTOMER_INFO_FAILED:
            return {
                ...state,
                isUpdatingCustomer: false,
            };

        default:
            return state;
    }
}
