import { Box, ButtonBase, Paper, Typography } from '@mui/material';
import useStyles from './LocationCard.styles';

export type Props = {
    name: string;
    productType: string;
    locationType: string;
    onClick?: () => void;
};

const LocationCard = ({ name, productType, locationType, onClick }: Props) => {
    const { classes } = useStyles();

    return (
        <ButtonBase disableRipple onClick={onClick} className={classes.button}>
            <Paper className={classes.root}>
                <Typography className={classes.title} variant='h3'>
                    {name}
                </Typography>
                <Box className={classes.labelBox}>
                    <Typography variant='subtitle2' sx={{ mr: 1 }}>
                        Lokasjonstype:
                    </Typography>
                    <Typography>{locationType}</Typography>
                </Box>
            </Paper>
        </ButtonBase>
    );
};

export default LocationCard;
