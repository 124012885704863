import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ArticleAttributesForm, {
    SelectAttribute,
} from '../../../components/Form/ArticleAttributesForm/ArticleAttributesForm';
import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { Article, ArticleVariant } from '../../../models/Article';
import {
    getProductAttributes,
    getArticleAttributes,
    saveProductAttributeValues,
} from '../../../state/ducks/inventory/ArticleAttributes';
import { useSelector } from '../../../state/hooks';

type Props = {
    article: Article;
};

const ProductAttributesContainer = ({ article }: Props) => {
    const { productAttributes, articleAttributes } = useSelector(state => state.inventory);
    const dispatch = useDispatch();

    const articleVariant: ArticleVariant = article.variants![0];

    const saveProductAttributes = (selectedAttributes: SelectAttribute[]) => {
        const newValues = selectedAttributes.filter(a => a.status === 'edited');
        dispatch(saveProductAttributeValues(article.articleId, newValues));
    };

    useEffect(() => {
        dispatch(getProductAttributes(article.articleId));
        dispatch(
            getArticleAttributes(
                articleVariant.articleGroup1Id,
                articleVariant.articleGroup2Id,
                articleVariant.articleGroup3Id
            )
        );
    }, [article.articleId]);

    return (
        <>
            {articleAttributes && productAttributes ? (
                <ArticleAttributesForm
                    attributes={articleAttributes}
                    productAttributes={productAttributes}
                    handleSaveAttributes={saveProductAttributes}
                />
            ) : (
                <SubtleSpinner text='Laster attributter...' />
            )}
        </>
    );
};

export default ProductAttributesContainer;
