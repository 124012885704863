import * as SendMessageTypes from './SendMessage';

interface smsState {
    sendingMessage: boolean;
    messageSentSuccess: boolean;
    sentMessage: string | null;
}

const initialState: smsState = {
    sendingMessage: false,
    messageSentSuccess: false,
    sentMessage: null,
};

export default function smsReducer(state = initialState, action: any) {
    const { type } = action;

    switch (type) {
        case SendMessageTypes.SENDING_MESSAGE:
            return {
                ...state,
                sendingMessage: true,
            };
        case SendMessageTypes.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                sendingMessage: false,
                messageSentSuccess: true,
                sentMessage: action.message,
            };
        default:
            return state;
    }
}
