import { Box, Tab, Typography } from '@mui/material';
import { useState } from 'react';
import ArticleInventoryContainer from '../../../containers/Generic/ArticleInventoryContainer/ArticleInventoryContainer';
import ProductAttributesContainer from '../../../containers/Generic/ProductAttributesContainer/ProductAttributesContainer';
import { Article } from '../../../models/Article';
import { currencyFormatter } from '../../../utils/format';
import SubtleSpinner from '../../Indicators/SubtleSpinner/SubtleSpinner';
import Row from '../../Layouts/Row/Row';
import TabMenu from '../../Navigation/TabMenu/TabMenu';
import TabPanel from '../../Navigation/TabMenu/TabPanel/TabPanel';
import Modal from '../Modal/Modal';

import useStyles from './ArticleModal.styles';

export type Props = {
    open: boolean;
    article: Article;
    loading: boolean;
    handleToggleModal: () => void;
};

const ArticleModal = ({ open, article, loading, handleToggleModal }: Props) => {
    const { classes, cx } = useStyles();

    const [value, setValue] = useState(0);
    const handleTabChange = (_: any, newValue: number) => setValue(newValue);

    return (
        <Modal openModal={open} toggleModal={handleToggleModal} maxWidth='lg'>
            {loading ? (
                <Box className={cx(classes.loadingBox, classes.root)}>
                    <SubtleSpinner text='Henter artikkel...' />
                </Box>
            ) : (
                <Box className={classes.root}>
                    <>
                        <Box className={classes.spacer}>
                            <Row gap={3}>
                                <Box>
                                    <Typography variant='subtitle2'>Varenummer</Typography>
                                    <Typography variant='body1'>{article.articleNo}</Typography>
                                </Box>
                                <Box>
                                    <Typography variant='subtitle2'>Varenavn</Typography>
                                    <Typography variant='body1'>{article.articleName}</Typography>
                                </Box>
                            </Row>
                        </Box>
                        <TabMenu value={value} handleTabChange={handleTabChange}>
                            <Tab disableRipple label='Lagerstatus' />
                            <Tab disableRipple label='Vareinfo' />
                            <Tab disableRipple label='Attributter' />
                        </TabMenu>
                        <Box className={classes.spacer}>
                            <Box className={classes.tabContent}>
                                <TabPanel value={value} index={0}>
                                    <ArticleInventoryContainer />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Box>
                                        <Typography variant='subtitle2'>Salgspris</Typography>
                                        <Typography variant='body1' align='right' sx={{ width: 'fit-content' }}>
                                            {currencyFormatter.format(article.grossPrice)},-
                                        </Typography>
                                    </Box>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <ProductAttributesContainer article={article} />
                                </TabPanel>
                            </Box>
                        </Box>
                    </>
                </Box>
            )}
        </Modal>
    );
};

export default ArticleModal;
