import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { Trash2 } from 'react-feather';

import useStyles from './GeneratePlacementsTable.styles';

type Props = {
    placements: string[];
    disableRowActions: boolean;
    handleDeletePlacement: (placement: string) => void;
};

const GeneratePlacementsTable = ({ placements, disableRowActions, handleDeletePlacement }: Props) => {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Navn</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {placements.length &&
                    placements.map(placement => {
                        return (
                            <TableRow key={placement}>
                                <TableCell className={classes.tableBodyCell}>{placement}</TableCell>
                                <TableCell className={classes.tableBodyCell} align='right'>
                                    <IconButton
                                        onClick={() => handleDeletePlacement(placement)}
                                        disabled={disableRowActions}
                                    >
                                        <Trash2 size={20} color={theme.palette.error.main} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
};

export default GeneratePlacementsTable;
