import { ArticleManufacturer } from '../utils/types';
import { api } from './api';

export const articleManufacturersApi = api.injectEndpoints({
    endpoints: builder => ({
        getArticleManufacturers: builder.query<ArticleManufacturer[], void>({
            query: () => ({
                url: `/articleManufacturers`,
                method: 'GET',
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
        }),
    }),
});

export const { useGetArticleManufacturersQuery } = articleManufacturersApi;
