import { ReactNode } from 'react';
import { Dialog, DialogProps, IconButton, Theme, useMediaQuery } from '@mui/material';

import useStyles from './Modal.styles';
import CloseIcon from '@mui/icons-material/Close';

export type Props = {
    children: ReactNode;
    openModal: boolean;
    maxWidth: DialogProps['maxWidth']; // from Dialog prop Types
    toggleModal: () => void;
};

/**
 * Component representing a standard modal.
 */
const Modal = ({ children, openModal, maxWidth, toggleModal }: Props) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const { classes, cx } = useStyles();

    let sizeClasses = classes.large;
    if (maxWidth === 'md') sizeClasses = classes.medium;
    if (maxWidth === 'sm') sizeClasses = classes.small;
    if (maxWidth === 'xs') sizeClasses = classes.xsmall;

    return (
        <Dialog
            fullScreen={isMobile}
            onClose={toggleModal}
            open={openModal}
            fullWidth={true}
            classes={{ paper: cx(classes.paper, isMobile ? '' : sizeClasses, isMobile ? classes.mobilePaper : null) }}
            maxWidth={maxWidth}
        >
            <div className={classes.closeContainer}>
                <IconButton onClick={toggleModal} className={classes.closeButton} size='large'>
                    <CloseIcon />
                </IconButton>
            </div>

            <div className={maxWidth === 'lg' ? classes.largeContent : ''}>{children}</div>
        </Dialog>
    );
};

export default Modal;
