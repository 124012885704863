import { makeStyles } from '../../../state/hooks';

export default makeStyles()({
    buttonRoot: {
        transition: '0.15s all ease',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
        borderRadius: 10,
        margin: '0 4px',
    },
    root: {
        display: 'flex',
        padding: '2px 4px',
    },
    text: {
        color: '#222',
        fontWeight: 500,
        marginRight: 4,
    },
    chevron: {
        transition: '0.3s all ease',
    },
    chevronClosed: {
        transform: 'rotate(0deg)',
    },
    chevronOpen: {
        transform: 'rotate(180deg)',
    },
});
