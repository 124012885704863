import { BikeBoxAvailability } from '../utils/types';
import { api } from './api';

export const bikeBoxAvailabilityApi = api.injectEndpoints({
    endpoints: builder => ({
        getBikeBoxAvailability: builder.query<BikeBoxAvailability, { storeName: string }>({
            query: ({ storeName }) => ({
                url: `/bikeBoxAvailability?storeName=${storeName}`,
                method: 'GET',
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
            providesTags: ['BikeBoxAvailability'],
        }),
        getBikeBoxAvailabilityById: builder.query<BikeBoxAvailability, { id: string; storeName: string }>({
            query: ({ id, storeName }) => ({
                url: `/bikeBoxAvailability/${id}?storeName=${storeName}`,
                method: 'GET',
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
            providesTags: ['BikeBoxAvailability'],
        }),
    }),
});

export const { useGetBikeBoxAvailabilityQuery, useGetBikeBoxAvailabilityByIdQuery } = bikeBoxAvailabilityApi;
