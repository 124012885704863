import { collection, doc, updateDoc, getFirestore } from 'firebase/firestore';
import app from '../../../configs/firebaseConfig';

import { WeekdayCapacity } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';
import { createFailedAction } from '../../../utils/helpers';
import { getServiceCapacity } from './GetServiceCapacity';

// Update weekday capacity
export const SET_WEEKDAY_CAPACITY_STARTED = 'SET_WEEKDAY_CAPACITY_STARTED';
export const SET_WEEKDAY_CAPACITY_SUCCESS = 'SET_WEEKDAY_CAPACITY_SUCCESS';
export const SET_WEEKDAY_CAPACITY_FAILED = 'SET_WEEKDAY_CAPACITY_FAILED';

const setWeekdayCapacityStarted = () => ({
    type: SET_WEEKDAY_CAPACITY_STARTED,
});

const setWeekdayCapacitySuccess = () => ({
    type: SET_WEEKDAY_CAPACITY_SUCCESS,
});

const setWeekdayCapacityFailed = createFailedAction(SET_WEEKDAY_CAPACITY_FAILED);

/**
 * Updates the weekday capacity for each day of the week.
 * @param newWeekdayCapacity An array of 7 integers, where the first represents sunday.
 * @param storeName The store location to update.
 */
export const setWeekdayCapacity =
    (newWeekdayCapacity: WeekdayCapacity, storeName: string): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(setWeekdayCapacityStarted());
        try {
            const db = getFirestore(app);
            const bookingsRef = collection(db, 'bookings');
            const bookingRef = doc(bookingsRef, storeName);
            await updateDoc(bookingRef, {
                weekdayCapacity: newWeekdayCapacity,
            });
            dispatch(setWeekdayCapacitySuccess());
            dispatch(getServiceCapacity());
        } catch (error) {
            dispatch(setWeekdayCapacityFailed(error));
        }
    };
