import { TablePagination } from '@mui/material';
import { useState } from 'react';
import { ArticlePlacement } from '../../../utils/types';
import Column from '../../Layouts/Column/Column';
import MobilePlacementsTableRow from './MobilePlacementsTableRow/MobilePlacementsTableRow';

export type Props = {
    placements: ArticlePlacement[];
    handleClearPlacement: (placements: { articlePlacement: ArticlePlacement; count: number }[]) => void;
    handleClearMultipleProductLocations: (placements: ArticlePlacement[]) => void;
};

const MobileInventoryPlacementsTable = ({
    placements,
    handleClearPlacement,
    handleClearMultipleProductLocations,
}: Props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            <Column gap={2}>
                {placements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(placement => {
                    const isVariant = placement.sizeColorId !== null;
                    if (isVariant) {
                        return (
                            <MobilePlacementsTableRow
                                key={placement.sizeColorId}
                                header={{
                                    key: 'Varenummer',
                                    value: placement.supplierArticleNumber ?? '-',
                                }}
                                body={[
                                    {
                                        key: 'Varenavn',
                                        value: placement.articleName ?? '-',
                                    },
                                    {
                                        key: 'Farge',
                                        value: placement.colorName ?? '-',
                                    },
                                    {
                                        key: 'Størrelse',
                                        value: placement.sizeName ?? '-',
                                    },
                                    {
                                        key: 'Antall',
                                        value: placement.count.toString(),
                                    },
                                ]}
                                rowMenuItems={[
                                    {
                                        name: 'Tøm vare',
                                        clickHandler: () =>
                                            handleClearPlacement([{ articlePlacement: placement, count: 1 }]),
                                    },
                                    {
                                        name: 'Tøm flere varer',
                                        clickHandler: () => handleClearMultipleProductLocations([placement]),
                                    },
                                ]}
                            />
                        );
                    } else {
                        return (
                            <MobilePlacementsTableRow
                                key={placement.placementId}
                                header={{
                                    key: 'Plassering',
                                    value: placement.placementName ?? '-',
                                }}
                                body={[
                                    {
                                        key: 'Varenummer',
                                        value: placement.articleNumber ?? '-',
                                    },
                                    {
                                        key: 'Varenavn',
                                        value: placement.articleName ?? 'Ledig',
                                    },
                                ]}
                                rowMenuItems={[
                                    {
                                        name: 'Tøm plassering',
                                        clickHandler: () =>
                                            handleClearPlacement([{ articlePlacement: placement, count: 1 }]),
                                    },
                                ]}
                            />
                        );
                    }
                })}
            </Column>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={placements.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Rader per side'
            />
        </>
    );
};

export default MobileInventoryPlacementsTable;
