import { makeStyles } from '../../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    description: {
        margin: '0 13px',
        fontWeight: 480,
        fontSize: '1em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8em',
            margin: '0 8px',
        },
    },
    templates: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    select: {
        height: '100%',
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.primary.main,
    },
}));
