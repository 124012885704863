import Order from '../models/Order';
import { ServiceBooking } from '../utils/types';
import { api } from './api';

export const ordersApi = api.injectEndpoints({
    endpoints: builder => ({
        getBookings: builder.query<ServiceBooking[], { storeName: string; fromDate: string }>({
            query: ({ storeName, fromDate }) => ({
                url: `/serviceBookings?storeName=${storeName}&fromDate=${fromDate}`,
                method: 'GET',
            }),
            providesTags: ['Bookings'],
        }),
        deleteBooking: builder.mutation<any, { orderId: number; storeName: string }>({
            query: ({ orderId, storeName }) => ({
                url: `/serviceBookings/${orderId}?storeName=${storeName}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Bookings'],
        }),
        getOrders: builder.query<Order[], { fromDate: string }>({
            query: ({ fromDate }) => ({
                url: `/orders?fromDate=${fromDate}`,
                method: 'GET',
            }),
        }),
        getCriticalOrders: builder.query<Order[], { fromDate: string; templateIds: Array<number> }>({
            query: ({ fromDate, templateIds }) => {
                const orderTemplateIds = templateIds.map(templateId => `orderTemplateId[]=${templateId}`).join('&');
                return {
                    url: `/orders?${orderTemplateIds}`,
                    params: {
                        type: 'critical',
                        fromDate,
                    },
                    method: 'GET',
                };
            },
            transformResponse: (response: Order[]) => {
                return response.map(order => new Order(order));
            },
        }),
    }),
});
export const { useGetBookingsQuery, useGetOrdersQuery, useDeleteBookingMutation, useGetCriticalOrdersQuery } =
    ordersApi;
