import { AppThunk } from '../../reduxTypes';
import firebase from '../../../configs/firebaseConfig';
import PhoneNumber from '../../../models/PhoneNumber';
import { createFailedAction } from '../../../utils/helpers';

export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_READ_SUCCES = 'MARK_AS_READ_SUCCES';
export const MARK_AS_READ_FAILED = 'MARK_AS_READ_FAILED';

const markAsRead = () => ({
    type: MARK_AS_READ,
});

const markAsReadSuccess = () => ({
    type: MARK_AS_READ_SUCCES,
});

const markAsReadFiled = createFailedAction(MARK_AS_READ_FAILED);

export const markMessagesAsRead =
    (phoneNumber: PhoneNumber): AppThunk =>
    async dispatch => {
        dispatch(markAsRead());
        const controlNumber = phoneNumber.get().substring(2);

        try {
            // wait 3 sek before marking as read
            setTimeout(async () => {
                const messageControlSnap = await firebase
                    .firestore()
                    .collection(`messageControl`)
                    .doc('newMessages')
                    .get();
                const messageControl = messageControlSnap.data();

                if (messageControl?.[controlNumber]) {
                    // remove phone number from newMessages control object – this will hide the messageControl in the app
                    delete messageControl[controlNumber];

                    await firebase
                        .firestore()
                        .collection(`messageControl`)
                        .doc('newMessages')
                        .set(messageControl, { merge: false });
                    dispatch(markAsReadSuccess());
                }

                // If there is no notification document throw error
                if (!messageControl) throw new Error('Notification document (message control) does not exist');
            }, 3000);
        } catch (error) {
            dispatch(markAsReadFiled(error));
        }
    };
