import { Theme } from '@mui/material';
import { makeStyles } from '../../../state/hooks';
import constants from '../../../styles/styleConstants';

export default makeStyles()((theme: Theme) => ({
    root: {
        marginBottom: 70,
    },
    paper: {
        padding: 20,
        boxShadow: constants.lightBoxShadow,
        borderRadius: 8,
    },
    inputBox: {
        borderBottom: 'none',
        padding: '0 10px',
        [theme.breakpoints.down('md')]: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            alignItems: 'center',
        },
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 15,
        rowGap: '10px',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'repeat(7, 1fr)',
        },
    },
    gridTitle: {},
    gridInputBox: {},
}));
