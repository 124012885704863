import { Button, TableCell, TableRow } from '@mui/material';
import { ReactNode, useState } from 'react';
import { ChevronUp, ChevronDown } from 'react-feather';

import MobileTableRow, { RowData } from '../MobileTableRow/MobileTableRow';
import useStyles from './MobileExpandableTableRow.styles';

export type Props = {
    headerRow: RowData;
    bodyRows: ReactNode;
    buttonText: {
        opened: string;
        closed: string;
    };
    children: ReactNode;
    onOpen: () => void;
};

const MobileExpandableTableRow = ({ headerRow, bodyRows, buttonText, children, onOpen }: Props) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    const buttonIcon = open ? <ChevronUp size={26} /> : <ChevronDown size={26} />;

    const handleOpen = () => {
        setOpen(prev => !prev);
        if (!open) onOpen();
    };

    // TODO Create a smooth transition from opened to closed state

    return (
        <MobileTableRow isExpandable={true} headerRow={headerRow} bodyRows={bodyRows}>
            <TableRow>
                <TableCell colSpan={2} className={classes.tableCell}>
                    {open && children}
                    <Button startIcon={buttonIcon} onClick={handleOpen} sx={{ mt: open ? 3 : 0 }}>
                        {open ? buttonText.opened : buttonText.closed}
                    </Button>
                </TableCell>
            </TableRow>
        </MobileTableRow>
    );
};

export default MobileExpandableTableRow;
