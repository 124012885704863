import { CircularProgress, Typography } from '@mui/material';

import { makeStyles } from '../../../state/hooks';

export type Props = {
    text?: string;
    style?: object;
    size?: string | number;
    className?: string;
    textClassName?: string;
};

const SubtleSpinner = ({ text, style = {}, className = '', textClassName = '', size }: Props) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.progressContainer, className)} style={style}>
            <CircularProgress size={size} style={{ color: '#ccc' }} />
            {text && (
                <Typography variant='h6' className={cx(classes.loadingText, textClassName)}>
                    {text}
                </Typography>
            )}
        </div>
    );
};

const useStyles = makeStyles()({
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    loadingText: {
        marginTop: 10,
        fontSize: '1.2em',
        color: '#666',
        fontWeight: 400,
    },
});

export default SubtleSpinner;
