import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    textBox: {
        textAlign: 'left',
    },
}));
