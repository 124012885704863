import dotProp from 'dot-prop-immutable';

import * as OrderTypes from './GetFutureOrders';
import * as OrderTemplateTypes from './GetOrderTemplates';
import * as OrderLinesTypes from './GetOrderLines';
import * as OrderHistoryTypes from './GetOrderHistory';
import * as OpenOrderTypes from './SetOpenedOrder';
import Order from '../../../models/Order';
import { Employee, FutureOrders, OrderTemplate } from '../../../utils/types';

interface PckState {
    orderTemplates: OrderTemplate[] | null;
    futureOrders: FutureOrders | null;
    unsortedFutureOrders: Order[] | null;
    criticalMetadata: { total: number } | null;
    openedOrder: Order | null;
    fethcingEmployees: boolean;
    fethcingOrderTemplates: boolean;
    fetchingFutureOrders: boolean;
    fetchingExtendedOrder: boolean;
    fetchingOrderLines: any;
    fetchingOrderHistory: boolean;
}

export const initialState: PckState = {
    orderTemplates: null,
    futureOrders: null,
    unsortedFutureOrders: null,
    criticalMetadata: null,
    openedOrder: null,
    fethcingEmployees: false,
    fethcingOrderTemplates: false,
    fetchingFutureOrders: false,
    fetchingExtendedOrder: false,
    fetchingOrderLines: {},
    fetchingOrderHistory: false,
};

export default function pckReducer(state = initialState, action: any): PckState {
    const { type, payload } = action;
    let orderFetching = {}; // Object with orderId and fetching status, e.g. {232323: true, 232425: fales}
    let newState: PckState;

    switch (type) {
        case OrderTemplateTypes.GET_ORDER_TEMPLATES:
            return {
                ...state,
                fethcingOrderTemplates: true,
            };
        case OrderTemplateTypes.GET_ORDER_TEMPLATES_SUCCESS:
            return {
                ...state,
                orderTemplates: payload.templates,
                fethcingOrderTemplates: false,
            };
        case OrderTemplateTypes.GET_ORDER_TEMPLATES_FAILED:
            return {
                ...state,
                fethcingOrderTemplates: false,
            };

        case OrderTypes.GET_ORDERS:
            return {
                ...state,
                fetchingFutureOrders: true,
            };
        case OrderTypes.GET_ORDERS_SUCCESS:
            return {
                ...state,
                futureOrders: payload.orders,
                fetchingFutureOrders: false,
            };
        case OrderTypes.GET_ORDERS_FAILED:
            return {
                ...state,
                fetchingFutureOrders: false,
            };

        case OrderTypes.STORE_UNSORTED_FUTURE_ORDERS:
            return {
                ...state,
                unsortedFutureOrders: payload.orders,
            };

        case OpenOrderTypes.SET_OPEN_ORDER:
            return {
                ...state,
                openedOrder: payload.order,
            };

        case OrderLinesTypes.GET_ORDER_LINES:
            orderFetching = dotProp.set(state.fetchingOrderLines, `${payload.id}`, true);
            return {
                ...state,
                fetchingOrderLines: orderFetching,
            };

        case OrderLinesTypes.GET_ORDER_LINES_SUCCESS:
            // TODO: Make this two different actions such that this one action does not do multiple things
            orderFetching = dotProp.set(state.fetchingOrderLines, `${payload.id}`, false);

            if (payload.storeInOrderHistory) {
                // Getting index of orderHistory-element to update
                let index = state.openedOrder?.orderHistory?.findIndex(order => order.orderId === payload.id);
                // Creating new state object
                newState = dotProp.set(state, `openedOrder.orderHistory.${index}.orderLines`, payload.orderLines);
            } else {
                newState = dotProp.set(state, 'openedOrder.orderLines', payload.orderLines);
            }
            return {
                ...state,
                openedOrder: newState.openedOrder,
                fetchingOrderLines: orderFetching,
            };

        case OrderLinesTypes.GET_ORDER_LINES_FAILED:
            orderFetching = dotProp.set(state.fetchingOrderLines, `${payload.id}`, false);

            return {
                ...state,
                fetchingOrderLines: orderFetching,
            };

        case OrderLinesTypes.RESET_FETCHING_ORDERLINES:
            return {
                ...state,
                fetchingOrderLines: {},
            };

        case OrderHistoryTypes.GET_ORDER_HISTORY:
            return {
                ...state,
                fetchingOrderHistory: true,
            };

        case OrderHistoryTypes.GET_ORDER_HISTORY_SUCCESS:
            if (state.openedOrder) {
                return {
                    ...state,
                    openedOrder: {
                        ...state.openedOrder,
                        orderHistory: payload.orderHistory,
                    },
                    fetchingOrderHistory: false,
                };
            } else {
                return state;
            }

        case OrderHistoryTypes.GET_ORDER_HISTORY_FAILED:
            return {
                ...state,
                fetchingOrderHistory: false,
            };

        default:
            return state;
    }
}
