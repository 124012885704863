import { SelectAttribute } from '../../../components/Form/ArticleAttributesForm/ArticleAttributesForm';
import { ArticleAttributeValueWithStatus } from '../../../components/Modals/ArticleAttributeValuesModal/ArticleAttributeValuesModal';
import { authFetch, createFailedAction } from '../../../utils/helpers';
import { ArticleAttribute, ProductAttribute } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const GET_ARTICLE_ATTRIBUTES_STARTED = 'GET_ARTICLE_ATTRIBUTES_STARTED';
export const GET_ARTICLE_ATTRIBUTES_SUCCESS = 'GET_ARTICLE_ATTRIBUTES_SUCCESS';
export const GET_ARTICLE_ATTRIBUTES_FAILED = 'GET_ARTICLE_ATTRIBUTES_FAILED';

const getArticleAttributesStarted = () => ({
    type: GET_ARTICLE_ATTRIBUTES_STARTED,
});

const getArticleAttributesSuccess = (articleAttributes: ArticleAttribute[]) => ({
    type: GET_ARTICLE_ATTRIBUTES_SUCCESS,
    payload: { articleAttributes },
});

const getArticleAttributesFailed = createFailedAction(GET_ARTICLE_ATTRIBUTES_FAILED);

export const getArticleAttributes =
    (articleGroup1Id: number | null, articleGroup2Id: number | null, articleGroup3Id: number | null): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(getArticleAttributesStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            if (articleGroup1Id !== undefined && articleGroup2Id !== undefined && articleGroup3Id !== undefined) {
                const params = new URLSearchParams();

                if (articleGroup1Id !== null) params.append('articleGroup1Id', articleGroup1Id.toString());
                if (articleGroup2Id !== null) params.append('articleGroup2Id', articleGroup2Id.toString());
                if (articleGroup3Id !== null) params.append('articleGroup3Id', articleGroup3Id.toString());

                const url = '/articleAttributes?' + decodeURI(params.toString());

                const response = await authFetch(fb, url, 'GET', undefined, apiUrl);
                const data: ArticleAttribute[] = await response.json();

                dispatch(getArticleAttributesSuccess(data));
            }
        } catch (error) {
            dispatch(getArticleAttributesFailed(error));
        }
    };

export const CREATE_ARTICLE_ATTRIBUTE_STARTED = 'CREATE_ARTICLE_ATTRIBUTE_STARTED';
export const CREATE_ARTICLE_ATTRIBUTE_SUCCESS = 'CREATE_ARTICLE_ATTRIBUTE_SUCCESS';

const createArticleAttributeSuccess = (articleAttribute: ArticleAttribute) => ({
    type: CREATE_ARTICLE_ATTRIBUTE_SUCCESS,
    payload: { articleAttribute },
});

export const createArticleAttribute =
    (articleAttribute: ArticleAttribute, callbackFn: VoidFunction): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch({ type: CREATE_ARTICLE_ATTRIBUTE_STARTED });
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const response = await authFetch(
                fb,
                '/articleAttributes',
                'POST',
                {
                    name: articleAttribute.name,
                    articleGroup1Id: articleAttribute.articleGroup1Id,
                    articleGroup2Id: articleAttribute.articleGroup2Id,
                    articleGroup3Id: articleAttribute.articleGroup3Id,
                },
                apiUrl
            );
            const data: ArticleAttribute = await response.json();

            dispatch(createArticleAttributeSuccess(data));
            callbackFn();
            dispatch(getArticleAttributes(null, null, null));
        } catch (error) {
            dispatch(createFailedAction('CREATE_ARTICLE_ATTRIBUTE_FAILED')(error));
        }
    };

export const DELETE_ARTICLE_ATTRIBUTE_STARTED = 'DELETE_ARTICLE_ATTRIBUTE_STARTED';
export const DELETE_ARTICLE_ATTRIBUTE_SUCCESS = 'DELETE_ARTICLE_ATTRIBUTE_SUCCESS';

export const deleteArticleAttribute =
    (articleAttributeId: number, callbackFn: VoidFunction): AppThunk =>
    async (dispatch, getState, fb) => {
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        dispatch({ type: DELETE_ARTICLE_ATTRIBUTE_STARTED });
        try {
            await authFetch(
                fb,
                `/articleAttributes/?articleAttributeId=${articleAttributeId}`,
                'DELETE',
                undefined,
                apiUrl
            );

            dispatch({ type: DELETE_ARTICLE_ATTRIBUTE_SUCCESS });
            callbackFn();
            dispatch(getArticleAttributes(null, null, null));
        } catch (error) {
            dispatch(createFailedAction(DELETE_ARTICLE_ATTRIBUTE_STARTED)(error));
        }
    };

export const SAVE_ARTICLE_ATTRIBUTE_VALUE_STARTED = 'SAVE_ARTICLE_ATTRIBUTE_VALUE_STARTED';
export const SAVE_ARTICLE_ATTRIBUTE_VALUE_SUCCESS = 'SAVE_ARTICLE_ATTRIBUTE_VALUE_SUCCESS';

export const saveArticleAttributeValues =
    (
        attributeId: ArticleAttribute['articleAttributeId'],
        articleAttributeValues: ArticleAttributeValueWithStatus[],
        callbackFn: VoidFunction
    ): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch({ type: SAVE_ARTICLE_ATTRIBUTE_VALUE_STARTED });
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        const url = `/articleAttributes/${attributeId}/attributeValues`;
        try {
            const newValues = articleAttributeValues.filter(value => value.status === 'new');
            const editedValues = articleAttributeValues.filter(value => value.status === 'edited');
            const deletedValues = articleAttributeValues.filter(value => value.status === 'deleted');

            const editedValuesPromises = editedValues.map(value =>
                authFetch(
                    fb,
                    url + `?attributeValueId=${value.articleAttributeValueId}&attributeValueName=${value.name}`,
                    'PUT',
                    undefined,
                    apiUrl
                )
            );
            const deletedValuesPromises = deletedValues.map(value =>
                authFetch(
                    fb,
                    url + `?articleAttributeValueId=${value.articleAttributeValueId}`,
                    'DELETE',
                    undefined,
                    apiUrl
                )
            );

            const promises = [...editedValuesPromises, ...deletedValuesPromises];

            if (newValues.length > 0) {
                await authFetch(
                    fb,
                    url,
                    'POST',
                    newValues.map(v => v.name),
                    apiUrl
                );
            }

            await Promise.all(promises);

            callbackFn();

            dispatch({ type: SAVE_ARTICLE_ATTRIBUTE_VALUE_SUCCESS });
            dispatch(getArticleAttributes(null, null, null));
        } catch (error) {
            dispatch(createFailedAction('SAVE_ARTICLE_ATTRIBUTE_VALUE_FAILED')(error));
        }
    };

// Get product attributes
export const GET_PRODUCT_ATTRIBUTES_STARTED = 'GET_PRODUCT_ATTRIBUTES_STARTED';
export const GET_PRODUCT_ATTRIBUTES_SUCCESS = 'GET_PRODUCT_ATTRIBUTES_SUCCESS';
export const GET_PRODUCT_ATTRIBUTES_FAILED = 'GET_PRODUCT_ATTRIBUTES_FAILED';

const getProductAttributesStarted = () => ({
    type: GET_PRODUCT_ATTRIBUTES_STARTED,
});

const getProductAttributesSuccess = (productAttributes: ProductAttribute[]) => ({
    type: GET_PRODUCT_ATTRIBUTES_SUCCESS,
    payload: { productAttributes },
});

const getProductAttributesFailed = createFailedAction(GET_PRODUCT_ATTRIBUTES_FAILED);

export const getProductAttributes =
    (articleId: number): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(getProductAttributesStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const response = await authFetch(fb, `/productAttributes?articleId=${articleId}`, 'GET', undefined, apiUrl);
            const data: ProductAttribute[] = await response.json();

            dispatch(getProductAttributesSuccess(data));
        } catch (error) {
            dispatch(getProductAttributesFailed(error));
        }
    };

export const SAVE_PRODUCT_ATTRIBUTE_VALUES_STARTED = 'SAVE_PRODUCT_ATTRIBUTE_VALUES_STARTED';
export const SAVE_PRODUCT_ATTRIBUTE_VALUES_SUCCESS = 'SAVE_PRODUCT_ATTRIBUTE_VALUES_SUCCESS';
export const SAVE_PRODUCT_ATTRIBUTE_VALUES_FAILED = 'SAVE_PRODUCT_ATTRIBUTE_VALUES_FAILED';

const saveProductAttributeValuesStarted = () => ({
    type: SAVE_PRODUCT_ATTRIBUTE_VALUES_STARTED,
});

const saveProductAttributeValuesSuccess = () => ({
    type: SAVE_PRODUCT_ATTRIBUTE_VALUES_SUCCESS,
});

const saveProductAttributeValuesFailed = createFailedAction(SAVE_PRODUCT_ATTRIBUTE_VALUES_FAILED);

export const saveProductAttributeValues =
    (articleId: number, productAttributeValues: SelectAttribute[]): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(saveProductAttributeValuesStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const editedValues = productAttributeValues.filter(value => value.status === 'edited');
            const deletedValues = productAttributeValues.filter(value => value.attributeValueId === 'noValue');

            const editedValuesPromises = editedValues.map(value => {
                if (value.attributeValueId === 'noValue') {
                    return authFetch(
                        fb,
                        `/productAttributes?articleId=${articleId}&attributeId=${value.attributeId}`,
                        'DELETE',
                        undefined,
                        apiUrl
                    );
                } else
                    return authFetch(
                        fb,
                        `/productAttributes?articleId=${articleId}&attributeId=${value.attributeId}&newAttributeValueId=${value.attributeValueId}`,
                        'PUT',
                        undefined,
                        apiUrl
                    );
            });

            await Promise.all([...editedValuesPromises]);

            dispatch(saveProductAttributeValuesSuccess());
            dispatch(getProductAttributes(articleId));
        } catch (error) {
            dispatch(saveProductAttributeValuesFailed(error));
        }
    };
