import { useState, ReactNode } from 'react';
import HelpButton from '../../Buttons/HelpButton/HelpButton';
import Modal from '../Modal/Modal';

type Props = {
    children: ReactNode;
};

const HelpModal = ({ children }: Props) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <HelpButton handleClick={() => setOpen(prev => !prev)} helpText='Klikk for en forklaring.' />
            <Modal openModal={open} toggleModal={() => setOpen(prev => !prev)} maxWidth='md'>
                {children}
            </Modal>
        </>
    );
};

export default HelpModal;
