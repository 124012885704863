import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    select: {
        borderRadius: 30,
        marginRight: 1,
        marginLeft: 2,
        borderColor: 'transparent',
        transition: `opacity ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,

        '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
        },
        '& .MuiSelect-iconOutlined': {
            right: 12,
        },
        '&:hover': {
            opacity: 0.6,
        },
    },
    notRounded: {
        borderRadius: 6,
        '&:focus': {
            borderRadius: '6px !important',
        },
    },
    outlined: {
        paddingLeft: 18,
        paddingRight: '36px !important',
        borderColor: 'transparent',

        '&:focus': {
            borderRadius: 30,
        },
    },
    dense: {
        paddingTop: 10,
        paddingBottom: 9,
    },
    normal: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    light: {
        backgroundColor: '#F2F2F2',
    },
    dark: {
        backgroundColor: '#2B2B2B',
        color: 'white',
        '& .MuiSelect-icon': {
            color: '#999',
        },
    },
}));
