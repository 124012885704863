import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Theme,
    useMediaQuery,
    Button,
    FormControlLabel,
    Switch,
    Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Article, ArticleVariant } from '../../../../models/Article';
import { getArticleColorOptions, getArticleSizeOptions } from '../../../../utils/helpers';
import SubtleSpinner from '../../../Indicators/SubtleSpinner/SubtleSpinner';

import Axis from '../../../Layouts/Axis/Axis';
import ArticleStockTable from '../../../Tables/ArticleStockTable/ArticleStockTable';
import MobileArticleStockTable from '../../../Tables/MobileArticleStockTable/MobileArticleStockTable';
import useStyles from './ArticleInvenvory.styles';

export type Props = {
    article: Article;
    handleFetchLocations: (sizeColorId: number | null) => void;
    handleRemoveFromProductLocation: (
        locationId: number,
        placementId: number | null,
        sizeColorId: number | null
    ) => void;
};

const ArticleInventory = ({ article, handleFetchLocations, handleRemoveFromProductLocation }: Props) => {
    const { classes } = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [color, setColor] = useState<string | number>('allColors');
    const [size, setSize] = useState<string | number>('allSizes');
    const [showOnlyInStock, setShowOnlyInStock] = useState<boolean>(false);
    const [articleVariants, setArticleVariants] = useState<ArticleVariant[] | undefined>([]);

    const colorOptions = getArticleColorOptions(article);
    const sizeOptions = getArticleSizeOptions(article);

    useEffect(() => {
        const filteredVariants = article.variants
            ?.filter(v => {
                if (size !== 'allSizes') return v.size?.sizeId === size;
                return true;
            })
            .filter(v => {
                if (color !== 'allColors') return v.color?.colorId === color;
                return true;
            })
            .filter(v => {
                if (showOnlyInStock) return v.stock.available > 0;
                return true;
            });

        setArticleVariants(filteredVariants);
    }, [article.variants, color, size, showOnlyInStock]);

    const handleResetFilter = () => {
        setSize('allSizes');
        setColor('allColors');
        setShowOnlyInStock(false);
    };

    const isPristineFilter = color !== 'allColors' || size !== 'allSizes';

    if (article && articleVariants) {
        return (
            <Axis direction='column' gap={3}>
                {article.hasVariants && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Axis isResponsive gap={3}>
                            <FormControl className={classes.dropdown}>
                                <InputLabel id='color'>Farge</InputLabel>
                                <Select
                                    labelId='color'
                                    value={color}
                                    label='Farge'
                                    onChange={event => setColor(event.target.value)}
                                    data-cy='color-dropdown'
                                >
                                    <MenuItem value='allColors'>Alle farger</MenuItem>;
                                    {colorOptions.map(color => {
                                        return (
                                            <MenuItem key={color.colorId} value={color.colorId}>
                                                {color.colorName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.dropdown}>
                                <InputLabel id='size'>Størrelse</InputLabel>
                                <Select
                                    labelId='size'
                                    value={size}
                                    label='Størrelse'
                                    onChange={event => setSize(event.target.value)}
                                    data-cy='size-dropdown'
                                >
                                    <MenuItem value='allSizes'>Alle størrelser</MenuItem>;
                                    {sizeOptions.map(size => {
                                        return (
                                            <MenuItem key={size.sizeId} value={size.sizeId}>
                                                {size.sizeName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {isPristineFilter && (
                                <div>
                                    <Button onClick={handleResetFilter} sx={{ height: '100%' }}>
                                        Tilbakestill filter
                                    </Button>
                                </div>
                            )}
                        </Axis>
                        <FormControl sx={{ alignSelf: 'center', mt: 3 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showOnlyInStock}
                                        onChange={() => setShowOnlyInStock(!showOnlyInStock)}
                                    />
                                }
                                label='Vis kun disponible artikler'
                            />
                        </FormControl>
                    </Box>
                )}
                {isMobile ? (
                    <MobileArticleStockTable
                        articleName={article.articleName}
                        articleVariants={articleVariants}
                        hasVariants={article.hasVariants}
                        handleFetchLocations={handleFetchLocations}
                        handleRemoveFromProductLocation={handleRemoveFromProductLocation}
                    />
                ) : (
                    <ArticleStockTable
                        articleName={article.articleName}
                        articleVariants={articleVariants}
                        hasVariants={article.hasVariants}
                        handleFetchLocations={handleFetchLocations}
                        handleRemoveFromProductLocation={handleRemoveFromProductLocation}
                    />
                )}
            </Axis>
        );
    } else {
        return <SubtleSpinner />;
    }
};

export default ArticleInventory;
