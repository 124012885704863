import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteConversation } from '../../../../state/ducks/conversations/DeleteConversations';
import { useActiveConversation, useSelector } from '../../../../state/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import ConversationHeader from '../../../../components/Extras/ConversationHeader/ConversationHeader';
import { updateCustomerInfo } from '../../../../state/ducks/conversations/UpdateCustomerInfo';

const ConversationHeaderContainer = () => {
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const activeConversation = useActiveConversation('activeConversationHeader', customerId);
    const isUpdatingCustomer = useSelector(state => state.conversations.isUpdatingCustomer);
    const [isExpanded, setIsExpanded] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const navigate = useNavigate();

    const handleSetExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const handleToggleModal = () => {
        setOpenModal(!openModal);
    };

    const handleDeleteConversation = () => {
        if (activeConversation) dispatch(deleteConversation(activeConversation?.customer.customerNumber));
        setOpenModal(false);
        setIsExpanded(false);
        navigate('/meldinger', { replace: true });
    };

    const handleUpdateCustomer = () => {
        if (activeConversation) {
            dispatch(updateCustomerInfo(activeConversation.customer.customerNumber));
        }
    };

    return (
        <>
            {activeConversation && (
                <ConversationHeader
                    customerNumber={activeConversation.customer.customerNumber}
                    phoneNumbers={activeConversation?.customer?.getPhoneNumbers()}
                    customerName={activeConversation.customer.customerName}
                    isExpanded={isExpanded}
                    handleSetExpanded={handleSetExpanded}
                    openModal={openModal}
                    toggleModal={handleToggleModal}
                    handleDeleteConversation={handleDeleteConversation}
                    handleUpdateCustomer={handleUpdateCustomer}
                    isUpdatingCustomer={isUpdatingCustomer}
                />
            )}
        </>
    );
};

export default ConversationHeaderContainer;
