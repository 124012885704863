import { authFetch, createFailedAction, getArticleVariantName } from '../../../utils/helpers';
import { AllocatedPlacement, ProductLocation } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';
import { getProductLocations } from './Locations';

export const PLACE_INVENTORY_STARTED = 'PLACE_INVENTORY_STARTED';
export const PLACE_INVENTORY_SUCCESS = 'PLACE_INVENTORY_SUCCESS';
export const PLACE_INVENTORY_FAILED = 'PLACE_INVENTORY_FAILED';

const placeInventoryStarted = () => ({
    type: PLACE_INVENTORY_STARTED,
});

const placeInventorySuccess = (allocatedPlacement: AllocatedPlacement) => ({
    type: PLACE_INVENTORY_SUCCESS,
    payload: { allocatedPlacement },
});

const placeInventoryFailed = createFailedAction(PLACE_INVENTORY_FAILED);

export const SET_ALLOCATION_HISTORY = 'SET_ALLOCATION_HISTORY';

const setAllocationHistory = (allocationHistory: AllocatedPlacement[]) => ({
    type: SET_ALLOCATION_HISTORY,
    payload: { allocationHistory },
});

export const placeInventory =
    (barcode: string, locationId: number | null, placementId: number | null, onSuccess: VoidFunction): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(placeInventoryStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        const previousAllocatedPlacement = getState().inventory.allocatedPlacement;

        try {
            // Fetch data
            let url = '/productLocations?barcode=' + barcode;

            if (locationId) url = url + '&locationId=' + locationId;
            if (placementId) url = url + '&placementId=' + placementId;

            const data = await authFetch(fb, url, 'POST', undefined, apiUrl);

            const { result: placedArticles, error }: { result: ProductLocation[]; error: any } = await data.json();
            if (error !== null) dispatch(placeInventoryFailed(error));
            else {
                const placedArticle = placedArticles[0];
                const articleName = getArticleVariantName(
                    placedArticle.articleName,
                    placedArticle.color,
                    placedArticle.size
                );

                dispatch(
                    placeInventorySuccess({
                        productLocationId: placedArticle.productLocationId!,
                        articleNumber: placedArticle.supplierArticleNumber ?? placedArticle.articleNumber,
                        articleName,
                        locationName: placedArticle.locationName,
                        placementName: placedArticle.placementName,
                    })
                );
                if (locationId && placementId) dispatch(getProductLocations(locationId, 'one-to-one'));
                onSuccess();
                if (previousAllocatedPlacement) {
                    const allocationHistory = [...getState().inventory.allocationHistory];
                    allocationHistory.unshift(previousAllocatedPlacement);
                    dispatch(setAllocationHistory(allocationHistory));
                }
            }
        } catch (error) {
            dispatch(placeInventoryFailed(error));
        }
    };
