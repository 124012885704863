import { Link } from 'react-router-dom';

import MenuNavigationButton from '../../../components/Buttons/MenuNavigationButton/MenuNavigationButton';
import { ThemePaletteMainColors } from '../../../utils/types';

type Props = {
    link: string;
    isActive: boolean;
    text: string;
    onClick?: VoidFunction;
    notifications?: {
        count: number;
        color?: ThemePaletteMainColors;
    }[];
};

const SidebarLinkContainer = ({ link, isActive, text, onClick, notifications }: Props) => {
    return (
        <Link to={link} style={{ textDecoration: 'none' }} onClick={onClick}>
            <MenuNavigationButton linkPath={link} isActive={isActive} notifications={notifications}>
                {text}
            </MenuNavigationButton>
        </Link>
    );
};

export default SidebarLinkContainer;
