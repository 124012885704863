import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../configs/firebaseConfig';
import { createFailedAction, isProdEnvironment } from '../../../utils/helpers';

export const GET_API_URL_STARTED = 'GET_API_URL_STARTED';
export const GET_API_URL_SUCCESS = 'GET_API_URL_SUCCESS';
export const GET_API_URL_FAILED = 'GET_API_URL_FAILED';

const getApiUrlStarted = () => ({
    type: GET_API_URL_STARTED,
});

const getApiUrlSuccess = (apiUrl: string) => ({
    type: GET_API_URL_SUCCESS,
    payload: { apiUrl },
});

const getApiUrlFailed = createFailedAction(GET_API_URL_FAILED);

export const getApiUrl = () => async (dispatch: any) => {
    dispatch(getApiUrlStarted());
    try {
        if (isProdEnvironment()) {
            const bookingInfoRef = doc(firestore, 'public', 'systemInfo');
            const bookingInfoSnap = await getDoc(bookingInfoRef);
            if (!bookingInfoSnap.exists()) throw new Error('Could not fetch API URL document.');
            const apiURL = bookingInfoSnap.data()?.apiURL;
            dispatch(getApiUrlSuccess(apiURL));
        } else {
            dispatch(getApiUrlSuccess('http://localhost:3001/v1'));
        }
    } catch (err) {
        dispatch(getApiUrlFailed(err));
    }
};
