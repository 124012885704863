import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

import HorizontalNavigationButtons from '../../Buttons/HorizontalNavigationButtons/HorizontalNavigationButtons';
import useStyles from './MonthPicker.styles';

export type Props = {
    month: number;
    year: number;
    nextMonth: () => void;
    previousMonth: () => void;
    controlsActive: { left: boolean; right: boolean };
};

const MonthPicker = ({ month, year, nextMonth, previousMonth, controlsActive }: Props) => {
    const { classes } = useStyles();
    const monthName: string = format(new Date(year, month), 'MMMM', { locale: nb });

    return (
        <div className={classes.root}>
            <Typography className={classes.monthName} variant='h3' sx={{ mr: { xs: 4, sm: 2, md: 4 } }}>
                {monthName} {year}
            </Typography>
            <HorizontalNavigationButtons
                navigateRight={nextMonth}
                navigateLeft={previousMonth}
                controlsActive={controlsActive}
                size='small'
            />
        </div>
    );
};

export default MonthPicker;
