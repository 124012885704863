import { Timestamp } from 'firebase/firestore';

import PhoneNumber from '../models/PhoneNumber';
import Conversation from '../models/Conversation';
import Order from '../models/Order';
import { ReactNode } from 'react';

export interface OrderTemplate {
    orderTemplateId: number;
    orderTemplateName: string;
}

export interface OrderLine {
    orderLineId: string;
    description: string; // "Description" in database table
    articleNumber: string;
    price: number; // Price of individual order line
    grossPrice: number; // Price with count and discount calculated
    count: number;
    stock: number | null;
    discount: number;
    info: string; // "Linjeinfo" in PCK
    error: GenericOrderError | null;
}

export type FutureOrders = Order[][];

export interface ServiceBooking {
    id: number;
    orderDate: string; // Date the booking is registerered
    deliveryDate: string; // Date the customer drops of the bike
    type: 'bike' | 'wheel';
    brand: {
        id: string;
        name: string;
    };
    description: string;
    customerName: string;
    customerEmail: string;
    customerPhone: string;
    hashedId: string;
    promoCodeId: string | undefined;
    soldHere: boolean | undefined;
}

export interface GenericOrderError {
    name: string;
    id: string;
    description: string;
}

export interface ConversationSearchResults {
    exhaustiveNbHits: boolean;
    hits: Conversation[];
    hitsPerPage: number;
    nbHits: number;
    nbPages: number;
    page: number;
    query: string;
}

/**
 * IMPORTANT: This type exists in the Firebase code (types) and here.
 * This is used for SMS-messages, and both types must have the same shape (but not neccesarily same types)!
 */
export interface Message {
    associatedWith: string;
    isIncoming: boolean;
    messageId: string;
    text: string;
    date: Timestamp;
    sent?: boolean; // Optional because old messages do not have the prop
}

export interface MessageTemplate {
    id: string;
    templateName: string;
    text: string;
    favorite: boolean;
}

export interface Employee {
    id: number;
    name: string;
}

export interface FilterOption {
    id: number | string;
    label: string;
}

export interface FilterOptions {
    [key: string]: FilterOption[];
}

export type ServiceCapacity = {
    date: string;
    past: boolean;
    capacity: null | number;
};

/**
 * The capacity of each weekday, where the first element is sunday
 */
export type WeekdayCapacity = number[];

export type SpecialCapacity = {
    id: string;
    date: string;
    count: number;
};

export interface Holidays {
    [date: string]: string; // Description of the day
}

export interface BookingDaysInfo {
    [date: string]: number;
}

export interface Notification {
    phoneNumber: PhoneNumber;
    messages: string[];
}

export type ThemePaletteMainColors = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export type TimePeriod = 'thisYear' | 'thisQuarter' | 'thisMonth' | 'thisWeek';

export type TimePeriodScope = 'soFar' | 'entire';

export type AggregateDataOptions = 'day' | 'month' | 'year';

export enum AggregateTimePeriods {
    thisYear = 'month',
    thisQuarter = 'month',
    thisMonth = 'day',
    thisWeek = 'day',
}

export type DataPoint = {
    count: number;
    netAmount: number;
    contribution: number;
    contributionMargin: number | null;
    aggregateKey: number;
};

export type DataPointSeries = {
    year: number;
    datapoints: DataPoint[];
    aggregateInterval: AggregateDataOptions;
};

export type DataComparisonValues = {
    timePeriod: string;
    data: number | null;
};

export type DataComparison = {
    data: DataComparisonValues[];
    difference: number[];
};

export type GraphLineData = {
    id: string;
    data: {
        x: string | number;
        y: number;
    }[];
};

export type ArticleGroup = {
    articleGroupId: number;
    articleGroupName: string;
};

export type ArticleGroupName = 'primaryArticleGroups' | 'secondaryArticleGroups' | 'tertiaryArticleGroups';

export type AllocatedPlacement = {
    productLocationId: number;
    articleNumber: string;
    articleName: string;
    locationName: string;
    placementName: string | null;
};

export type MobileTableRowData = {
    key: string;
    value: string | number;
    displayFormat: 'text' | 'percentage' | 'currency';
    rowValueIcon?: ReactNode;
};

export type InventoryProductType = {
    productTypeName: string;
    productTypeId: number;
};

export type LocationType = 'one-to-one' | 'one-to-many';

export type NewLocation = {
    name: string;
    locationType: LocationType;
    maxSize: number | undefined;
    articleGroup1Id: number;
    articleGroup2Id: number | null;
    articleGroup3Id: number | null;
};

export type InventoryLocation = {
    inventoryLocationId: number;
    name: string;
    articleGroup1Id: number;
    articleGroup2Id: number | null;
    articleGroup3Id: number | null;
    locationType: 'one-to-one' | 'one-to-many';
    maxSize: number | null;
    productLocations?: ArticlePlacement[];
    articleGroup1Name: string | null;
    articleGroup2Name: string | null;
    articleGroup3Name: string | null;
};

export enum LocationTypes {
    'one-to-one' = '1 til 1',
    'one-to-many' = '1 til mange',
}

export type ProductLocation = {
    productLocationId?: number;
    articleId: number;
    sizeColorId: number | null;
    locationId: number;
    locationName: string;
    placementId: number | null;
    placementName: string | null;
    count: number;
    articleName: string;
    articleNumber: string;
    color: string | null;
    size: string | null;
    supplierArticleNumber: string | null;
};

export interface ArticlePlacement {
    articleId: number | null;
    placementId: number | null; // Row id non-variants
    placementName: string | null;
    articleNumber: string | null;
    articleName: string | null;
    sizeColorId: number | null; // Row id variants
    count: number;
    supplierArticleNumber: string | null;
    colorName: string | null;
    sizeName: string | null;
}

export type SortOrder = 'asc' | 'desc';

export interface TableHeadCell<RowObjectType> {
    disablePadding: boolean;
    id: keyof RowObjectType;
    label: string;
    numeric: boolean;
}

export type GeneratePlacementFormData = {
    letterPrefix: string;
    startValue: number;
    endValue: number;
};

export type InventorySearchObject = {
    searchQuery: string | null;
    primaryArticleGroups: number[];
    secondaryArticleGroups: number[];
    tertiaryArticleGroups: number[];
    articleAttributes: {
        articleAttributeId: ArticleAttribute['articleAttributeId'];
        articleAttributeValues: ArticleAttributeValue['articleAttributeValueId'][];
    }[];
    manufacturers: number[];
};

export type ArticleAttribute = {
    articleAttributeId: number;
    articleAttributeValues: ArticleAttributeValue[] | null;
    name: string;
    articleGroup1Id: number | null;
    articleGroup2Id: number | null;
    articleGroup3Id: number | null;
};

export type ArticleAttributeValue = {
    articleAttributeValueId: number;
    name: string;
};

export type FeatureFlags = {
    [id: string]: {
        isActive: boolean;
        name: string;
        onlyForPCK: boolean;
    };
};

export type FeatureFlag = 'orderSystem' | 'inventorySystem' | 'bookingSystem' | 'messageSystem' | 'bikeBoxSystem';

export type ProductAttribute = {
    articleId: number;
    articleAttributeId: number;
    articleAttributeValueId: number;
    attributeName: string;
    attributeValueName: string;
};

export type ConfigValues = {
    creditOrders: {
        bookingOrderTemplateId: number;
        orderTemplateColors: {
            [id: string]: string;
        };
    };
    criticalOrders: {
        includeWeborders: boolean;
        templateIds: Array<number>;
    };
    system: {
        storeOptions: {
            id: string;
            name: string;
        }[];
    };
};

export type ArticleManufacturer = {
    manufacturerId: number;
    manufacturerName: string;
};

export type StatisticsFilter = {
    fromDate: Date;
    toDate: Date;
    years: number[];
    timePeriod: TimePeriod;
    timePeriodScope: TimePeriodScope;
    activeArticleGroupIds: {
        primaryArticleGroups: number[] | null;
        secondaryArticleGroups: number[] | null;
        tertiaryArticleGroups: number[] | null;
    };
    orderTemplateIds: number[] | null;
};

export type BikeBox = {
    id: number; // Lock ID
    name: string; // Lock name
    reservations: BikeBoxReservation[] | null;
    activeReservation: BikeBoxReservation | null;
};

export type BikeBoxReservation = {
    authorizationId: number;
    fromDate: string;
    toDate: string;
    personId: number;
    customerName: string;
    phoneNumber: string;
    mediumId: number;
};

export type BikeBoxLockEvent = {
    lockId: number;
    lockName: string;
    lockEventMessage: string;
    timestamp: string;
    personName: string;
};

export type BikeBoxAvailability = {
    date: string;
    available: boolean;
    selected: boolean;
    rangeSelected: boolean;
}[][];

export type NewBikeBoxReservationFormData = {
    firstName: string;
    lastName: string;
    countryCode: string;
    phoneNumber: string;
    fromDate: string;
    toDate: string;
};

export type NewBikeBoxReservation = {
    bikeBoxId: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    requestedDate: string;
    reservationDuration: number;
};

export type FilterState = {
    employees: number;
    orderTemplates: number;
    errorMessages: number;
    sorting: 'price-asc' | 'price-desc' | 'date-asc' | 'date-desc';
};
