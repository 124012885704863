import { IconButton, Tooltip } from '@mui/material';

import { HelpCircle } from 'react-feather';
import { makeStyles } from '../../../state/hooks';

export type Props = {
    handleClick?: () => void;
    helpText?: string;
};

/**
 * Component representing a helper text that the user can hover on to get more information.
 */
const HelpButton = ({ handleClick, helpText = '' }: Props) => {
    const { classes } = useStyles();

    return (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={helpText} placement='top' leaveDelay={500} arrow>
            <IconButton onClick={handleClick ? handleClick : undefined} size='large'>
                <HelpCircle color='#0B73DD' size={22} />
            </IconButton>
        </Tooltip>
    );
};

const useStyles = makeStyles()({
    tooltip: {
        fontSize: '0.9rem',
        fontWeight: 400,
    },
});

export default HelpButton;
