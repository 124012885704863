import { authFetch, createFailedAction } from '../../../utils/helpers';
import { ServiceCapacity } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const GET_SERVICE_CAPACITY_STARTED = 'GET_SERVICE_CAPACITY_STARTED';
export const GET_SERVICE_CAPACITY_SUCCESS = 'GET_SERVICE_CAPACITY_SUCCESS';
export const GET_SERVICE_CAPACITY_FAILED = 'GET_SERVICE_CAPACITY_FAILED';

const getServiceCapacityStarted = () => ({
    type: GET_SERVICE_CAPACITY_STARTED,
});

const getServiceCapacitySuccess = (serviceCapacity: ServiceCapacity[][][]) => ({
    type: GET_SERVICE_CAPACITY_SUCCESS,
    payload: {
        serviceCapacity,
        loadingBookings: false,
    },
});

const getServiceCapacityFailed = createFailedAction(GET_SERVICE_CAPACITY_FAILED);

export const getServiceCapacity = (): AppThunk => async (dispatch, getState, fb) => {
    dispatch(getServiceCapacityStarted());
    try {
        const offset = new Date().getTimezoneOffset();
        const activeStore = getState().system.activeStoreLocation;
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        const responses = await Promise.all([
            authFetch(
                fb,
                `/serviceCapacity?storeName=${activeStore}&numberOfMonths=12&brand[]=trek&timezoneOffset=${offset}`,
                'GET',
                undefined,
                apiUrl
            ),
            authFetch(
                fb,
                `/serviceCapacity?storeName=${activeStore}&numberOfMonths=12&brand[]=other&timezoneOffset=${offset}`,
                'GET',
                undefined,
                apiUrl
            ),
        ]);

        if (responses[0].ok && responses[1].ok) {
            const prioritized: ServiceCapacity[][] = await responses[0].json();
            const nonPrioritized: ServiceCapacity[][] = await responses[1].json();

            dispatch(getServiceCapacitySuccess([nonPrioritized, prioritized]));
        } else {
            throw Error('GET_SERVICE_CAPACITY_FAILED');
        }
    } catch (error) {
        console.log(error);
        dispatch(getServiceCapacityFailed(error));
    }
};
