import { BikeBoxReservation, NewBikeBoxReservation } from '../utils/types';
import { api } from './api';

export const bikeBoxReservationApi = api.injectEndpoints({
    endpoints: builder => ({
        createBikeBoxReservation: builder.mutation<
            BikeBoxReservation,
            { bikeBoxReservation: NewBikeBoxReservation; storeName: string }
        >({
            query: ({ bikeBoxReservation, storeName }) => ({
                url: `/bikeBoxReservations/${bikeBoxReservation.bikeBoxId}`,
                method: 'POST',
                body: {
                    ...bikeBoxReservation,
                },
                params: {
                    storeName,
                    timezoneOffset: new Date().getTimezoneOffset(),
                },
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
            invalidatesTags: ['BikeBoxAvailability', 'BikeBoxes'],
        }),
        deleteBikeBoxReservation: builder.mutation<
            BikeBoxReservation,
            { bikeBoxId: number; bikeBoxReservationId: number; storeName: string }
        >({
            query: ({ bikeBoxId, bikeBoxReservationId, storeName }) => ({
                url: `/bikeBoxReservations/${bikeBoxId}`,
                method: 'DELETE',
                params: {
                    storeName,
                    authorizationId: bikeBoxReservationId,
                },
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
            invalidatesTags: ['BikeBoxAvailability', 'BikeBoxes'],
        }),
    }),
});

export const { useCreateBikeBoxReservationMutation, useDeleteBikeBoxReservationMutation } = bikeBoxReservationApi;
