import { Typography, Box, Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { SubmitHandler, useForm } from 'react-hook-form';

import GeneratePlacementForm from '../../Form/GeneratePlacementForm/GeneratePlacementForm';
import GeneratePlacementsTable from '../../Tables/GeneratePlacementsTable/GeneratePlacementsTable';
import Modal from '../Modal/Modal';
import { generateStringList } from '../../../utils/helpers';
import { GeneratePlacementFormData } from '../../../utils/types';

export type Props = {
    openModal: boolean;
    isLoading: boolean;
    handleSubmitPlacements: (placements: string[], callbackFn: VoidFunction) => void;
    toggleModal: () => void;
};

const GeneratePlacementsModal = ({ openModal, isLoading, handleSubmitPlacements, toggleModal }: Props) => {
    const { handleSubmit, control, reset } = useForm<GeneratePlacementFormData>();
    const [generatedPlacements, setGeneratedPlacements] = useState<string[]>([]);

    const onValidSubmit: SubmitHandler<GeneratePlacementFormData> = (data: GeneratePlacementFormData) => {
        setGeneratedPlacements(generateStringList(data.startValue, data.endValue, data.letterPrefix));
    };

    const handleAddPlacements = () => {
        handleSubmitPlacements(generatedPlacements, toggleModal);
    };

    const handleDeleteGeneratedPlacement = (placement: string) => {
        const index = generatedPlacements.findIndex(p => p === placement);
        const newArray = generatedPlacements.slice();
        if (index > -1) newArray.splice(index, 1);
        setGeneratedPlacements(newArray);
    };

    useEffect(() => {
        reset(); // Resets form values
        setGeneratedPlacements([]);
    }, [openModal, reset]);

    const hasGeneratedPlacements = generatedPlacements.length > 0;

    return (
        <Modal toggleModal={toggleModal} openModal={openModal} maxWidth='sm'>
            <Box sx={{ p: 5 }}>
                <Typography sx={{ mb: 4 }} variant='h3'>
                    Legg til plasseringer
                </Typography>
                <GeneratePlacementForm control={control} isLoading={isLoading} />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant={hasGeneratedPlacements ? 'text' : 'contained'}
                        onClick={handleSubmit(onValidSubmit)}
                        disabled={isLoading}
                    >
                        {hasGeneratedPlacements ? 'Generer på nytt' : 'Generer plasseringer'}
                    </Button>
                </Box>
                {generatedPlacements.length > 0 && (
                    <>
                        <Box sx={{ mt: 3 }}>
                            <Typography variant='h4' sx={{ mb: 0 }}>
                                Genererte plasseringer:
                            </Typography>
                            <GeneratePlacementsTable
                                placements={generatedPlacements}
                                disableRowActions={isLoading}
                                handleDeletePlacement={handleDeleteGeneratedPlacement}
                            />
                        </Box>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 2,
                                flexDirection: {
                                    sm: 'row',
                                    xs: 'column-reverse',
                                },
                            }}
                        >
                            <Button onClick={toggleModal}>Avbryt</Button>
                            <Button
                                variant='contained'
                                startIcon={
                                    isLoading ? <CircularProgress size={20} sx={{ color: 'neutrals.400' }} /> : <Plus />
                                }
                                onClick={handleAddPlacements}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Lager nye plasseringer...' : 'Legg til plasseringer'}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default GeneratePlacementsModal;
