import { useDispatch } from 'react-redux';

import ErrorSnackBar from '../../../components/Error/ErrorSnackBar/ErrorSnackBar';
import { clearErrorByCode } from '../../../state/ducks/error/handleErrors';
import { useSelector } from '../../../state/hooks';
import AppError from '../../../models/AppError';
import OverlayStack from '../../../components/Layouts/OverlayStack/OverlayStack';

const ErrorMessageContainer = () => {
    const errors: AppError[] = useSelector(state => state.error.errors);
    const dispatch = useDispatch();

    const handleClose = (errorCode: string) => {
        dispatch(clearErrorByCode(errorCode));
    };

    return (
        <OverlayStack>
            {errors.map((error, index) => (
                <ErrorSnackBar
                    key={'error' + index}
                    identifier={error.getIdentifier()}
                    message={error.getPublicMessage()}
                    handleClose={handleClose}
                />
            ))}
        </OverlayStack>
    );
};

export default ErrorMessageContainer;
