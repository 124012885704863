import { Timestamp } from 'firebase/firestore';

import { Message } from '../utils/types';
import Customer from './Customer';

/**
 * Conversation-class represents a conversation between the system and a single customer
 */
export default class Conversation {
    customer: Customer;
    flagged: boolean;
    latestMessage: Message | null;
    latestMessageDate: Date | null;

    constructor(raw: any) {
        this.checkInvariant(raw);

        this.customer = new Customer(raw);
        this.flagged = raw?.flagged ?? false;
        this.latestMessage = raw?.latestMessage ?? null;
        this.latestMessageDate = raw?.latestMessageDate ? this.parseDate(raw.latestMessageDate) : null;
    }

    /**
     * Invariant check for class. Checks if the raw input-object contains a customerNumber, and either phoneNumber1 or phoneNumber2.
     * @param raw The object to create the Conversation from.
     */
    private checkInvariant(raw: any) {
        if (!raw.customerNumber)
            throw new Error('Could not find customerNumber on raw object when instanciating Customer-object.');
        if (!raw.phoneNumber1 && !raw.phoneNumber2)
            throw new Error('Could not find phonenumbers on raw object when instanciating Customer-object.');
    }

    /**
     * Parses the raw date object given and outputs a js Date.
     * Converts both firestore Timestamps and converted algolia Timestamps
     *
     * @param raw date object
     * @returns the parsed date if possible or null if not
     */
    private parseDate(raw: any): Date | null {
        // Firestore timestamp object shape
        if (raw?.seconds !== undefined && raw?.nanoseconds !== undefined)
            return new Timestamp(raw.seconds, raw.nanoseconds).toDate();

        // Algolia timestamp object shape
        if (raw?._seconds !== undefined && raw?._nanoseconds !== undefined)
            return new Timestamp(raw._seconds, raw._nanoseconds).toDate();

        return null;
    }

    /**
     * Compare two conversations by their customer number
     * @param obj {any} object this instance is to be compared to
     * @returns {boolean} true if equal, false if not
     */
    equals(obj: any): boolean {
        return obj instanceof Conversation && this.customer.customerNumber === obj?.customer.customerNumber;
    }
}
