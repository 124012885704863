import * as ModalControlTypes from './ModalControl';
import * as ActiveStoreLocationTypes from './ActiveStoreLocation';
import * as GetAPIUrlTypes from './GetAPIUrl';

interface systemState {
    openModal: boolean;
    openBookingModal: boolean;
    openNewConversationModal: boolean;
    openArticleModal: boolean;
    resetCalendarPosition: boolean;
    activeStoreLocation: string | null;
    apiURL: string | null;
}

const initialState: systemState = {
    openModal: false,
    openBookingModal: false,
    openNewConversationModal: false,
    openArticleModal: false,
    resetCalendarPosition: false,
    activeStoreLocation: null,
    apiURL: null,
};

export default function systemReducer(state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        // Modals
        case ModalControlTypes.OPEN_MODAL:
            return {
                ...state,
                openModal: true,
            };
        case ModalControlTypes.CLOSE_MODAL:
            return {
                ...state,
                openModal: false,
            };

        case ModalControlTypes.OPEN_BOOKING_MODAL:
            return {
                ...state,
                openBookingModal: true,
            };
        case ModalControlTypes.CLOSE_BOOKING_MODAL:
            return {
                ...state,
                openBookingModal: false,
            };

        case ModalControlTypes.OPEN_NEW_CONVERSATION_MODAL:
            return {
                ...state,
                openNewConversationModal: true,
            };
        case ModalControlTypes.CLOSE_NEW_CONVERSATION_MODAL:
            return {
                ...state,
                openNewConversationModal: false,
            };
        case ModalControlTypes.OPEN_ARTICLE_MODAL: {
            return {
                ...state,
                openArticleModal: true,
            };
        }
        case ModalControlTypes.CLOSE_ARTICLE_MODAL: {
            return {
                ...state,
                openArticleModal: false,
            };
        }
        case ActiveStoreLocationTypes.SET_ACTIVE_STORE_LOCATION: {
            return {
                ...state,
                activeStoreLocation: payload.location as string,
            };
        }
        case GetAPIUrlTypes.GET_API_URL_SUCCESS: {
            return {
                ...state,
                apiURL: payload.apiUrl as string,
            };
        }

        case '@@reactReduxFirebase/LOGOUT':
            return {
                ...state,
            };

        case '@@reactReduxFirebase/LOGIN':
            return {
                ...state,
            };

        default:
            return state;
    }
}
