import { GenericOrderError } from './types';

export const ITEM_NOT_ORDERED = 'item-not-ordered';
export const INSUFFICIENT_ITEMS_ORDERED = 'insufficient-items-ordered';
export const DELIVERY_DATE_PASSED = 'delivery-date-passed';
export const NO_DELIVERY_DATE = 'no-delivery-date';

/**
 * Gets a GernericOrderError object based on the error code.
 * @param id The id of the error code
 * @returns A GenericOrderError object.
 */
export function getGenericOrderError(id: string): GenericOrderError {
    switch (id) {
        case ITEM_NOT_ORDERED: {
            return {
                id: ITEM_NOT_ORDERED,
                name: 'Ikke bestilt',
                description: 'Èn eller flere varer er enten ikke disponibel, eller er ikke i en innkjøpsordre.',
            };
        }
        case DELIVERY_DATE_PASSED: {
            return {
                id: DELIVERY_DATE_PASSED,
                name: 'Leveringsdato passert',
                description: 'Leveringsdatoen for ordren har passert, og burde følges opp.',
            };
        }
        case INSUFFICIENT_ITEMS_ORDERED: {
            return {
                id: INSUFFICIENT_ITEMS_ORDERED,
                name: 'Ikke bestilt nok',
                description: 'Det er ikke bestilt nok av èn eller flere varer. Sjekk innkjøpsordre.',
            };
        }
        case NO_DELIVERY_DATE: {
            return {
                id: NO_DELIVERY_DATE,
                name: 'Ingen leveringsdato',
                description: 'Ordren har ingen leveringsdato.',
            };
        }
        default:
            return {
                id: 'default',
                name: 'Feil oppdaget',
                description: 'Vi oppdaget en uspesifisert feil.',
            };
    }
}

/**
 * Returns the error messages corresponding to an error code.
 * @param identifier The error code to match with a error message.
 * @returns An error message, or null if no match is found.
 */
export function getErrorMessageByIdentifier(identifier?: string): string {
    switch (identifier) {
        // Firebase auth errors
        case 'auth/wrong-password':
            return 'Passordet stemmer ikke, eller så har ikke brukeren et passord. (Firebase)'; // The password is invalid or the user does not have a password.
        case 'auth/user-not-found':
            return 'Kunne ikke finne brukeren. (Firebase)'; // There is no user record corresponding to this identifier. The user may have been deleted.
        case 'unavailable':
        case 'auth/internal-error':
        case 'auth/network-request-failed':
            return 'Kunne ikke logge inn, sjekk internetttilkoblingen din. (Firebase)';

        // System
        case 'NOT_LOGGED_IN':
            return 'Du er ikke logget inn i Firebase.';
        case 'NO_EMPLOYEES_FOUND':
            return 'Fant ingen ansatte fra PCK.';
        case 'NO_ORDER_LINES_FOUND':
            return 'Fant ingen ordrelinjer til denne ordren.';
        case 'NO_ORDER_TEMPLATES_FOUND':
            return 'Fant ingen ordremaler fra PCK';
        case 'MARK_AS_READ_FAILED':
            return 'Kunne ikke markere meldinger som lest. (Firebase)';
        case 'GET_HOLIDAYS_FAILED':
            return 'Kunne ikke hente helligdager fra API (webapi.no).';
        case 'RELOAD_FAILED':
            return 'Kunne ikke laste inn siden på nytt, restart programmet og prøv på nytt.';
        case 'CUSTOMER_CONVERSATION_NOT_FOUND':
            return 'Kunne ikke finne kundesamtale. (Firebase)';
        case 'CONVERSATION_MUST_HAVE_PHONE_NUMBER':
            return 'Kunne ikke finne tlf. nummeret til kunden og kunne derfor ikke opprette en samtale';
        case 'UPDATE_CUSTOMER_INFO_FAILED':
            return 'Kunne ikke oppdatere kundeinformasjonen.';
    }

    // Generic fetching error message
    if (identifier?.includes('GET') && identifier?.includes('FAILED')) {
        return 'Kunne ikke hente data fra serveren.';
    }

    return 'Det skjedde en uventet feil.';
}
