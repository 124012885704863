import { useDispatch } from 'react-redux';

import ArticleInventory from '../../../components/Modals/ArticleModal/ArticleInvenvory/ArticleInvenvory';
import { getArticleLocations, removeProductLocation } from '../../../state/ducks/inventory/SetArticleModal';
import { useSelector } from '../../../state/hooks';

const ArticleInventoryContainer = () => {
    const article = useSelector(state => state.inventory.articleModal);
    const dispatch = useDispatch();

    const handleFetchLocations = (sizeColorId: number | null) => {
        dispatch(getArticleLocations(article, sizeColorId));
    };

    const handleRemoveFromProductLocation = (
        locationId: number,
        placementId: number | null,
        sizeColorId: number | null
    ) => {
        dispatch(removeProductLocation(article.articleId, locationId, sizeColorId, placementId));
    };

    return (
        <ArticleInventory
            article={article}
            handleFetchLocations={handleFetchLocations}
            handleRemoveFromProductLocation={handleRemoveFromProductLocation}
        />
    );
};

export default ArticleInventoryContainer;
