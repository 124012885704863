import { Box, Button, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { getArticleVariantName } from '../../../utils/helpers';
import { ArticlePlacement } from '../../../utils/types';

import InputField from '../../Form/InputField/InputField';
import Column from '../../Layouts/Column/Column';
import Modal from '../Modal/Modal';

export type Props = {
    openModal: boolean;
    toggleModal: () => void;
    handleClearPlacement: (placements: { articlePlacement: ArticlePlacement; count: number }[]) => void;
    onClearProductLocation: VoidFunction;
    productLocationsToClear: ArticlePlacement[];
};

const EmptyProductLocationsModal = ({
    openModal,
    toggleModal,
    handleClearPlacement,
    onClearProductLocation,
    productLocationsToClear,
}: Props) => {
    const [articleList, setArticleList] = useState<number[]>([]);

    const onSubmit = () => {
        const placements = productLocationsToClear.map((placement, index) => {
            return {
                articlePlacement: placement,
                count: articleList[index],
            };
        });
        handleClearPlacement(placements);
        onClearProductLocation();
    };

    // TODO better error handling/feedback when count is higher than placements existing.
    const handleChange = (
        event: ChangeEvent<{
            value: unknown;
            id: string;
            type: string;
        }>,
        index: number
    ) => {
        const newNumber = Number(event.target.value);
        const arr = [...articleList];
        if (newNumber <= productLocationsToClear[index].count) {
            arr[index] = newNumber;
            setArticleList(arr);
            console.log(arr);
        }
    };

    useEffect(() => {
        if (articleList.length === 0) {
            const newList: number[] = productLocationsToClear.map(loc => loc.count);
            setArticleList(newList);
        }
    }, [productLocationsToClear]);

    return (
        <Modal toggleModal={toggleModal} openModal={openModal} maxWidth='sm'>
            <Box sx={{ p: 5 }}>
                <Typography variant='h3' sx={{ mb: 3 }}>
                    Velg antall som skal tømmes
                </Typography>
                {articleList.length && (
                    <Column gap={2}>
                        {productLocationsToClear &&
                            productLocationsToClear.length &&
                            productLocationsToClear.map((loc, i) => (
                                <Box
                                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                                    key={i + '_' + loc.articleId + '_' + loc.sizeColorId}
                                >
                                    <Box sx={{ mr: 2, minWidth: '70%' }}>
                                        <Typography variant='subtitle2'>
                                            {loc.supplierArticleNumber ?? loc.articleNumber}
                                        </Typography>
                                        <Typography>
                                            {getArticleVariantName(loc.articleName!, loc.colorName, loc.sizeName)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <InputField
                                            label='Antall'
                                            id={'input_' + loc.articleNumber}
                                            value={articleList[i]}
                                            type='number'
                                            onChange={(
                                                event: ChangeEvent<{
                                                    value: unknown;
                                                    id: string;
                                                    type: string;
                                                }>
                                            ) => handleChange(event, i)}
                                        />
                                    </Box>
                                </Box>
                            ))}
                    </Column>
                )}
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='text' onClick={toggleModal}>
                        Avbryt
                    </Button>
                    <Button variant='contained' onClick={onSubmit}>
                        Tøm lokasjoner
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EmptyProductLocationsModal;
