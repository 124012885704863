import { ReactNode, MouseEvent } from 'react';
import { Button } from '@mui/material';

import { makeStyles } from '../../../state/hooks';

type Props = {
    children: ReactNode;
    selectTemplate: (value: string) => void;
    value: string;
};

const ReplyTemplateButton = ({ children, value, selectTemplate }: Props) => {
    const { classes } = useStyles();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        selectTemplate(event.currentTarget.value as string);
    };

    return (
        <Button className={classes.root} onClick={handleClick} value={value}>
            <>{children}</>
        </Button>
    );
};
const useStyles = makeStyles()(theme => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.primary[50],
        color: theme.palette.primary.main,
        lineHeight: '1.7em',
        marginRight: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary[700],
        },
    },
}));

export default ReplyTemplateButton;
