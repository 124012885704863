/**
 * Forces a string to a given size, and adds dots at the end.
 * It adds 2 dots as default, and 3 dots if the second to last character is a whitespace.
 * @param text The text to shorten.
 * @param max The max number of characters.
 * @returns The shortened string
 */
export function forceTextLength(text: string | null | undefined, max: number) {
    try {
        const length = text?.length;
        if (length && length >= max) {
            if (text[max - 1] === ' ') {
                return text.slice(0, max - 1) + '...';
            } else {
                return text.slice(0, max) + '..';
            }
        }
    } catch (error) {
        console.error(error);
        return '--';
    }
    return text;
}

/**
 * Returns dollar signs according to the price given.
 * @param price The price as a number
 * @returns Dollar signs as strings from $ to $$$$, or a '-' if the price is 0.
 */
export function getPriceSymbol(price: number) {
    let symbol = '$';
    if (price === 0) {
        symbol = '-';
    } else if (price >= 10000 && price < 30000) {
        symbol = '$$';
    } else if (price >= 30000 && price < 50000) {
        symbol = '$$$';
    } else if (price >= 50000) {
        symbol = '$$$$';
    }

    return symbol;
}

/**
 * Checks if a string is empty, and returns a dash instead.
 * @param text The text to check.
 * @returns The string, or a dash ('-').
 */
// TODO Rename this function to something more descriptive.
export function displayNicely(text: string | undefined | null) {
    if (!text || text === '') return '-';
    return text;
}

/**
 * Formats a phone number with spaces, e.g. 900 00 000
 * @param phoneNumber The phone number to format
 * @returns A formatted string
 */
export function formatPhoneNumber(phoneNumber: string) {
    const groups = phoneNumber.match(/(\w{3})(\w{2})(\w{3})/);
    groups?.shift(); // Removes first element, which is the whole phone number
    return groups?.join(' ') ?? phoneNumber;
}

/**
 *
 * @param phoneNumber
 * @returns
 */
export function cleanPhoneNumber(phoneNumber: string | number | null) {
    let cleaned = phoneNumber?.toString().replace(' ', '');
    if (cleaned) {
        const regex = cleaned.includes('/') ? /^\d{8}/g : /\d{8}$|/g;
        const cleanList = regex.exec(cleaned);
        if (cleanList) cleaned = cleanList[0];
    }
    return cleaned;
}

/**
 * Formats a price, with an optional suffix.
 * @param price The price to format.
 * @param suffix An optinoal suffix to add to the end of the string
 * @returns A formatted price as a string, or 'n/a' if price is not a number.
 */
export function formatPrice(price: number, suffix?: string): string {
    if (typeof price !== 'number') return 'n/a';
    const endText = suffix ? ' ' + suffix : ',-';
    const formattedPrice = formatThousands(price);
    if (price === 0) return formattedPrice + endText;
    if (price) return formattedPrice + endText;
    return 'n/a';
}

/**
 * Formats a number with spaces every thousands, e.g. 200000 returns '200 000'
 * @param num The number to format
 * @returns A formatted string.
 */
export function formatThousands(num: number): string {
    const roundedNumber = Math.round(num * 100) / 100; // Added to avoid 17.5 to be rounded up to 18

    // Checks if number has a decimal
    if (roundedNumber % 1 > 0) {
        const numberString = roundedNumber.toFixed(2).toString();
        let beforeComma = numberString.split('.');
        let formatted = stringWithSpaces(beforeComma[0]);
        return [formatted, beforeComma[1]].join('.');
    } else {
        return stringWithSpaces(roundedNumber.toString());
    }
}

/**
 * Helper function that adds spaces every third character.
 * @param str The string to format.
 * @returns Formatted string.
 */
export function stringWithSpaces(str: string) {
    var parts = str.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

/**
 * Helper function to capitalize the first letter of each word in the string.
 * @param text The text to format.
 * @returns The capitalized text.
 */
export function capitalizeText(text: string): string {
    let splitStr = text.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export const currencyFormatter = new Intl.NumberFormat('nb-NO', {
    currency: 'NOK',
    maximumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat('nb-NO', {
    maximumFractionDigits: 0,
});

/**
 * Formats percentage values to be displayed to users.
 * @param signDisplay When to display + or - before the percentage, defaults to 'always'.
 * @param maximumFractionDigits The maximum number of decimals to show.
 * @returns A formatter object that can be used to format values to percentage.
 */
export const percentageFormatter = (
    signDisplay: Intl.NumberFormatOptions['signDisplay'] = 'always',
    maximumFractionDigits: Intl.NumberFormatOptions['maximumFractionDigits'] = 0,
    style: Intl.NumberFormatOptions['style'] = 'percent'
) =>
    new Intl.NumberFormat('nb-NO', {
        style,
        signDisplay,
        maximumFractionDigits,
    });
