import { formatISO } from 'date-fns';
import BookingCalendarModal from '../../../components/Modals/BookingCalendarModal/BookingCalendarModal';
import {
    useDeleteSpecialCapacityMutation,
    useInsertSpecialCapacityMutation,
    useUpdateSpecialCapacityMutation,
} from '../../../services/BookingCapacity';
import { useSelector } from '../../../state/hooks';
import { CurrentDay } from './BookingCalendarContainer/BookingCalendarContainer';

type Props = {
    open: boolean;
    details: CurrentDay;
    handleOpen: () => void;
};

const BookingCalendarModalContainer = ({ open, details, handleOpen }: Props) => {
    const activeStoreLocation = useSelector(state => state.system.activeStoreLocation);

    const [updateSpecialCapacity] = useUpdateSpecialCapacityMutation();
    const [insertSpecialCapacity] = useInsertSpecialCapacityMutation();
    const [deleteSpecialCapacity] = useDeleteSpecialCapacityMutation();

    const handleUpdateSpecialCapacity = (newCapacity: number) => {
        if (details.date !== null && activeStoreLocation !== null) {
            const isoDate = formatISO(details.date, { representation: 'date' });
            if (details.specialCapacityId === null) {
                insertSpecialCapacity({ storeName: activeStoreLocation, date: isoDate, capacity: newCapacity });
            } else {
                updateSpecialCapacity({
                    storeName: activeStoreLocation,
                    id: details.specialCapacityId,
                    capacity: newCapacity,
                });
            }
        }
    };

    const handleResetSpecialCapacity = () => {
        if (details.specialCapacityId !== null && activeStoreLocation !== null) {
            deleteSpecialCapacity({ storeName: activeStoreLocation, id: details.specialCapacityId });
        }
    };

    return (
        <BookingCalendarModal
            open={open}
            handleOpen={handleOpen}
            details={details}
            handleUpdate={handleUpdateSpecialCapacity}
            handleReset={handleResetSpecialCapacity}
        />
    );
};

export default BookingCalendarModalContainer;
