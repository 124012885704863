import { collection, doc, updateDoc, getFirestore } from 'firebase/firestore';

import Customer from '../../../models/Customer';
import app from '../../../configs/firebaseConfig';
import { AppThunk } from '../../reduxTypes';
import { authFetch, createFailedAction } from '../../../utils/helpers';

export const UPDATE_CUSTOMER_INFO_STARTED = 'UPDATE_CUSTOMER_INFO_STARTED';
export const UPDATE_CUSTOMER_INFO_SUCCESS = 'UPDATE_CUSTOMER_INFO_SUCCESS';
export const UPDATE_CUSTOMER_INFO_FAILED = 'UPDATE_CUSTOMER_INFO_FAILED';

const updateCustomerStarted = () => ({
    type: UPDATE_CUSTOMER_INFO_STARTED,
});

const updateCustomerSuccess = () => ({
    type: UPDATE_CUSTOMER_INFO_SUCCESS,
});

const updateCustomerFailed = createFailedAction(UPDATE_CUSTOMER_INFO_FAILED);

export const updateCustomerInfo =
    (customerNumber: string): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(updateCustomerStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const response = await authFetch(fb, '/customers/' + customerNumber, 'GET', undefined, apiUrl);

            const result: {
                customerNumber: number | null;
                customerName: string | null;
                phoneNumber1: string | null;
                phoneNumber2: string | null;
                email: string | null;
                mailingAddress: string | null;
                mailingAddress2: string | null;
                mailingZip: string | null;
                mailingCity: string | null;
            } = await response.json();

            if (result && Object.keys(result).length > 0) {
                const updatedCustomer = new Customer(result);

                const db = getFirestore(app);
                const customersRef = collection(db, 'customers');
                const customerRef = doc(customersRef, updatedCustomer.customerNumber);

                await updateDoc(customerRef, {
                    customerName: updatedCustomer.customerName,
                    customerNumber: updatedCustomer.customerNumber,
                    phoneNumber1: updatedCustomer.phoneNumber1?.get() ?? '',
                    phoneNumber2: updatedCustomer.phoneNumber2?.get() ?? '',
                });
            } else throw new Error('Could not find customer with number ' + customerNumber);

            dispatch(updateCustomerSuccess());
        } catch (error) {
            dispatch(updateCustomerFailed(error));
        }
    };
