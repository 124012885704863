import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {},
    outgoing: {
        backgroundColor: theme.palette.success.main,
        '& > p': {
            color: '#fff',
        },
    },
    outgoingContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    incoming: {
        backgroundColor: theme.palette.neutrals[100],
    },
    incomingContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    messageContainer: {
        paddingBottom: 10,
    },
    message: {
        borderRadius: 16,
        padding: '12px 14px 6px 13px',
        maxWidth: '65%',
        position: 'relative',
    },
    messageText: {
        whiteSpace: 'pre-line',
        fontSize: '0.95em',
        lineHeight: '1.3em',
        overflowWrap: 'break-word',
    },
    blankedOut: {
        backgroundColor: '#f9f9f9',
        border: '2px solid rgba(0,0,0,0.05)',
        color: '#999',
    },
    hasNotification: {
        marginTop: 20,
    },
    notification: {
        right: -10,
        top: -10,
        position: 'absolute',
        padding: '3px 8px',
    },
    date: {
        fontSize: '0.7rem',
        opacity: 0.6,
        marginTop: 1,
        '&::first-letter': {
            textTransform: 'uppercase',
        },
    },
    outgoingDate: {
        opacity: 0.65,
        textAlign: 'right',
    },
    incommingDate: {
        opacity: 0.4,
    },
    notSent: {
        opacity: 0.5,
    },
}));
