import { formatISO } from 'date-fns';

import { authFetch, createFailedAction } from '../../../utils/helpers';
import { AppThunk } from '../../reduxTypes';
import { getServiceCapacity } from './GetServiceCapacity';

// Insert special capacity
export const INSERT_SPECIAL_CAPACITY_STARTED = 'INSERT_SPECIAL_CAPACITY_STARTED';
export const INSERT_SPECIAL_CAPACITY_SUCCESS = 'INSERT_SPECIAL_CAPACITY_SUCCESS';
export const INSERT_SPECIAL_CAPACITY_FAILED = 'INSERT_SPECIAL_CAPACITY_FAILED';

const insertSpecialCapacityStarted = () => ({
    type: INSERT_SPECIAL_CAPACITY_STARTED,
});

const insertSpecialCapacitySuccess = () => ({
    type: INSERT_SPECIAL_CAPACITY_SUCCESS,
});

const insertSpecialCapacityFailed = createFailedAction(INSERT_SPECIAL_CAPACITY_FAILED);

export const insertSpecialCapacity =
    (date: Date, capacity: number): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(insertSpecialCapacityStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const isoDate = formatISO(date, { representation: 'date' });
            const activeStoreLocation = getState().system.activeStoreLocation;

            const response = await authFetch(
                fb,
                `/specialCapacity?storeName=${activeStoreLocation}&date=${isoDate}&capacity=${capacity}`,
                'POST',
                undefined,
                apiUrl
            );
            if (response.ok) {
                dispatch(insertSpecialCapacitySuccess());
            } else {
                throw Error(INSERT_SPECIAL_CAPACITY_FAILED);
            }
        } catch (error) {
            dispatch(insertSpecialCapacityFailed(error as any));
        }
    };

// Update special capacity
export const UPDATE_SPECIAL_CAPACITY_STARTED = 'UPDATE_SPECIAL_CAPACITY_STARTED';
export const UPDATE_SPECIAL_CAPACITY_SUCCESS = 'UPDATE_SPECIAL_CAPACITY_SUCCESS';
export const UPDATE_SPECIAL_CAPACITY_FAILED = 'UPDATE_SPECIAL_CAPACITY_FAILED';

const updateSpecialCapacityStarted = () => ({
    type: UPDATE_SPECIAL_CAPACITY_STARTED,
});

const updateSpecialCapacitySuccess = () => ({
    type: UPDATE_SPECIAL_CAPACITY_SUCCESS,
});

const updateSpecialCapacityFailed = createFailedAction(UPDATE_SPECIAL_CAPACITY_FAILED);

export const updateSpecialCapacity =
    (id: string, capacity: number): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(updateSpecialCapacityStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            const activeStoreLocation = getState().system.activeStoreLocation;
            const response = await authFetch(
                fb,
                `/specialCapacity?storeName=${activeStoreLocation}&id=${id}&capacity=${capacity}`,
                'PUT',
                undefined,
                apiUrl
            );
            if (response.ok) {
                dispatch(updateSpecialCapacitySuccess());
            } else {
                throw Error(UPDATE_SPECIAL_CAPACITY_FAILED);
            }
        } catch (error) {
            dispatch(updateSpecialCapacityFailed(error));
        }
    };

// Delete special capacity
export const DELETE_SPECIAL_CAPACITY_STARTED = 'DELETE_SPECIAL_CAPACITY_STARTED';
export const DELETE_SPECIAL_CAPACITY_SUCCESS = 'DELETE_SPECIAL_CAPACITY_SUCCESS';
export const DELETE_SPECIAL_CAPACITY_FAILED = 'DELETE_SPECIAL_CAPACITY_FAILED';

const deleteSpecialCapacityStarted = () => ({
    type: DELETE_SPECIAL_CAPACITY_STARTED,
});

const deleteSpecialCapacitySuccess = () => ({
    type: DELETE_SPECIAL_CAPACITY_SUCCESS,
});

const deleteSpecialCapacityFailed = createFailedAction(DELETE_SPECIAL_CAPACITY_FAILED);

export const deleteSpecialCapacity =
    (id: string): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(deleteSpecialCapacityStarted());
        try {
            const apiUrl = getState().system.apiURL;
            if (!apiUrl) throw new Error('Could not fetch API URL.');
            const activeStoreLocation = getState().system.activeStoreLocation;
            const response = await authFetch(
                fb,
                `/specialCapacity?storeName=${activeStoreLocation}&id=${id}`,
                'DELETE',
                undefined,
                apiUrl
            );

            if (response.ok) {
                dispatch(deleteSpecialCapacitySuccess());
            } else {
                throw Error('DELETE_SPECIAL_CAPACITY_FAILED');
            }
        } catch (error) {
            dispatch(deleteSpecialCapacityFailed(error));
        }
    };
