import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Trash2 } from 'react-feather';
import { ArticlePlacement } from '../../../utils/types';

import SubtleSpinner from '../../Indicators/SubtleSpinner/SubtleSpinner';
import Column from '../../Layouts/Column/Column';
import Modal from '../Modal/Modal';

export type Props = {
    openModal: boolean;
    placements: ArticlePlacement[];
    handleSubmit: (barcode: string, articlePlacement: ArticlePlacement, succesCallbackFn: VoidFunction) => void;
    handleClearPlacement: (selectedPlacement: ArticlePlacement, succesCallbackFn: VoidFunction) => void;
    toggleModal: () => void;
    isLoading: boolean;
    isSuccess: boolean;
};

const UpdateLocationModal = ({
    openModal,
    placements,
    handleSubmit,
    handleClearPlacement,
    toggleModal,
    isLoading,
    isSuccess,
}: Props) => {
    placements.length > 0 &&
        placements.sort((a, b) => {
            const aNumber = a.placementName === null ? '0' : a.placementName.replace(/\D/g, '');
            const bNumber = b.placementName === null ? '0' : b.placementName.replace(/\D/g, '');
            return parseInt(aNumber) - parseInt(bNumber);
        });

    const [inputFieldValue, setInputFieldValue] = useState('');
    const [selectedPlacement, setSelectedPlacement] = useState<ArticlePlacement['placementId']>(
        placements[0].placementId
    );
    const [inputError, setInputError] = useState(false);
    const inputRef = useRef<HTMLInputElement>();

    const onKeypress = (event: any) => {
        if (event.keyCode === 13) {
            const placement = findPlacement(selectedPlacement);
            if (inputFieldValue === '') setInputError(true);
            else if (placements && placement) {
                setInputError(false);
                handleSubmit(inputFieldValue, placement, () => onSuccess(placement));
            }
        }
    };

    const handleEmptyPlacement = () => {
        const placement = findPlacement(selectedPlacement);
        if (placements && placement) {
            handleClearPlacement(placement, () => onSuccess(placement));
        }
    };

    const onChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputFieldValue(event.target.value);
        setInputError(false);
    };

    const incrementPlacementValue = (previousPlacement: ArticlePlacement) => {
        if (placements) {
            const placementIndex = placements.findIndex(v => v.placementId === previousPlacement.placementId);
            if (placementIndex < placements.length - 1) {
                setSelectedPlacement(placements[placementIndex + 1].placementId);
            }
        }
    };

    const findPlacement = (placementId: ArticlePlacement['placementId']) => {
        return placements.find(placement => placement.placementId === placementId);
    };

    const onSuccess = (placement: ArticlePlacement) => {
        incrementPlacementValue(placement);
        resetInputField();
        inputRef.current?.focus();
    };

    const resetInputField = () => setInputFieldValue('');

    useEffect(() => {
        if (!isLoading && isSuccess) {
            const placement = findPlacement(selectedPlacement);
            if (placement) onSuccess(placement);
        }
    }, [isLoading, isSuccess]);

    return (
        <Modal toggleModal={toggleModal} openModal={openModal} maxWidth='xs'>
            <Box sx={{ p: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Typography sx={{ mb: 4, mr: 2 }} variant='h3'>
                        Oppdater plasseringer
                    </Typography>
                    {isLoading && <SubtleSpinner size={24} />}
                </Box>
                <Column gap={2}>
                    <Paper elevation={0} sx={{ p: 2 }}>
                        <Column gap={2}>
                            {placements && placements.length > 0 && (
                                <FormControl fullWidth disabled={isLoading}>
                                    <InputLabel id='placement'>Plassering</InputLabel>
                                    <Select
                                        labelId='placement'
                                        value={selectedPlacement}
                                        label='Plassering'
                                        onChange={event => {
                                            const placement = findPlacement(
                                                event.target.value as ArticlePlacement['placementId']
                                            );
                                            if (placement) setSelectedPlacement(placement.placementId);
                                        }}
                                        data-cy='placement-dropdown'
                                    >
                                        {placements.map(placement => {
                                            if (placement.placementId !== null && placement.placementName !== null) {
                                                return (
                                                    <MenuItem key={placement.placementId} value={placement.placementId}>
                                                        {placement.placementName}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                            <FormControl fullWidth>
                                <TextField
                                    inputRef={inputRef}
                                    label='Strekkode'
                                    disabled={isLoading}
                                    placeholder='"6018156215" eller "528549"'
                                    id='scan-input'
                                    variant='outlined'
                                    value={inputFieldValue}
                                    autoComplete='off'
                                    onKeyDown={onKeypress}
                                    onChange={onChangeInput}
                                />
                                {inputError && <FormHelperText error>Strekkoden kan ikke være tom.</FormHelperText>}
                            </FormControl>
                            {selectedPlacement && (
                                <div>
                                    <Button
                                        disabled={isLoading}
                                        onClick={handleEmptyPlacement}
                                        sx={{ width: '100%' }}
                                        color='error'
                                        variant='outlined'
                                        startIcon={<Trash2 size={20} />}
                                    >
                                        Tøm plassering
                                    </Button>
                                </div>
                            )}
                        </Column>
                    </Paper>
                    {/* {placedProductLocation !== null ? (
                        <AllocatedPlacementCard
                            articleName={placedProductLocation.articleName}
                            articleNumber={placedProductLocation.articleNumber}
                            locationName={placedProductLocation.locationName}
                            placementName={placedProductLocation.placementName}
                            handleUndo={() => {}}
                            variant='compact'
                        />
                    ) : (
                        <AllocatedPlacementCard
                            articleName={'Ledig plassering'}
                            articleNumber={''}
                            locationName={location.name}
                            placementName={selectedPlacement}
                            handleUndo={() => {}}
                            variant='compact'
                        />
                    )} */}
                </Column>
            </Box>
        </Modal>
    );
};

export default UpdateLocationModal;
