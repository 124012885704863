import { Paper, Typography, Box, Grow, useMediaQuery, Theme, ButtonBase, Chip } from '@mui/material';
import { isPast, isToday } from 'date-fns';

import useStyles from './BookingCalendarCard.styles';
import { formatWeekday } from '../../../utils/date';

export type Props = {
    specialCapacityId?: string | null;
    date?: Date;
    capacity?: number | null;
    available?: number;
    isHoliday?: boolean;
    onClick?: (date: Date, capacity: number, specialCapacityId: string | null) => void;
};

/**
 * Card representing a day in a booking calender.
 */
const BookingCalendarCard = ({
    date,
    capacity,
    available = 0,
    isHoliday = false,
    specialCapacityId = null,
    onClick,
}: Props) => {
    const { classes, cx } = useStyles();
    const isSunday = date && formatWeekday(date) === 6;
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const hasSpecialCapacity = specialCapacityId !== null;

    const handleClick = () => {
        if (onClick && date && capacity !== undefined && capacity !== null) {
            onClick(date, capacity, specialCapacityId);
        }
    };

    let bookingState: 'available' | 'full' | 'overbooket';

    if (date) {
        let availableTextClass: any;
        let bottomText: string = isMobile ? `${available}` : `${available} ledige`;
        if (capacity && available > 0) {
            // Positive capacity
            bookingState = 'available';
            availableTextClass = cx(classes.bookingText, classes.bookingsAvailable);
        } else if (available < 0) {
            // Negative capacity
            bookingState = 'overbooket';
            availableTextClass = cx(classes.bookingText, classes.overbooked);
            isMobile ? (bottomText = `${available}`) : (bottomText = `${Math.abs(available)} overbooket`);
        } else {
            // No capacity
            bookingState = 'full';
            availableTextClass = classes.bookingText;
        }
        const pastDate = isPast(date);

        let rootStyles = classes.root;
        if (isToday(date)) rootStyles = cx(classes.root, classes.today);
        else if (isSunday) rootStyles = cx(classes.root, classes.sunday);

        const showSpecialCapacity = hasSpecialCapacity && !pastDate;

        const bookingColor = getBookingColor(bookingState);

        return (
            <Grow in={true} timeout={500}>
                <ButtonBase
                    className={rootStyles}
                    classes={{ disabled: classes.disabled }}
                    onClick={!pastDate ? handleClick : undefined}
                    disabled={pastDate}
                    disableRipple
                >
                    <Box className={classes.topContainer}>
                        <Typography
                            variant={'h4'}
                            sx={{ color: 'neutrals.500', alignSelf: isMobile ? 'center' : 'flex-end' }}
                            className={isHoliday || isSunday ? classes.emphasized : ''}
                        >
                            {date.getDate()}.
                        </Typography>
                        {!isMobile && <span className={showSpecialCapacity ? classes.special : ''}></span>}
                    </Box>
                    <Box className={classes.bottomContainer}>
                        {pastDate ? (
                            <Typography variant='body2' className={classes.bookingText}>
                                -
                            </Typography>
                        ) : (
                            <>
                                {isMobile ? (
                                    <Chip variant='outlined' size='small' label={bottomText} color={bookingColor} />
                                ) : (
                                    <Typography variant='body2' className={availableTextClass}>
                                        {bottomText}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Box>
                    {isMobile && showSpecialCapacity && <span className={classes.specialBottom}></span>}
                </ButtonBase>
            </Grow>
        );
    }

    return <Paper elevation={0} className={cx(classes.root, classes.blank)}></Paper>;
};

export default BookingCalendarCard;

function getBookingColor(bookingState: string) {
    switch (bookingState) {
        case 'available':
            return 'success';
        case 'full':
            return 'default';
        case 'overbooket':
            return 'error';
    }
    return 'primary';
}
