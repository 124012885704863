import { FormControl, TextField, FormHelperText } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { NewBikeBoxReservationFormData } from '../../../utils/types';
import Axis from '../../Layouts/Axis/Axis';

type Props = {
    control: Control<NewBikeBoxReservationFormData, any>;
    isLoading: boolean;
};

const NewReservationForm = ({ control, isLoading }: Props) => {
    return (
        <Axis isResponsive gap={2}>
            <Controller
                control={control}
                name='firstName'
                rules={{
                    required: true,
                    maxLength: 50,
                }}
                defaultValue={''}
                render={({ field: { ref, ...field }, fieldState: { isTouched, isDirty, error }, formState }) => (
                    <FormControl>
                        <TextField
                            {...field}
                            disabled={isLoading}
                            label='Fornavn'
                            placeholder='"Ola"'
                            variant='outlined'
                            autoComplete='off'
                            inputRef={ref}
                        />
                        {error?.type === 'required' && (
                            <FormHelperText error>Fornavn kan ikke være tom.</FormHelperText>
                        )}
                        {error?.type === 'maxLength' && (
                            <FormHelperText error>Maks-lengde på fornavn er 50 tegn.</FormHelperText>
                        )}
                    </FormControl>
                )}
            />
            <Controller
                control={control}
                name='lastName'
                rules={{
                    required: true,
                    maxLength: 50,
                }}
                defaultValue={''}
                render={({ field: { ref, ...field }, fieldState: { isTouched, isDirty, error }, formState }) => (
                    <FormControl>
                        <TextField
                            {...field}
                            disabled={isLoading}
                            label='Etternavn'
                            placeholder='"Nordmann"'
                            variant='outlined'
                            autoComplete='off'
                            inputRef={ref}
                        />
                        {error?.type === 'required' && (
                            <FormHelperText error>Etternavn kan ikke være tom.</FormHelperText>
                        )}
                        {error?.type === 'maxLength' && (
                            <FormHelperText error>Maks-lengde på etternavn er 50 tegn.</FormHelperText>
                        )}
                    </FormControl>
                )}
            />
            <Axis gap={2}>
                <Controller
                    control={control}
                    name='countryCode'
                    rules={{
                        required: true,
                        maxLength: 8,
                    }}
                    defaultValue={'+47'}
                    render={({ field: { ref, ...field }, fieldState: { isTouched, isDirty, error }, formState }) => (
                        <FormControl>
                            <TextField
                                {...field}
                                disabled={isLoading}
                                label='Landskode'
                                name='countryCode'
                                placeholder='"+47"'
                                variant='outlined'
                                autoComplete='tel-country-code'
                                aria-label='Landskode'
                                inputRef={ref}
                                style={{ width: 100 }}
                            />
                            {error?.type === 'required' && (
                                <FormHelperText error>Landkode kan ikke være tom.</FormHelperText>
                            )}
                            {error?.type === 'maxLength' && (
                                <FormHelperText error>Maks-lengde på landkode er 8 tegn.</FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
                <Controller
                    control={control}
                    name='phoneNumber'
                    rules={{
                        required: true,
                        maxLength: 15,
                    }}
                    defaultValue={''}
                    render={({ field: { ref, ...field }, fieldState: { isTouched, isDirty, error }, formState }) => (
                        <FormControl>
                            <TextField
                                {...field}
                                disabled={isLoading}
                                label='Mobilnummer'
                                placeholder='"12345678"'
                                variant='outlined'
                                autoComplete='off'
                                type={'tel'}
                                inputRef={ref}
                            />
                            {error?.type === 'required' && (
                                <FormHelperText error>Mobilnummer kan ikke være tom.</FormHelperText>
                            )}
                            {error?.type === 'maxLength' && (
                                <FormHelperText error>Maks-lengde på mobilnummer er 15 tegn.</FormHelperText>
                            )}
                        </FormControl>
                    )}
                />
            </Axis>
        </Axis>
    );
};

export default NewReservationForm;
