import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createArticleAttribute, getArticleAttributes } from '../../../../state/ducks/inventory/ArticleAttributes';
import { getArticleGroups } from '../../../../state/ducks/statistics/StatisticsFilter';
import { useSelector } from '../../../../state/hooks';
import { ArticleAttribute } from '../../../../utils/types';
import Attributes from '../../../../views/Inventory/Attributes/Attributes';

type Props = {};

const AttributesContainer = ({}: Props) => {
    const { articleAttributes } = useSelector(state => state.inventory);
    const { primaryArticleGroups, secondaryArticleGroups, tertiaryArticleGroups } = useSelector(
        state => state.statistics
    );
    const dispatch = useDispatch();

    const [openCreateAttributeModal, setOpenCreateAttributeModal] = useState(false);

    useEffect(() => {
        dispatch(getArticleAttributes(null, null, null));
        dispatch(getArticleGroups());
    }, [dispatch]);

    const handleCreateAttribute = (attribute: ArticleAttribute) => {
        console.log('Create attribute');
        dispatch(createArticleAttribute(attribute, toggleCreateAttributesModal));
    };

    const toggleCreateAttributesModal = () => {
        setOpenCreateAttributeModal(!openCreateAttributeModal);
    };

    return (
        <Attributes
            openCreateAttributeModal={openCreateAttributeModal}
            toggleCreateAttributeModal={toggleCreateAttributesModal}
            attributes={articleAttributes}
            primaryArticleGroups={primaryArticleGroups}
            secondaryArticleGroups={secondaryArticleGroups}
            tertiaryArticleGroups={tertiaryArticleGroups}
            handleSaveAttribute={handleCreateAttribute}
        />
    );
};

export default AttributesContainer;
