import useStyles from './PhoneNumberWithIcon.styles';
import PhoneNumber from '../../../models/PhoneNumber';
import { Phone } from 'react-feather';

export type Props = {
    iconNumber: string;
    phoneNumber: PhoneNumber;
};

const PhoneNumberWithIcon = ({ iconNumber, phoneNumber }: Props) => {
    const { classes, cx } = useStyles();

    const classNames =
        iconNumber === '1'
            ? cx(classes.iconContainer, classes.phoneNum1)
            : cx(classes.iconContainer, classes.phoneNum2);

    return (
        <div className={classes.root}>
            <div className={classNames}>
                <Phone className={classes.icon} />
                <span className={classes.number}>{iconNumber}</span>
            </div>
            {phoneNumber.getPretty()}
        </div>
    );
};

export default PhoneNumberWithIcon;
