import { TableRow, styled, TableCell, Typography } from '@mui/material';
import { ArticleVariant } from '../../../models/Article';
import { getArticleVariantName, getStockColor } from '../../../utils/helpers';
import InventoryLocationCard from '../../Cards/InventoryLocationCard/InventoryLocationCard';
import Axis from '../../Layouts/Axis/Axis';
import MobileExpandableTableRow from '../MobileExpandableTableRow/MobileExpandableTableRow';

export type Props = {
    articleName: string;
    articleVariants: ArticleVariant[];
    hasVariants: boolean;
    handleFetchLocations: (sizeColorId: number | null) => void;
    handleRemoveFromProductLocation: (
        locationId: number,
        placementId: number | null,
        sizeColorId: number | null
    ) => void;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MobileArticleStockTable = ({
    articleName,
    articleVariants,
    hasVariants,
    handleFetchLocations,
    handleRemoveFromProductLocation,
}: Props) => {
    const tableRows = articleVariants.map(variant => {
        const bodyRows = (
            <>
                {hasVariants && (
                    <>
                        <StyledTableRow>
                            <TableCell>
                                <Typography variant='subtitle2'>Farge</Typography>
                            </TableCell>
                            <TableCell>{variant.color?.colorName}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell>
                                <Typography variant='subtitle2'>Størrelse</Typography>
                            </TableCell>
                            <TableCell>{variant.size?.sizeName}</TableCell>
                        </StyledTableRow>
                    </>
                )}
                <StyledTableRow>
                    <TableCell>
                        <Typography variant='subtitle2'>Disponibel</Typography>
                    </TableCell>
                    <TableCell sx={{ color: getStockColor(variant.stock.available) }}>
                        {variant.stock.available}
                    </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell>
                        <Typography variant='subtitle2'>I innkjøp</Typography>
                    </TableCell>
                    <TableCell sx={{ color: getStockColor(variant.stock.inTransit, true) }}>
                        {variant.stock.inTransit}
                    </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell>
                        <Typography variant='subtitle2'>I ordre</Typography>
                    </TableCell>
                    <TableCell>{variant.stock.inOrder}</TableCell>
                </StyledTableRow>
            </>
        );

        const noPlacements = !variant.inventoryLocations || variant.inventoryLocations.length === 0;

        return (
            <MobileExpandableTableRow
                key={variant.articleNumber}
                headerRow={{
                    key: 'Artikkelnummer',
                    value: variant.articleNumber,
                    displayFormat: 'text',
                }}
                bodyRows={bodyRows}
                buttonText={{ opened: 'Lukk plasseringer', closed: 'Åpne plasseringer' }}
                onOpen={() => handleFetchLocations(variant.sizeColorId)}
            >
                <Axis direction='column' gap={2}>
                    {!noPlacements ? (
                        variant.inventoryLocations.map(location => {
                            const articleVariantName = getArticleVariantName(
                                articleName,
                                variant.color?.colorName ?? null,
                                variant.size?.sizeName ?? null
                            );
                            return (
                                <InventoryLocationCard
                                    key={location.placementId ?? location.locationId}
                                    locationId={location.locationId}
                                    placementId={location.placementId}
                                    sizeColorId={variant.sizeColorId}
                                    locationName={location.placementName ?? location.locationName}
                                    count={location.count}
                                    articleName={articleVariantName}
                                    handleMoveArticle={() => {}}
                                    handleRemoveArticle={handleRemoveFromProductLocation}
                                />
                            );
                        })
                    ) : (
                        <Typography variant='body1' sx={{ mb: 2 }}>
                            Ingen plasseringer
                        </Typography>
                    )}
                </Axis>
            </MobileExpandableTableRow>
        );
    });

    return (
        <Axis direction='column' gap={2}>
            <>{tableRows}</>
        </Axis>
    );
};

export default MobileArticleStockTable;
