import * as QuerySearchTypes from './QuerySearch';
import * as SetSelectedTemplateTypes from './SetSelectedTemplate';
import { FutureOrders } from '../../../utils/types';

interface filterState {
    isSearching: boolean;
    searchQuery: string;
    searchResults: FutureOrders | null;
    activeSearch: boolean;
    activeTemplateId: number;
    activeTemplate: object | null;
}

const initialState: filterState = {
    isSearching: false,
    searchQuery: '',
    searchResults: null,
    activeSearch: false,
    activeTemplateId: 0,
    activeTemplate: null,
};

export default function filterReducer(state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case QuerySearchTypes.QUERY_SEARCH:
            return {
                ...state,
                searchQuery: payload.query,
                isSearching: true,
                activeSearch: true,
            };
        case QuerySearchTypes.QUERY_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: payload.results,
                isSearching: false,
            };
        case QuerySearchTypes.RESET_SEARCH:
            return {
                ...state,
                isSearching: false,
                searchResults: null,
                searchQuery: '',
                activeSearch: false,
            };

        case SetSelectedTemplateTypes.SET_SELECTED_TEMPLATE:
            let templateId = 0;
            if (payload.template) {
                templateId = payload.template.id;
            }

            return {
                ...state,
                activeTemplate: payload.template,
                activeTemplateId: templateId,
            };

        default:
            return state;
    }
}
