import { makeStyles } from '../../../state/hooks';
import constants from '../../../styles/styleConstants';

const ligthGray = '#F2F2F2';

export default makeStyles()(theme => ({
    root: {
        minHeight: 60,
        backgroundColor: 'white',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        boxShadow: constants.lightBoxShadow,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: theme.palette.neutrals[100],
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            alignItems: 'center',
        },
    },
    topContainer: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            '& > h4': {
                fontSize: '1rem',
            },
        },
    },
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: 20,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingBottom: 2,
            justifyContent: 'center',
            '& > p': {
                fontSize: '0.75rem',
            },
        },
    },
    sunday: {
        backgroundColor: ligthGray,
        '&.Mui-disabled': {
            backgroundColor: ligthGray + ' !important',
        },
    },
    disabled: {
        backgroundColor: 'white !important',
        boxShadow: constants.lightBoxShadow + ' !important',
    },
    today: {
        boxShadow: `0 0 0 2px ${theme.palette.primary.main} !important`,
    },
    bookingText: {
        textAlign: 'left',
        padding: 2,
        color: theme.palette.neutrals[400],
    },
    bookingsAvailable: {
        color: theme.palette.success.main,
    },
    overbooked: {
        color: theme.palette.error.main,
    },
    blank: {
        backgroundColor: theme.palette.neutrals[200],
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.neutrals[200],
        },
    },
    emphasized: {
        color: theme.palette.error.main,
        '&::after': {
            borderBottom: '2px solid',
            content: '""',
            marginTop: -2,
            display: 'block',
        },
    },
    special: {
        width: '100%',
        position: 'relative',
        '&::before': {
            color: theme.palette.warning.main,
            content: '"Spesiell"',
            position: 'absolute',
            fontSize: '0.75rem',
            top: 3,
            right: 0,
            fontWeight: 550,
        },
    },
    specialBottom: {
        position: 'absolute',
        width: '100%',
        left: 0,
        bottom: 0,
        backgroundColor: theme.palette.warning.main,
        height: 5,
        borderEndStartRadius: 8,
        borderEndEndRadius: 8,
    },
}));
