import { Box, Typography, Grow } from '@mui/material';

import SmallNotification from '../../Notifications/SmallNotification/SmallNotification';
import { Message } from '../../../utils/types';
import { formatPastDate } from '../../../utils/date';
import useStyles from './MessageBubble.styles';

export type Props = {
    message: Message;
    read: boolean;
};

const MessageBubble = ({ message, read }: Props) => {
    const { classes, cx } = useStyles();

    const messageType = message.isIncoming ? classes.incoming : classes.outgoing;
    const messageTypeContainer = message.isIncoming ? classes.incomingContainer : classes.outgoingContainer;
    const sent = message?.sent || message?.sent === undefined;

    return (
        <Grow in={true} timeout={500}>
            <div className={classes.root}>
                <Box
                    className={cx(
                        classes.messageContainer,
                        messageTypeContainer,
                        read ? classes.hasNotification : null,
                        !sent ? classes.notSent : null
                    )}
                >
                    <Box className={cx(classes.message, messageType)}>
                        <Typography className={classes.messageText}>{message.text}</Typography>
                        <Typography
                            className={cx(
                                classes.date,
                                message.isIncoming ? classes.incommingDate : classes.outgoingDate
                            )}
                        >
                            {formatPastDate(message.date.toDate()) + ' siden'}
                        </Typography>
                        {read && <SmallNotification label='Ny' classNames={classes.notification} />}
                    </Box>
                </Box>
            </div>
        </Grow>
    );
};

export default MessageBubble;
