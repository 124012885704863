import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    tableHeader: {
        color: '#fff',
        backgroundColor: '#6b909a',
    },
    stockErrorIcon: {
        marginLeft: 5,
        alignSelf: 'center',
        color: theme.palette.error.main,
    },
    stockSpan: {
        display: 'flex',
        alignItems: 'center',
    },
}));
