import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { firebaseReducer, getFirebase } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

import pckReducer from './ducks/pck/pckReducer';
import smsReducer from './ducks/sms/smsReducer';
import filterReducer from './ducks/filter/filterReducer';
import systemReducer from './ducks/system/systemReducer';
import bookingReducer from './ducks/bookings/bookingReducer';
import errorReducer from './ducks/error/errorReducer';
import conversationsReducer from './ducks/conversations/conversationsReducer';
import statisticsReducer from './ducks/statistics/statisticsReducer';
import inventoryReducer from './ducks/inventory/inventoryReducer';

import { ordersApi } from '../services/Orders';
import { rtkQueryErrorLogger } from '../services/api';

const middlewares = [thunk.withExtraArgument(getFirebase), ordersApi.middleware, rtkQueryErrorLogger];

export const rootReducer = combineReducers({
    pck: pckReducer,
    sms: smsReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    filter: filterReducer,
    system: systemReducer,
    bookings: bookingReducer,
    error: errorReducer,
    conversations: conversationsReducer,
    statistics: statisticsReducer,
    inventory: inventoryReducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
});

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));
