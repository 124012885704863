import firebase from '../../../configs/firebaseConfig';
import { createFailedAction } from '../../../utils/helpers';

import { AppThunk } from '../../reduxTypes';

export const DELETE_CONVERSATION_STARTED = 'DELETE_CONVERSATION_STARTED';
export const DELETE_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS';
export const DELETE_CONVERSATION_FAILED = 'DELETE_CONVERSATION_FAILED';

const deleteConversationStarted = () => ({
    type: DELETE_CONVERSATION_STARTED,
});
const deleteConversationSuccess = () => ({
    type: DELETE_CONVERSATION_SUCCESS,
});
const deleteConversationFailed = createFailedAction(DELETE_CONVERSATION_FAILED);

export const deleteConversation =
    (customerNumber: string): AppThunk =>
    async dispatch => {
        dispatch(deleteConversationStarted());
        try {
            await firebase.firestore().collection(`customers`).doc(customerNumber).delete();
            dispatch(deleteConversationSuccess());
        } catch (error) {
            dispatch(deleteConversationFailed(error));
        }
    };
