import { ChangeEvent, ReactNode } from 'react';
import { Tabs } from '@mui/material';

import { makeStyles } from '../../../state/hooks';

export type Props = {
    children: ReactNode;
    value: number | string;
    handleTabChange: (event: ChangeEvent<{}>, newValue: number) => void;
};

const TabMenu = ({ children, value, handleTabChange }: Props) => {
    const { classes } = useStyles();

    return (
        <div>
            <Tabs
                variant='scrollable'
                value={value}
                onChange={handleTabChange}
                indicatorColor='primary'
                textColor='primary'
                className={classes.root}
            >
                {children}
            </Tabs>
            <div className={classes.menuLineSeparator}></div>
        </div>
    );
};
const useStyles = makeStyles()(theme => ({
    root: {
        '& > div > span': {
            height: 4,
            zIndex: 200,
        },
        '& .MuiTab-root': {
            minWidth: 'auto !important' as any,
        },
        '& .MuiButtonBase-root': {
            marginRight: 30,
            padding: 0,
            overflow: 'visible',
        },
        '& .MuiTabs-scrollableX': {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
        },
    },
    menuLineSeparator: {
        borderBottom: '1px solid #e2e2e2',
        display: 'block',
        position: 'relative',
        marginTop: -1,
    },
}));

export default TabMenu;
