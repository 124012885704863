import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import StickyHeaderBox from '../../Layouts/HeaderBox/HeaderBox';
import ConversationToolbarContainer from '../../../containers/Generic/ConversationToolbarContainer/ConversationToolbarContainer';
import ContactContainer from '../../../containers/Generic/CustomerContactContainers/ContactContainer/ContactContainer';
import ConversationHeaderContainer from '../../../containers/PageSpecific/Conversations/ConversationHeaderContainer/ConversationHeaderContainer';

const MainConversation = () => {
    const { customerId } = useParams();
    const [contactInput, setContactInput] = useState('');

    useEffect(() => {
        setContactInput('');
    }, [customerId]);

    return (
        <StickyHeaderBox
            header={
                <>
                    <ConversationHeaderContainer />
                    <ConversationToolbarContainer />
                </>
            }
        >
            <ContactContainer contactInput={contactInput} setContactInput={setContactInput} />
        </StickyHeaderBox>
    );
};

export default MainConversation;
