import { makeStyles } from '../../../state/hooks';

export default makeStyles()({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    phoneNum1: {
        color: '#1CA4CF',
    },
    phoneNum2: {
        color: '#CF871C',
    },
    number: {
        position: 'relative',
        top: -7,
        right: 10,
        fontSize: 12,
    },
    icon: {
        height: 19,
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});
