import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        padding: 30,
    },
    heading: {
        marginTop: -7,
        marginBottom: 2,
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    inputText: {
        color: '#222',
    },
    input: {
        marginTop: 5,
        width: 120,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 40,
    },
    resetContainer: {
        marginTop: 20,
        marginBottom: -20,
        paddingTop: 7,
        borderTop: '1px solid #e6e6e6',
        display: 'flex',
        justifyContent: 'center',
    },
    inputContainer: {
        /* display: 'flex',
        alignItems: 'center', */
    },
    reset: {
        color: theme.palette.warning.main,
        paddingLeft: 20,
        paddingRight: 20,
    },
    subHeading: {
        marginBottom: 25,
        color: '#777',
    },
}));
