import { ButtonBase, Typography } from '@mui/material';
import { ChevronDown } from 'react-feather';

import useStyles from './ExpandButton.styles';

export type Props = {
    text?: string;
    isExpanded: boolean;
    handleClick: () => void;
};

/**
 * Component representing a button for showing hidden content vertically.
 */
const ExpandButton = ({ text = '', isExpanded, handleClick }: Props) => {
    const { classes, cx } = useStyles();

    const chevronStyles = isExpanded ? classes.chevronOpen : classes.chevronClosed;

    return (
        <>
            <ButtonBase disableRipple className={classes.buttonRoot} onClick={handleClick}>
                <div className={classes.root}>
                    {text && <Typography className={classes.text}>{text}</Typography>}
                    <ChevronDown color='#999' className={cx(classes.chevron, chevronStyles)} />
                </div>
            </ButtonBase>
        </>
    );
};

export default ExpandButton;
