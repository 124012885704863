import {
    Box,
    Button,
    Collapse,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ExpandButton from '../../../../components/Buttons/ExpandButton/ExpandButton';
import SubtleSpinner from '../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import Axis from '../../../../components/Layouts/Axis/Axis';
import EmptyProductLocationsModal from '../../../../components/Modals/EmptyProductLocationsModal/EmptyProductLocationsModal';
import GeneratePlacementsModal from '../../../../components/Modals/GeneratePlacementsModal/GeneratePlacementsModal';
import LocationModal from '../../../../components/Modals/LocationModal/LocationModal';
import PromptModal from '../../../../components/Modals/PromptModal/PromptModal';
import UpdateLocationModal from '../../../../components/Modals/UpdateLocationModal/UpdateLocationModal';
import NestedBreadcrumbs from '../../../../components/Navigation/NestedBreadcrumbs/NestedBreadcrumbs';
import MobileInventoryPlacementsTable from '../../../../components/Tables/MobileInventoryPlacementsTable/MobileInventoryPlacementsTable';
import InventoryPlacementsTable from '../../../../components/Tables/InventoryPlacementsTable/InventoryPlacementsTable';
import SmallLabel from '../../../../components/Typography/SmallLabel/SmallLabel';
import { useDeleteProductLocationMutation } from '../../../../services/ProductLocations';
import { deleteLocation, editLocation, getProductLocations } from '../../../../state/ducks/inventory/Locations';
import { placeInventory } from '../../../../state/ducks/inventory/PlaceInventory';
import { useSelector } from '../../../../state/hooks';
import {
    ArticlePlacement,
    InventoryLocation as InventoryLocationType,
    LocationTypes,
    NewLocation,
} from '../../../../utils/types';

export type Props = {
    currentLocation: InventoryLocationType | undefined;
    creatingPlacements: boolean;
    handleSubmitPlacements: (placements: string[], callbackFn: VoidFunction) => void;
};

// TODO Split component up, separate the location settings from the placements.
const InventoryLocation = ({ currentLocation, creatingPlacements, handleSubmitPlacements }: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showEditLocationModal, setShowEditLocationModal] = useState(false);
    const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
    const [showUpdatePlacementsModal, setShowUpdatePlacementsModal] = useState(false);
    const [showLocationInfo, setShowLocationInfo] = useState(false);
    const [showCreatePlacementsModal, setShowCreatePlacementsModal] = useState(false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [productLocationsToClear, setProductLocationsToClear] = useState<ArticlePlacement[] | null>(null);

    const [deleteProductLocation, { isLoading, isSuccess }] = useDeleteProductLocationMutation();

    const [placementsMenuAnchor, setPlacementsMenuAnchor] = useState<null | HTMLElement>(null);

    const { primaryArticleGroups, secondaryArticleGroups, tertiaryArticleGroups } = useSelector(
        state => state.statistics
    );

    const onSaveLocation = (location: NewLocation) => {
        dispatch(editLocation(location));
        setShowEditLocationModal(false);
    };

    const onDeleteLocation = () => {
        if (currentLocation) {
            dispatch(deleteLocation(currentLocation.inventoryLocationId, onDeleteLocationCallback));
            setShowDeleteLocationModal(false);
        }
    };

    const handleAllocateArticle = (
        barcode: string,
        articlePlacement: ArticlePlacement,
        succesCallbackFn: VoidFunction
    ) => {
        if (currentLocation) {
            dispatch(
                placeInventory(
                    barcode,
                    currentLocation.inventoryLocationId,
                    articlePlacement.placementId,
                    succesCallbackFn
                )
            );
        }
    };

    const onDeleteLocationCallback = () => {
        navigate('../', { replace: true });
    };

    const handleClearMultipleProductLocations = (placements: ArticlePlacement[]) => {
        setProductLocationsToClear(placements);
    };

    const onClearProductLocation = () => {
        setProductLocationsToClear(null);
    };

    const handleClearPlacement = (
        placements: { articlePlacement: ArticlePlacement; count: number }[],
        callbackFn?: VoidFunction
    ) => {
        if (currentLocation) {
            const placement = placements[0].articlePlacement;
            deleteProductLocation({
                locationId: currentLocation.inventoryLocationId,
                articleId: placement.articleId ?? undefined,
                placementId: placement.placementId ?? undefined,
                sizeColorId: placement.sizeColorId ?? undefined,
                count: 1,
            });
        }
    };

    const toggleUpdatePlacementsModal = () => {
        setShowUpdatePlacementsModal(!showUpdatePlacementsModal);
        if (currentLocation)
            dispatch(getProductLocations(currentLocation?.inventoryLocationId, currentLocation?.locationType));
        setPlacementsMenuAnchor(null);
    };

    const productLocations = currentLocation?.productLocations;

    return (
        <>
            {productLocations ? (
                <Box sx={{ mt: 5 }}>
                    <NestedBreadcrumbs currentPathname={currentLocation.name} parentPath={'Lokasjoner'} />
                    <Paper
                        sx={{
                            mt: 4,
                            p: {
                                xs: 4,
                                sm: 5,
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                            <Typography sx={{ mr: 1 }} variant='h2'>
                                {currentLocation.name}
                            </Typography>
                            <ExpandButton
                                isExpanded={showLocationInfo}
                                handleClick={() => setShowLocationInfo(!showLocationInfo)}
                            />
                        </Box>
                        <Collapse in={showLocationInfo}>
                            <Box sx={{ mb: 4 }}>
                                <Axis isResponsive gap={2}>
                                    <Box>
                                        <SmallLabel text={'Lokasjonstype'} />
                                        <Typography>{LocationTypes[currentLocation.locationType]}</Typography>
                                    </Box>
                                    <Box>
                                        <SmallLabel text={'Maks størrelse'} />
                                        <Typography>{currentLocation.maxSize}</Typography>
                                    </Box>
                                    <Box>
                                        <SmallLabel text={'Artikkelgruppe 1'} />
                                        <Typography>{currentLocation.articleGroup1Name ?? '-'}</Typography>
                                    </Box>
                                    <Box>
                                        <SmallLabel text={'Artikkelgruppe 2'} />
                                        <Typography>{currentLocation.articleGroup2Name ?? '-'}</Typography>
                                    </Box>
                                    <Box>
                                        <SmallLabel text={'Artikkelgruppe 3'} />
                                        <Typography>{currentLocation.articleGroup3Name ?? '-'}</Typography>
                                    </Box>
                                </Axis>
                                <Box sx={{ mt: 2 }}>
                                    <Axis isResponsive gap={2}>
                                        {/* <Button
                                            onClick={() => setShowEditLocationModal(!showEditLocationModal)}
                                            variant='outlined'
                                        >
                                            Rediger lokasjon
                                        </Button> */}
                                        {currentLocation.locationType === 'one-to-one' && (
                                            <Button
                                                variant='outlined'
                                                onClick={() => setShowCreatePlacementsModal(true)}
                                            >
                                                Legg til plasseringer
                                            </Button>
                                        )}
                                        <Button
                                            onClick={() => setShowDeleteLocationModal(!showDeleteLocationModal)}
                                            variant='outlined'
                                            color='error'
                                        >
                                            Slett lokasjon
                                        </Button>
                                    </Axis>
                                </Box>
                            </Box>
                        </Collapse>

                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                                <Typography variant='h3' sx={{ mr: 1 }}>
                                    Plasseringer
                                </Typography>
                                {currentLocation.locationType === 'one-to-one' && (
                                    <IconButton onClick={event => setPlacementsMenuAnchor(event.currentTarget)}>
                                        {<MoreVertical color='#000' size={20} />}
                                    </IconButton>
                                )}
                            </Box>
                            <Menu
                                id='basic-menu'
                                anchorEl={placementsMenuAnchor}
                                open={placementsMenuAnchor !== null}
                                onClose={() => setPlacementsMenuAnchor(null)}
                            >
                                <MenuItem onClick={toggleUpdatePlacementsModal}>Oppdater plasseringer</MenuItem>
                            </Menu>
                            {isMobile ? (
                                <MobileInventoryPlacementsTable
                                    placements={productLocations}
                                    handleClearPlacement={handleClearPlacement}
                                    handleClearMultipleProductLocations={handleClearMultipleProductLocations}
                                />
                            ) : (
                                <InventoryPlacementsTable
                                    articleRows={productLocations}
                                    locationType={currentLocation.locationType}
                                    handleClearPlacement={handleClearPlacement}
                                    handleClearMultipleProductLocations={handleClearMultipleProductLocations}
                                />
                            )}
                        </Box>
                    </Paper>
                    {productLocations.length && (
                        <UpdateLocationModal
                            openModal={showUpdatePlacementsModal}
                            toggleModal={toggleUpdatePlacementsModal}
                            placements={productLocations}
                            handleClearPlacement={(articlePlacement, callbackFn) =>
                                handleClearPlacement([{ articlePlacement, count: 1 }], callbackFn)
                            }
                            handleSubmit={handleAllocateArticle}
                            isLoading={isLoading}
                            isSuccess={isSuccess}
                        />
                    )}
                    <LocationModal
                        open={showEditLocationModal}
                        title='Rediger lokasjon'
                        handleToggleModal={() => setShowEditLocationModal(!showEditLocationModal)}
                        handleSaveLocation={onSaveLocation}
                        primaryArticleGroups={primaryArticleGroups}
                        secondaryArticleGroups={secondaryArticleGroups}
                        tertiaryArticleGroups={tertiaryArticleGroups}
                        initialValues={{
                            name: { value: currentLocation.name },
                            maxSize: { value: currentLocation.maxSize ?? undefined },
                            locationType: { value: currentLocation.locationType, disabled: true },
                            primaryArticleGroup: { value: currentLocation.articleGroup1Id, disabled: true },
                            secondaryArticleGroup: { value: currentLocation.articleGroup2Id, disabled: true },
                            tertiaryArticleGroup: { value: currentLocation.articleGroup3Id, disabled: true },
                        }}
                    />
                    <PromptModal
                        openModal={showDeleteLocationModal}
                        maxWidth={'sm'}
                        title={`Slette lokasjonen "${currentLocation.name}"?`}
                        description='Dette vil slette lokasjonen, og alle plasseringer som tilhører den.'
                        leftButton={
                            <Button onClick={() => setShowDeleteLocationModal(!showDeleteLocationModal)}>Avbryt</Button>
                        }
                        rightButton={
                            <Button onClick={onDeleteLocation} variant='contained' color='error'>
                                Slett lokasjonen
                            </Button>
                        }
                        toggleModal={() => setShowDeleteLocationModal(!showDeleteLocationModal)}
                    />
                </Box>
            ) : (
                <SubtleSpinner text='Laster lokasjoner...' />
            )}
            <GeneratePlacementsModal
                isLoading={creatingPlacements}
                openModal={showCreatePlacementsModal}
                toggleModal={() => setShowCreatePlacementsModal(!showCreatePlacementsModal)}
                handleSubmitPlacements={handleSubmitPlacements}
            />
            {productLocationsToClear !== null && (
                <EmptyProductLocationsModal
                    openModal={productLocationsToClear !== null}
                    toggleModal={onClearProductLocation}
                    handleClearPlacement={handleClearPlacement}
                    onClearProductLocation={onClearProductLocation}
                    productLocationsToClear={productLocationsToClear}
                />
            )}
        </>
    );
};

export default InventoryLocation;
