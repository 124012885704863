import * as SetStandardCapacityTypes from './SetStandardCapacity';
import * as UpdateSpecialCapacityTypes from './UpdateSpecialCapacity';
import * as HolidayTypes from './GetHolidays';
import * as GetServiceCapacityTypes from './GetServiceCapacity';

import { Holidays, ServiceCapacity } from '../../../utils/types';

interface bookingState {
    holidays: Holidays | null;
    prioritizedCapacity: ServiceCapacity[][] | null;
    nonPrioritizedCapacity: ServiceCapacity[][] | null;
    settingStandardCapacity: boolean;
    fetchingStandardCapacity: boolean;
    fetchingSpecialCapacity: boolean;
    fetchingBookingData: boolean;
}

const initialState: bookingState = {
    holidays: null,
    prioritizedCapacity: null,
    nonPrioritizedCapacity: null,
    settingStandardCapacity: false,
    fetchingStandardCapacity: false,
    fetchingSpecialCapacity: false,
    fetchingBookingData: false,
};

export default function bookingReducer(state = initialState, action: any): bookingState {
    const { type, payload } = action;

    switch (type) {
        case SetStandardCapacityTypes.SET_WEEKDAY_CAPACITY_STARTED:
            return {
                ...state,
                settingStandardCapacity: true,
            };

        case SetStandardCapacityTypes.SET_WEEKDAY_CAPACITY_SUCCESS:
            return {
                ...state,
                settingStandardCapacity: false,
            };

        case SetStandardCapacityTypes.SET_WEEKDAY_CAPACITY_FAILED:
            return {
                ...state,
                settingStandardCapacity: false,
            };

        case UpdateSpecialCapacityTypes.UPDATE_SPECIAL_CAPACITY_STARTED:
            return {
                ...state,
                fetchingSpecialCapacity: true,
            };

        case UpdateSpecialCapacityTypes.UPDATE_SPECIAL_CAPACITY_FAILED:
            return {
                ...state,
                fetchingSpecialCapacity: false,
            };

        case HolidayTypes.GET_HOLIDAYS_SUCCESS:
            return {
                ...state,
                holidays: payload.holidays,
            };

        case GetServiceCapacityTypes.GET_SERVICE_CAPACITY_STARTED:
            return {
                ...state,
                fetchingBookingData: true,
            };

        case GetServiceCapacityTypes.GET_SERVICE_CAPACITY_SUCCESS:
            return {
                ...state,
                fetchingBookingData: false,
                nonPrioritizedCapacity: payload.serviceCapacity[0],
                prioritizedCapacity: payload.serviceCapacity[1],
            };

        case GetServiceCapacityTypes.GET_SERVICE_CAPACITY_FAILED:
            return {
                ...state,
                fetchingBookingData: false,
            };
    }

    return state;
}
