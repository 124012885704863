import { Box, Card, Typography } from '@mui/material';

import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import HelpModal from '../../../components/Modals/HelpModal/HelpModal';
import { useGetServiceCapacityQuery, useGetSpecialCapacityQuery } from '../../../services/BookingCapacity';
import exampleImage from '../../../assets/eksempel.png';
import { useBookingCapacity } from '../../../state/hooks';
import BookingCalendarContainer from './BookingCalendarContainer/BookingCalendarContainer';
import PrioritizedBookingsDivisionContainer from './PrioritizedBookingsDivisionContainer/PrioritizedBookingsDivisionContainer';
import StandardCapacityContainer from './StandardCapacityContainer';

type Props = {
    activeStoreLocation: string;
};

const BookingCalendarCapacityContainer = ({ activeStoreLocation }: Props) => {
    const timezoneOffset = new Date().getTimezoneOffset();

    const { weekdayCapacity, calculatedWeekdayCapacities, prioritizedCapacityFraction } =
        useBookingCapacity(activeStoreLocation);

    const { data: prioritizedCapacity } = useGetServiceCapacityQuery({
        storeName: activeStoreLocation,
        numberOfMonths: 12,
        brand: ['trek'],
        timezoneOffset,
    });
    const { data: nonPrioritizedCapacity } = useGetServiceCapacityQuery({
        storeName: activeStoreLocation,
        numberOfMonths: 12,
        brand: ['other'],
        timezoneOffset,
    });

    const { data: specialCapacity } = useGetSpecialCapacityQuery({
        storeName: activeStoreLocation,
    });

    return (
        <>
            <Box sx={{ px: { md: 5, xs: 4 } }}>
                {prioritizedCapacity && nonPrioritizedCapacity && specialCapacity ? (
                    <BookingCalendarContainer
                        prioritizedCapacity={prioritizedCapacity}
                        nonPrioritizedCapacity={nonPrioritizedCapacity}
                        activeStoreLocation={activeStoreLocation}
                        weekdayCapacity={weekdayCapacity}
                        calculatedWeekdayCapacities={calculatedWeekdayCapacities}
                        specialCapacity={specialCapacity}
                    />
                ) : (
                    <SubtleSpinner style={{ marginTop: 73 }} text='Henter booking data' />
                )}
            </Box>
            <Typography variant='h2' sx={{ px: { md: 5, xs: 4 }, mt: 6, mb: 2 }}>
                Ukemal
            </Typography>
            <Box sx={{ px: { md: 5, xs: 4 }, mt: 4, mb: 2, display: 'flex', alignItems: 'center' }}>
                <Typography variant='h3'>Reservert kapasitet for prioriterte bestillinger</Typography>
                <HelpModal>
                    <Box sx={{ p: { md: 5, xs: 4 } }}>
                        <Typography variant='h2' sx={{ mb: 2 }}>
                            Reservert kapasitet for prioriterte bestillinger
                        </Typography>
                        <Typography>
                            Her kan du bestemme hvor mye kapasitet som skal reserveres for prioriterte bookinger.
                            Utregningen for kapasitet tar utgangspunkt i total kapasitet for hver dag, enten den er
                            bestemt i ukemalen eller som en spesiell kapasitet.
                        </Typography>
                        <br />
                        <Typography variant='h4'>Eksempel</Typography>
                        <Card sx={{ mt: 2, mb: 4 }}>
                            <img
                                src={exampleImage}
                                alt='Bilde av eksempel på kapasitet.'
                                style={{ maxWidth: '100%' }}
                            />
                        </Card>
                        <Typography>
                            Mandag 12. april har en total kapasitet på <strong>10 plasser</strong>, utregnet fra
                            ukemalen. <strong>60%</strong> av kapasiteten er reservert for prioriterte bookinger.
                        </Typography>
                        <br />
                        <Typography>
                            <strong>Eksempel 1:</strong> her er det 6 bookinger for prioriterte, og 4 bookinger for
                            ikke-prioriterte sykler.
                        </Typography>
                        <Typography>
                            <strong>Eksempel 2:</strong> her er det 5 bookinger for prioriterte, og 4 bookinger for
                            ikke-prioriterte sykler. Den siste ledige plassen er da reservert for en prioritert sykkel.
                        </Typography>
                        <Typography>
                            <strong>Eksempel 3:</strong> her er det 5 bookinger for prioriterte, og 1 booking for
                            ikke-prioriterte sykler. Selv om det er 5 ledige plasser, kan det maksimalt bookes 4
                            ikke-prioriterte sykler siden 60% av plassene er reservert for prioriterte sykler.
                        </Typography>
                    </Box>
                </HelpModal>
            </Box>
            <Box sx={{ px: { md: 5, xs: 4 }, maxWidth: 250 }}>
                {prioritizedCapacityFraction && (
                    <PrioritizedBookingsDivisionContainer
                        prioritizedCapacityFraction={prioritizedCapacityFraction}
                        activeStoreLocation={activeStoreLocation}
                    />
                )}
            </Box>
            <Typography variant='h3' sx={{ px: { md: 5, xs: 4 }, mt: 4, mb: 2 }}>
                Kapasitet i ukedager
            </Typography>
            <Box sx={{ px: { md: 5, xs: 4 } }}>
                <StandardCapacityContainer
                    activeStoreLocation={activeStoreLocation}
                    weekdayCapacity={weekdayCapacity}
                />
            </Box>
        </>
    );
};

export default BookingCalendarCapacityContainer;
