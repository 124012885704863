import React, { ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import SubtleSpinner from '../components/Indicators/SubtleSpinner/SubtleSpinner';

import { FeatureFlag, FeatureFlags } from '../utils/types';

const FeatureFlagsContext = React.createContext({} as FeatureFlags);

type Props = {
    children: ReactNode;
    allowedFlags: FeatureFlag[];
    redirectOnNoFeatureFlag?: boolean;
    exactFlags?: boolean;
};

/**
 * Component to conditionally render children based on feature flags.
 * @param allowedFlags - The required feature flags to render the children
 * @param children - The children to render
 * @param redirectOnNoFeatureFlag - (Optional) If true, the user will be redirected to the closest available feature landing page if they don't have the required feature flags
 */
export const Flags = ({ allowedFlags, children, redirectOnNoFeatureFlag, exactFlags }: Props) => {
    return (
        <FeatureFlagsContext.Consumer>
            {(flags: FeatureFlags) => {
                if (flags) {
                    let match = allowedFlags.every(flag => flags[flag].isActive);
                    if (!exactFlags) match = allowedFlags.some(flag => flags[flag].isActive);
                    if (match) return <>{children}</>;
                    else if (redirectOnNoFeatureFlag) return <NavigateToActiveFeature flags={flags} />;
                } else {
                    return <p>no flags found</p>;
                }
            }}
        </FeatureFlagsContext.Consumer>
    );
};

export const FlagsProvider = ({ value, children }: { value: FeatureFlags; children: ReactNode }) => {
    return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};

const NavigateToActiveFeature = ({ flags }: { flags: FeatureFlags }) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (flags) {
            const activeFeature = Object.keys(flags).find(flag => flags[flag].isActive);
            if (activeFeature) {
                switch (activeFeature) {
                    case 'orderSystem':
                        navigate('/');
                        break;
                    case 'bookingSystem':
                        navigate('/bookinger');
                        break;
                    case 'messageSystem':
                        navigate('/meldinger');
                        break;
                    case 'inventorySystem':
                        navigate('/varelager');
                        break;
                    default:
                        navigate('/');
                }
            }
        }
    }, [flags, navigate]);

    return <SubtleSpinner text='Funksjon ikke aktiv, dirigerer deg videre...' />;
};
