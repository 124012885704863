import { BikeBox, BikeBoxLockEvent } from '../utils/types';
import { api } from './api';

export const bikeBoxesApi = api.injectEndpoints({
    endpoints: builder => ({
        getBikeBoxes: builder.query<BikeBox[], { storeName: string }>({
            query: ({ storeName }) => ({
                url: `/bikeBoxes?storeName=${storeName}`,
                method: 'GET',
            }),
            providesTags: ['BikeBoxes'],
            transformResponse: (response: { result: any; error: any }) => response.result,
        }),
        getBikeBoxById: builder.query<BikeBox, { id: string; storeName: string }>({
            query: ({ id, storeName }) => ({
                url: `/bikeBoxes/${id}?storeName=${storeName}`,
                method: 'GET',
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
            providesTags: ['BikeBoxes'],
        }),
        getBikeboxEventHistory: builder.query<BikeBoxLockEvent[], { id: string }>({
            query: ({ id }) => ({
                url: `/bikeBoxes/${id}/eventHistory`,
                method: 'GET',
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
            providesTags: ['BikeBoxes'],
        }),
    }),
});

export const { useGetBikeBoxesQuery, useGetBikeBoxByIdQuery, useGetBikeboxEventHistoryQuery } = bikeBoxesApi;
