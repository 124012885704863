import {
    Button,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    useMediaQuery,
} from '@mui/material';
import { useState } from 'react';

import { Article } from '../../../models/Article';
import { currencyFormatter } from '../../../utils/format';

export type Props = {
    handleOpenArticleModal: (article: Article) => void;
    results: Article[];
};

const InventorySearchResultsCard = ({ handleOpenArticleModal, results }: Props) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        cursor: 'pointer',
    }));

    return (
        <TableContainer component={Paper}>
            <Table aria-label='orderlines table' stickyHeader={true} sx={{ overflowX: 'auto' }}>
                <TableHead sx={{ '& th': { backgroundColor: '#fff' } }}>
                    <TableRow>
                        {!isMobile && <TableCell>Varenummer</TableCell>}
                        <TableCell width={'auto'}>Varenavn</TableCell>
                        {!isMobile && <TableCell width={50}>Pris</TableCell>}
                        <TableCell width={50}>Disponibel</TableCell>
                        {!isMobile && <TableCell size='small'></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(article => {
                        return (
                            <StyledTableRow key={article.articleId} onClick={() => handleOpenArticleModal(article)}>
                                {!isMobile && <TableCell>{article.articleNo}</TableCell>}
                                <TableCell>{article.articleName}</TableCell>
                                {!isMobile && <TableCell>{currencyFormatter.format(article.grossPrice)},-</TableCell>}
                                <TableCell>{article.stock}</TableCell>
                                {!isMobile && (
                                    <TableCell align='right' size='small'>
                                        <Button size='small'>Vis mer</Button>
                                    </TableCell>
                                )}
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={results.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Rader per side'
            />
        </TableContainer>
    );
};

export default InventorySearchResultsCard;
