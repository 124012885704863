import { collection, doc, updateDoc, getFirestore } from 'firebase/firestore';
import app from '../../../configs/firebaseConfig';
import { createFailedAction } from '../../../utils/helpers';
import { AppThunk } from '../../reduxTypes';
import { getServiceCapacity } from './GetServiceCapacity';

export const SET_PRIORITIZED_FRACTION_STARTED = 'SET_PRIORITIZED_FRACTION_STARTED';
export const SET_PRIORITIZED_FRACTION_SUCCESS = 'SET_PRIORITIZED_FRACTION_SUCCESS';
export const SET_PRIORITIZED_FRACTION_FAILED = 'SET_PRIORITIZED_FRACTION_FAILED';

export const setPrioritizedFraction =
    (storeName: string, prioritizedFraction: number): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch({ type: SET_PRIORITIZED_FRACTION_STARTED });
        try {
            const db = getFirestore(app);
            const bookingsRef = collection(db, 'bookings');
            const bookingRef = doc(bookingsRef, storeName);
            await updateDoc(bookingRef, {
                prioritizedCapacityFraction: prioritizedFraction / 100,
            });
            dispatch({ type: SET_PRIORITIZED_FRACTION_SUCCESS });
            dispatch(getServiceCapacity());
        } catch (error) {
            const errorHandler = createFailedAction(SET_PRIORITIZED_FRACTION_FAILED);
            dispatch(errorHandler(error));
        }
    };
