import { sortFutureOrders, sortOnDeliveryDate } from '../../../utils/sortsAndFilters';
import { FutureOrders } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';
import { getOrderTemplatesSuccess } from './GetOrderTemplates';
import { authFetch, createFailedAction, extractMetaDataFromOrders } from '../../../utils/helpers';
import { clearErrorByCode } from '../error/handleErrors';
import Order from '../../../models/Order';

export const GET_ORDERS_WITHOUT_LOADING = 'GET_ORDERS_WITHOUT_LOADING';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';
export const STOP_GET_ORDERS = 'STOP_GET_ORDERS';
export const STORE_UNSORTED_FUTURE_ORDERS = 'STORE_UNSORTED_FUTURE_ORDERS';

const getOrders = () => ({
    type: GET_ORDERS,
});
const getOrdersWithoutLoading = () => ({
    type: GET_ORDERS_WITHOUT_LOADING,
});

const getOrdersSuccess = (orders: FutureOrders) => ({
    type: GET_ORDERS_SUCCESS,
    payload: { orders },
});
const getOrdersFailed = createFailedAction(GET_ORDERS_FAILED);

const stopGetBasicOrders = () => ({
    type: STOP_GET_ORDERS,
});
const storeUnsortedOrders = (orders: Order[]) => ({
    type: STORE_UNSORTED_FUTURE_ORDERS,
    payload: { orders },
});

let timer: any = null;
export const startGetFutureOrders = (): AppThunk => dispatch => {
    clearInterval(timer);

    dispatch(getFutureOrders());

    timer = setInterval(() => {
        dispatch(getOrdersWithoutLoading());
        dispatch(getFutureOrders(false));
    }, 50000);
};

export const stopGetFutureOrders = (): AppThunk => dispatch => {
    clearInterval(timer);
    dispatch(stopGetBasicOrders());
};

export const getFutureOrders =
    (loading: boolean = true): AppThunk =>
    async (dispatch, getState, fb) => {
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        if (loading) dispatch(getOrders());

        try {
            const responses = await Promise.all([
                authFetch(fb, '/orders', 'GET', undefined, apiUrl),
                authFetch(fb, '/orders?type=service', 'GET', undefined, apiUrl),
            ]);

            const customerOrders: object[] = await responses[0].json();
            let serviceOrders: object[] = await responses[1].json();

            // Temporary adding template id to bookings
            serviceOrders = serviceOrders.map(order => {
                return {
                    ...order,
                    templateId: 1,
                    templateName: 'V Mottak',
                };
            });

            let allOrders: Order[] = [...serviceOrders, ...customerOrders].map(raw => new Order(raw));

            allOrders = allOrders.filter(o => o.deliveryDate); // Temporary remove orders witout delivery dates (webshop orders)

            allOrders.sort(sortOnDeliveryDate);

            // Divide orders into a 2D array like a calendar
            const sortedOrders = sortFutureOrders(allOrders);

            const [templates] = extractMetaDataFromOrders(allOrders);

            dispatch(getOrdersSuccess(sortedOrders));
            dispatch(storeUnsortedOrders(allOrders));
            dispatch(getOrderTemplatesSuccess(Object.values(templates)));
            dispatch(clearErrorByCode(GET_ORDERS_FAILED));
        } catch (error) {
            dispatch(getOrdersFailed(error));
        }
    };
