import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _isEqual from 'lodash/isEqual';

import { WeekdayCapacity } from '../../../utils/types';
import StandardCapacityForm from '../../../components/Form/StandardCapacityForm/StandardCapacityForm';
import { setWeekdayCapacity } from '../../../state/ducks/bookings/SetStandardCapacity';

type Props = {
    activeStoreLocation: string;
    weekdayCapacity: WeekdayCapacity;
};

const StandardCapacityContainer = ({ activeStoreLocation, weekdayCapacity }: Props) => {
    const dispatch = useDispatch();
    const [localWeekdayCapacity, setLocalWeekdayCapacity] = useState<WeekdayCapacity>([]);
    const [isPristine, setIsPristine] = useState(true);

    useEffect(() => {
        if (weekdayCapacity) {
            setLocalWeekdayCapacity(weekdayCapacity);
        }
    }, [weekdayCapacity]);

    useEffect(() => {
        const x = _isEqual(localWeekdayCapacity, weekdayCapacity);
        setIsPristine(x);
    }, [localWeekdayCapacity, weekdayCapacity]);

    const handleChange = (id: number, value: number) => {
        if (localWeekdayCapacity) {
            let temp = [...localWeekdayCapacity];
            let index = temp.findIndex((cap, i) => i === id);
            if (index >= 0) {
                temp[index] = Number(value);
                setLocalWeekdayCapacity(temp);
            }
        }
    };

    const handleSave = () => {
        if (localWeekdayCapacity) dispatch(setWeekdayCapacity(localWeekdayCapacity, activeStoreLocation));
    };

    return (
        <>
            {weekdayCapacity && (
                <StandardCapacityForm
                    capacity={localWeekdayCapacity}
                    settingStandardCapacity={false}
                    isPristine={isPristine}
                    handleChange={handleChange}
                    handleSave={handleSave}
                />
            )}
        </>
    );
};

export default StandardCapacityContainer;
