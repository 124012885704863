import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SubtleSpinner from '../components/Indicators/SubtleSpinner/SubtleSpinner';

import WithSidebarLayout from '../components/Layouts/WithSidebarLayout/WithSidebarLayout';
import ErrorBoundary from '../containers/App/ErrorBoundaryContainer/ErrorBoundaryContainer';
import SidebarContainer from '../containers/Generic/SidebarContainer/SidebarContainer';
import { FlagsProvider } from '../contexts/FeatureFlagsContext';
import { getApiUrl } from '../state/ducks/system/GetAPIUrl';
import { useFeatureFlags } from '../state/hooks';

type Props = {
    children: ReactNode;
};

const Main = ({ children }: Props) => {
    const featureFlags = useFeatureFlags();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getApiUrl());
    }, [dispatch]);
    if (featureFlags === undefined) return <SubtleSpinner text='Laster...' />;
    return (
        <ErrorBoundary>
            <FlagsProvider value={featureFlags}>
                <WithSidebarLayout>
                    <SidebarContainer />
                    {children}
                </WithSidebarLayout>
            </FlagsProvider>
        </ErrorBoundary>
    );
};

export default Main;
