import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        padding: theme.spacing(4),
        maxWidth: 500,
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    placementText: {
        padding: '6px 10px',
        backgroundColor: theme.palette.success.main,
        color: '#fff',
        borderRadius: '8px',
        display: 'inline-block',
        fontSize: '1.5em',
        fontWeight: 600,
    },
    locationsBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
