import { AppThunk } from '../../reduxTypes';
import { FutureOrders } from '../../../utils/types';
import { setSelectedTemplate } from './SetSelectedTemplate';
import Order from '../../../models/Order';
import PhoneNumber from '../../../models/PhoneNumber';

export const QUERY_SEARCH = 'START_SEARCH';
export const QUERY_SEARCH_RESULTS = 'QUERY_SEARCH_RESULTS';
export const RESET_SEARCH = 'RESET_SEARCH';

const querySearch = (query: string) => ({
    type: QUERY_SEARCH,
    payload: { query },
});
const querySearchResults = (results: FutureOrders) => ({
    type: QUERY_SEARCH_RESULTS,
    payload: { results },
});
export const resetSearch = () => ({
    type: RESET_SEARCH,
});

export const search =
    (rawQuery: string): AppThunk =>
    (dispatch, getState) => {
        dispatch(querySearch(rawQuery));
        dispatch(setSelectedTemplate(0)); // Make shure selected template is reset

        const calendar = getState().pck.futureOrders;

        if (calendar) {
            const query = rawQuery.toLowerCase().replace(/\s/g, ''); // Clean query
            const results: FutureOrders = []; // Create empty results list

            calendar.forEach((day: Order[], dayIndex: number) => {
                day.forEach((order: Order) => {
                    results.push([]);

                    const orderSearchProps = [
                        order?.customer?.customerName,
                        order.orderId,
                        order?.customer?.phoneNumber1,
                        order?.customer?.phoneNumber2,
                        order.labled,
                        order.theirRef,
                    ];

                    for (const prop of orderSearchProps) {
                        if (prop) {
                            let cleanedProp: string;

                            if (prop instanceof PhoneNumber) {
                                cleanedProp = prop.get();
                            } else {
                                cleanedProp = prop.toString().toLowerCase().replace(/\s/g, '');
                            }

                            // If match is found, add to results
                            if (cleanedProp.includes(query)) {
                                results[dayIndex].push(order);
                                break;
                            }
                        }
                    }
                });
            });

            dispatch(querySearchResults(results));
        }
    };
