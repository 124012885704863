import { Paper, Fade, CircularProgress, Button, Box, Typography } from '@mui/material';

import useStyles from './StandardCapacityForm.styles';
import { WeekdayCapacity } from '../../../utils/types';
import { getWeekDays, moveFirstArrayElementToEnd, moveLastArrayElementToStart } from '../../../utils/helpers';
import InputField from '../InputField/InputField';
import { ChangeEvent } from 'react';

export type Props = {
    capacity: WeekdayCapacity;
    settingStandardCapacity: boolean;
    isPristine: boolean;
    handleChange: (id: number, value: number) => void;
    handleSave: () => void;
};

const StandardCapacityForm = ({ capacity, settingStandardCapacity, isPristine, handleChange, handleSave }: Props) => {
    const { classes } = useStyles();

    const weekdays = moveLastArrayElementToStart(getWeekDays());

    const handleChangeCapacity = (event: ChangeEvent<{ value: unknown; id: string; type: string }>, id: number) => {
        const value = Number(event.target.value);
        if (value >= 0) handleChange(id, value);
    };

    const capacityElements = capacity.map((cap, i) => (
        <div className={classes.inputBox} key={i + '_capacity'}>
            <div className={classes.gridInputBox}>
                <Typography variant='subtitle2' className={classes.gridTitle}>
                    {weekdays[i]}
                </Typography>
                <InputField
                    id={'weekday' + 1}
                    value={capacity[i]}
                    type='number'
                    placeholder='Kapasitet'
                    onChange={(event: ChangeEvent<{ value: unknown; id: string; type: string }>) =>
                        handleChangeCapacity(event, i)
                    }
                />
            </div>
        </div>
    ));

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.gridContainer}>
                    {/* <Box>
                        <Typography>Ukedag</Typography>
                        <Typography>Antall</Typography>
                    </Box> */}
                    {capacity && moveFirstArrayElementToEnd(capacityElements)}
                </div>
            </Paper>

            {!isPristine && (
                <Fade in={true}>
                    <Box display='flex' justifyContent='flex-end' alignContent='center' marginTop={1}>
                        <>
                            {settingStandardCapacity && (
                                <CircularProgress style={{ marginRight: 10, alignSelf: 'center' }} size={20} />
                            )}
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={settingStandardCapacity}
                                style={{ minWidth: 100 }}
                                onClick={handleSave}
                            >
                                {!settingStandardCapacity ? 'Lagre' : 'Lagrer...'}
                            </Button>
                        </>
                    </Box>
                </Fade>
            )}
        </div>
    );
};

export default StandardCapacityForm;
