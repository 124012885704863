import { Box, Typography } from '@mui/material';
import useStyles from './IndexRouteMessage.styles';

export type Props = {
    message: string;
};

const IndexRouteMessage = ({ message }: Props) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant='h3'>{message}</Typography>
        </Box>
    );
};

export default IndexRouteMessage;
