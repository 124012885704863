import { makeStyles } from '../../../state/hooks';

type Props = {
    label: number | string;
    classNames?: string;
};

const SmallNotification = ({ label, classNames = '' }: Props) => {
    const { classes, cx } = useStyles();
    return <span className={cx(classes.notificationBubble, classNames)}>{label}</span>;
};

const useStyles = makeStyles()(theme => ({
    notificationBubble: {
        display: 'inline-block',
        backgroundColor: theme.palette.error.main,
        borderRadius: 20,
        textAlign: 'center',
        fontWeight: 480,
        height: 20,
        lineHeight: '20px',
        color: 'white',
        padding: '2px 9px',
        fontSize: '0.7em',

        animationName: '$glowing',
        animationDuration: '4s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
    },
    '@keyframes glowing': {
        '0%': { backgroundColor: theme.palette.error.main },
        '50%': { backgroundColor: theme.palette.error.light },
        '100%': { backgroundColor: theme.palette.error.main },
    },
}));

export default SmallNotification;
