import algoliasearch from 'algoliasearch/lite';

import { ConversationSearchResults } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';
import Conversation from '../../../models/Conversation';
import { createFailedAction } from '../../../utils/helpers';

export const CONVERSATION_SEARCH_STARTED = 'CONVERSATION_SEARCH_STARTED';
export const CONVERSATION_SEARCH_SUCCESS = 'CONVERSATION_SEARCH_SUCCESS';
export const CONVERSATION_SEARCH_FAILED = 'CONVERSATION_SEARCH_FAILED';
export const CLEAR_CONVERSATION_SEARCH_RESULTS = 'CLEAR_CONVERSATION_SEARCH_RESULTS';
export const FLAG_SEARCH_RESULT_CONVERSATION = 'FLAG_SEARCH_RESULT_CONVERSATION';

const conversationSearchStarted = () => ({
    type: CONVERSATION_SEARCH_STARTED,
});
const conversationSearchSuccess = (results: ConversationSearchResults) => ({
    type: CONVERSATION_SEARCH_SUCCESS,
    payload: { results },
});
const conversationSearchFailed = createFailedAction(CONVERSATION_SEARCH_FAILED);

export const clearConversationSearchResults = () => ({
    type: CLEAR_CONVERSATION_SEARCH_RESULTS,
});

// Set the flag in local redux state (if possible)
export const flagSearchResultConversation = (customerNumber: string) => ({
    type: FLAG_SEARCH_RESULT_CONVERSATION,
    payload: { customerNumber },
});

export const conversationSearch =
    (query: string): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(conversationSearchStarted());

        try {
            const keys = await (await fb().firestore().collection('keys').doc('algolia').get()).data();

            if (keys?.algoliaAppId && keys?.algoliaSearchKey) {
                const client = algoliasearch(keys.algoliaAppId, keys.algoliaSearchKey); // setup client
                const index = client.initIndex('customers'); // index

                const results: any = await index.search(query);
                if (results?.hits) {
                    results.hits = results.hits.map((hit: any) => new Conversation(hit));
                }
                dispatch(conversationSearchSuccess(results));
            } else throw new Error('Could not find algolia app and search keys');
        } catch (error) {
            dispatch(conversationSearchFailed(error));
        }
    };
