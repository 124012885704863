import { useState } from 'react';
import { AppBar, Box, Button, Paper, Theme, Typography, useMediaQuery } from '@mui/material';
import { ArrowLeftCircle } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';

import { versionNumber } from '../../../utils/constants';
import OrdersNotificationContainer from '../../../containers/PageSpecific/FutureOrders/OrdersNotificationContainer/OrdersNotificationContainer';
import SidebarLinkContainer from '../../../containers/Generic/SidebarLinkContainer/SidebarLinkContainer';
import MobileNavbar from '../MobileNavbar/MobileNavbar';
import Logo from '../../Graphics/Logo/Logo';
import useStyles from './Sidebar.styles';
import { Flags } from '../../../contexts/FeatureFlagsContext';
import ActiveStoreLocationContainer from '../../../containers/Generic/ActiveStoreLocationContainer/ActiveStoreLocationContainer';

export type Props = {
    criticalNotification: number;
    conversationNotification: number;
    flaggedNotification: number;
    handleLogout: () => void;
};

const Sidebar = ({ criticalNotification, conversationNotification, flaggedNotification, handleLogout }: Props) => {
    const { classes } = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);

    function toggleMobileDrawer() {
        if (isMobile) setOpen(prev => !prev);
    }

    const rootRegex = new RegExp('/$');

    const links = (
        <>
            <div>
                {!isMobile && (
                    <Link to={'/'}>
                        <Box sx={{ pt: '10px', pb: '15px', margin: 'auto', maxWidth: '80%' }}>{<Logo />}</Box>
                    </Link>
                )}
                <div className={classes.links}>
                    <Flags allowedFlags={['orderSystem', 'bookingSystem']}>
                        <OrdersNotificationContainer
                            link='/'
                            text='Ordre'
                            isActive={rootRegex.test(pathname)}
                            notificationColor='error'
                            onClick={toggleMobileDrawer}
                        />
                    </Flags>
                    <Flags allowedFlags={['orderSystem']}>
                        <SidebarLinkContainer
                            link='/oppfolging'
                            text='Oppfølging'
                            isActive={pathname.includes('/oppfolging')}
                            notifications={[{ count: criticalNotification }]}
                            onClick={toggleMobileDrawer}
                        />
                    </Flags>
                    <Flags allowedFlags={['bookingSystem']}>
                        <SidebarLinkContainer
                            link='/bookinger'
                            text='Bookinger'
                            isActive={pathname.includes('/bookinger')}
                            onClick={toggleMobileDrawer}
                        />
                    </Flags>
                    <Flags allowedFlags={['messageSystem']}>
                        <SidebarLinkContainer
                            link='/meldinger'
                            text='Meldinger'
                            isActive={pathname.includes('/meldinger')}
                            notifications={[
                                { count: flaggedNotification, color: 'warning' },
                                { count: conversationNotification, color: 'error' },
                            ]}
                            onClick={toggleMobileDrawer}
                        />
                    </Flags>
                    <Flags allowedFlags={['orderSystem']}>
                        <SidebarLinkContainer
                            link='/statistikk'
                            text='Statistikk'
                            isActive={pathname.includes('/statistikk')}
                            onClick={toggleMobileDrawer}
                        />
                    </Flags>
                    <Flags allowedFlags={['inventorySystem']}>
                        <SidebarLinkContainer
                            link='/varelager'
                            text='Varelager'
                            isActive={pathname.includes('/varelager')}
                            onClick={toggleMobileDrawer}
                        />
                    </Flags>
                </div>
            </div>
            <Box sx={{ mt: 4 }}>
                <ActiveStoreLocationContainer />
                <Button
                    onClick={handleLogout}
                    sx={{ mb: 1, minWidth: 0 }}
                    variant='text'
                    fullWidth
                    startIcon={<ArrowLeftCircle />}
                >
                    {'Logg ut'}
                </Button>
                <Typography className={classes.version} variant='body2'>
                    Versjon {versionNumber}
                </Typography>
            </Box>
        </>
    );

    return (
        <>
            {isMobile ? (
                <AppBar className={classes.mobileMenu}>
                    <MobileNavbar open={open} toggleModal={toggleMobileDrawer} logo={<Logo />}>
                        {links}
                    </MobileNavbar>
                </AppBar>
            ) : (
                <Paper component={'aside'} className={classes.root}>
                    <div className={classes.sidebar}>{links}</div>
                </Paper>
            )}
        </>
    );
};

export default Sidebar;
