import { Box, Paper, Typography } from '@mui/material';
import PopoverPromptButton from '../../Buttons/PopoverPromptButton/PopoverPromptButton';
import Axis from '../../Layouts/Axis/Axis';
import Column from '../../Layouts/Column/Column';
import useStyles from './InventoryLocationCard.styles';

export type Props = {
    locationId: number;
    placementId: number | null;
    sizeColorId: number | null;
    locationName: string;
    articleName: string;
    count: number;
    handleMoveArticle: VoidFunction;
    handleRemoveArticle: (locationId: number, placementId: number | null, sizeColorId: number | null) => void;
};

const InventoryLocationCard = ({
    locationId,
    placementId,
    sizeColorId,
    locationName,
    articleName,
    count,
    handleMoveArticle,
    handleRemoveArticle,
}: Props) => {
    const { classes } = useStyles();

    return (
        <Paper className={classes.root}>
            <Column gap={3}>
                <Box className={classes.textBox}>
                    <Typography variant='h5'>{locationName}</Typography>
                    <Typography variant='body2'>Disponibel: {count}</Typography>
                </Box>
                <Axis direction='column' gap={1}>
                    <PopoverPromptButton
                        buttonProps={{ variant: 'contained' }}
                        buttonText='Ta fra lager'
                        popoverTitle='Ta vare fra lager?'
                        confirmText='Ta vare'
                        onConfirm={() => handleRemoveArticle(locationId, placementId, sizeColorId)}
                    >
                        <Typography>
                            Varen “{articleName}” blir fjernet fra plasseringen "{locationName}".
                        </Typography>
                    </PopoverPromptButton>
                    {/* <Button variant='outlined' onClick={handleMoveArticle}>
                        Flytt varer
                    </Button> */}
                </Axis>
            </Column>
        </Paper>
    );
};

export default InventoryLocationCard;
