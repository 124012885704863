import { AppThunk } from '../../reduxTypes';
import Order from '../../../models/Order';

export const SET_OPEN_ORDER = 'SET_OPEN_ORDER';

const setOpenOrder = (order: Order) => ({
    type: SET_OPEN_ORDER,
    payload: { order },
});

export const openOrder =
    (orderId: number, isFutureOrder = true, unsortedOrders?: Order[]): AppThunk =>
    (dispatch, getState) => {
        let orders: Order[] | null = [];

        if (isFutureOrder && unsortedOrders) {
            orders = [...unsortedOrders];
        }

        if (orders) {
            const order = orders.find(item => item.orderId === orderId);
            if (order) dispatch(setOpenOrder(order));
            else console.log('Could not find order, ' + orderId);
        }
    };
