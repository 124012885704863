import { makeStyles } from '../../../state/hooks';
import constants from '../../../styles/styleConstants';

export default makeStyles()(theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: 50,
        borderRadius: 8,
        border: '2px solid ' + theme.palette.neutrals[200],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        cursor: 'default',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
        },
        ['& > p']: {
            textDecoration: 'line-through',
            color: theme.palette.neutrals[300],
        },
    },
    available: {
        ['& > p']: {
            textDecoration: 'none',
            color: theme.palette.neutrals[600],
        },
        ['& :nth-of-type(2)']: {
            color: theme.palette.success.main,
            fontSize: '0.75rem',
        },
    },
    interactable: {
        cursor: 'pointer',
    },
    selected: {
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        ['& > p']: {
            color: 'white !important',
        },
    },
    rangeSelected: {
        border: '2px solid ' + theme.palette.primary.main,
    },
    blank: {
        backgroundColor: theme.palette.neutrals[200],
        border: 'none',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.neutrals[200],
        },
    },
    hidden: {
        opacity: 0,
    },
}));
