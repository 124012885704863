import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';

import { forceTextLength } from '../../../utils/format';

export type Props = {
    options: { id: number; value: string }[];
    activeOptions: number[] | null;
    label: string;
    name: string;
    defaultValueName: string;
    handleChange: (event: SelectChangeEvent<number[]>) => void;
    maxTextLength?: number;
};

const MultipleSelectDropdown = ({
    options,
    activeOptions,
    label,
    name,
    defaultValueName,
    maxTextLength = 26,
    handleChange,
}: Props) => {
    // Force override how Select renders the selected values because we use checkboxes inside it.
    const renderValue = (value: number[]) => {
        if (value.length === 0) return <p style={{ margin: 0 }}>{defaultValueName}</p>;
        const articleGroupNames = value.map(v => options.find(articleGroup => articleGroup.id === v)?.value);
        const str = articleGroupNames.join(', ');
        return <>{forceTextLength(str, maxTextLength)}</>; // Temporary hardcoded fixed text length
    };

    const sortedOptions = useMemo(() => {
        return options.sort((a, b) => a.value.localeCompare(b.value, 'nb'));
    }, [options]);

    return (
        <FormControl fullWidth>
            <InputLabel id={name + '_label'}>{label}</InputLabel>
            <Select
                labelId={name + '_label'}
                name={name}
                value={activeOptions ?? []}
                label={label}
                onChange={handleChange}
                multiple
                data-cy={name + '_dropdown'}
                renderValue={renderValue}
            >
                {sortedOptions.map(option => {
                    return (
                        <MenuItem key={option.id} value={option.id}>
                            <Checkbox checked={activeOptions === null ? false : activeOptions.includes(option.id)} />
                            <ListItemText primary={option.value} />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default MultipleSelectDropdown;
