import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(3),
    },
    button: {
        width: '100%',
    },

    title: {
        marginBottom: theme.spacing(2),
        textAlign: 'left',
    },
    labelBox: {
        display: 'flex',
        alignItems: 'baseline',
    },
}));
