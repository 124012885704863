import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { useSelector } from '../../../state/hooks';
import Main from '../../../views/Main';

type Props = {
    children: ReactNode;
};

const AuthContainer = ({ children }: Props) => {
    const auth = useSelector(state => state.firebase.auth);
    const location = useLocation();

    const showSpinner = !auth.isLoaded && auth.isEmpty;

    if (showSpinner) return <SubtleSpinner style={{ marginTop: 50 }} />;

    if (auth.isEmpty) return <Navigate to='/logg-inn' state={{ from: location }} replace />;
    else return <Main>{children}</Main>;
};

export default AuthContainer;
