import { capitalizeText } from '../utils/format';
import PhoneNumber from './PhoneNumber';

/**
 * Model that represents a Customer.
 */
export default class Customer {
    customerName: string;
    customerNumber: string;
    phoneNumber1: PhoneNumber | null;
    phoneNumber2: PhoneNumber | null;

    constructor(raw: any) {
        this.customerName = raw?.customerName ? capitalizeText(raw.customerName) : 'No name';
        this.customerNumber = raw?.customerNumber?.toString() ?? raw?.id?.toString();
        this.phoneNumber1 = raw?.phoneNumber1 ? new PhoneNumber(raw.phoneNumber1) : null;
        this.phoneNumber2 = raw?.phoneNumber2 ? new PhoneNumber(raw.phoneNumber2) : null;
    }

    /**
     * Get all the phone numbers of the conversation (all that are not null)
     * @returns list of phone number instance or null if none are found
     */
    getPhoneNumbers(): PhoneNumber[] | null {
        const nums = [];
        if (this.phoneNumber1) nums.push(this.phoneNumber1);
        if (this.phoneNumber2) nums.push(this.phoneNumber2);
        return nums.length > 0 ? nums : null;
    }

    /**
     * Returns first phone number of the conversation
     * @returns first phone number that is not null. Null if none are present
     */
    getFirstPhoneNumber(): PhoneNumber | null {
        if (this.phoneNumber1) return this.phoneNumber1;
        if (this.phoneNumber2) return this.phoneNumber2;
        return null;
    }

    /**
     * Compare to customers by their customer number (id).
     * @param obj {any} object this instance is to be compared to
     * @returns {boolean} true if equal, false if not
     */
    equals(obj: any): boolean {
        return obj instanceof Customer && this.customerNumber === obj.customerNumber;
    }
}
