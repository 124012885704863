import { Box, Button, CircularProgress, FormControl, FormHelperText, TextField, Typography } from '@mui/material';
import { differenceInDays, format, subDays } from 'date-fns';
import { nb } from 'date-fns/locale';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';

import BikeBoxCalendar from '../../../../../components/Extras/BikeBoxCalendar/BikeBoxCalendar';
import NewReservationForm from '../../../../../components/Form/NewReservationForm/NewReservationForm';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import Column from '../../../../../components/Layouts/Column/Column';
import Modal from '../../../../../components/Modals/Modal/Modal';
import SmallLabel from '../../../../../components/Typography/SmallLabel/SmallLabel';
import { useGetBikeBoxAvailabilityByIdQuery } from '../../../../../services/BikeBoxAvailability';
import { useCreateBikeBoxReservationMutation } from '../../../../../services/BikeBoxReservation';
import { calculateBikeBoxAvailability, calculateReservationStartDate } from '../../../../../utils/helpers';
import { NewBikeBoxReservationFormData } from '../../../../../utils/types';

type Props = {
    openModal: boolean;
    bikeBoxId: string;
    activeStoreLocation: string;
    toggleModal: () => void;
    month: number;
    defaultValues?: Partial<NewBikeBoxReservationFormData>;
};

const NewReservationFormContainer = ({
    openModal,
    bikeBoxId,
    activeStoreLocation,
    toggleModal,
    month,
    defaultValues,
}: Props) => {
    const [currentMonth, setCurrentMonth] = useState(month);
    const { handleSubmit, control, setValue } = useForm<NewBikeBoxReservationFormData>({
        defaultValues: {
            countryCode: '+47',
            ...defaultValues,
        },
    });
    const [
        createBikeBoxReservation,
        { isLoading: loadingCreateReservation, isSuccess: createReservationSuccess, data },
    ] = useCreateBikeBoxReservationMutation();

    const [reservationDuration, setReservationDuration] = useState(2);

    const { data: reservationAvailability, isLoading: loadingAvailability } = useGetBikeBoxAvailabilityByIdQuery({
        id: bikeBoxId,
        storeName: activeStoreLocation,
    });

    const isLoading = loadingAvailability || loadingCreateReservation;

    const [fromDate, toDate] = useWatch({
        control,
        name: ['fromDate', 'toDate'],
    });

    const bikeBoxAvailability = useMemo(() => {
        if (!reservationAvailability) return [];
        const result = calculateBikeBoxAvailability(
            toDate,
            reservationDuration - 1,
            reservationAvailability,
            currentMonth
        );
        return result;
    }, [reservationAvailability, fromDate, toDate, reservationDuration]);

    const handleDateClicked = (date: Date) => {
        setValue('toDate', date.toISOString());
    };

    useEffect(() => {
        if (toDate && reservationDuration && reservationAvailability) {
            const duration = reservationDuration - 1;
            const calculatedFromDate = subDays(new Date(toDate), duration);
            const result = calculateReservationStartDate(
                calculatedFromDate,
                new Date(toDate),
                reservationAvailability,
                currentMonth
            );
            setValue('fromDate', result);
        }
    }, [reservationDuration, toDate]);

    useEffect(() => {
        if (createReservationSuccess && data) {
            toggleModal();
        }
    }, [createReservationSuccess]);

    const onChangeReservationDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const num = parseInt(value);
        if (num < 2) {
            setReservationDuration(1);
        } else {
            setReservationDuration(num);
        }
    };

    const onValidSubmit: SubmitHandler<NewBikeBoxReservationFormData> = (data: NewBikeBoxReservationFormData) => {
        const duration = differenceInDays(new Date(data.toDate), new Date(data.fromDate));
        createBikeBoxReservation({
            bikeBoxReservation: {
                bikeBoxId: parseInt(bikeBoxId),
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.countryCode + data.phoneNumber,
                requestedDate: data.toDate,
                reservationDuration: duration,
            },
            storeName: activeStoreLocation,
        });
    };

    return (
        <Modal maxWidth={'lg'} openModal={openModal} toggleModal={toggleModal}>
            <Box sx={{ p: 5 }}>
                <Typography variant='h3'>Ny reservasjon</Typography>
                <Box sx={{ mt: 5 }}>
                    <Column gap={3}>
                        <NewReservationForm control={control} isLoading={isLoading} />
                        <Box>
                            <SmallLabel text={'Fra-dato'} />
                            <Typography variant='body1'>
                                {fromDate ? format(new Date(fromDate), 'EEEE do MMMM yyyy', { locale: nb }) : '-'}
                            </Typography>
                        </Box>
                        <Box>
                            <SmallLabel text={'Til-dato'} />
                            <Typography variant='body1'>
                                {toDate ? format(new Date(toDate), 'EEEE do MMMM yyyy', { locale: nb }) : '-'}
                            </Typography>
                        </Box>
                        {bikeBoxAvailability.length > 0 ? (
                            <BikeBoxCalendar
                                disabled={isLoading}
                                currentMonth={currentMonth}
                                setCurrentMonth={setCurrentMonth}
                                reservationAvailability={bikeBoxAvailability}
                                onDateClick={handleDateClicked}
                            />
                        ) : (
                            <SubtleSpinner />
                        )}
                        <Box>
                            <FormControl>
                                <TextField
                                    error={isNaN(reservationDuration)}
                                    disabled={isLoading}
                                    label='Reservasjon varighet'
                                    placeholder='"2"'
                                    type={'number'}
                                    variant='outlined'
                                    autoComplete='off'
                                    value={reservationDuration.toString()}
                                    onChange={onChangeReservationDuration}
                                />
                                {isNaN(reservationDuration) && (
                                    <FormHelperText error>Du må velge en gyldig varighet</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button disabled={isLoading} variant='text' onClick={toggleModal} sx={{ marginRight: 2 }}>
                                Avbryt
                            </Button>
                            <Button
                                startIcon={loadingCreateReservation ? <CircularProgress size={16} /> : undefined}
                                disabled={isLoading}
                                variant='contained'
                                onClick={handleSubmit(onValidSubmit)}
                            >
                                {loadingCreateReservation ? 'Sender reservasjon...' : 'Send reservasjon'}
                            </Button>
                        </Box>
                    </Column>
                </Box>
            </Box>
        </Modal>
    );
};

export default NewReservationFormContainer;
