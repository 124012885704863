import { useState, useEffect } from 'react';
import { Button, MenuItem, SelectChangeEvent } from '@mui/material';

import SelectField from '../SelectField/SelectField';
import useStyles from './SegmentedControl.styles';

interface Option {
    id: number;
    name: string;
}

export interface Props {
    shortcuts: number[];
    selected: number;
    options: Option[] | null;
    handleSelect: (id: number) => void;
    startShortcutOption?: Option;
}

/**
 * Reusable generic segmented controls (iOS style) component
 */
const SegmentedControl = ({ selected, shortcuts, options, handleSelect, startShortcutOption }: Props) => {
    const hasDropdown = shortcuts?.length !== options?.length;
    const [listSelected, setListSelected] = useState(0);
    const { classes, cx } = useStyles();

    useEffect(() => {
        if (listSelected !== selected) setListSelected(0);
    }, [selected, listSelected]);

    function handleChange(event: SelectChangeEvent) {
        const selectedOption = event.target.value;

        // If the selected option choosen from the dropdown is in the shortcuts reset the dropdown
        if (shortcuts.find(shortcut => shortcut.toString() === selectedOption)) {
            setListSelected(0);
        } else {
            setListSelected(Number(selectedOption));
        }
        handleSelect(Number(selectedOption));
    }

    let shortcutList: Option[] = [];

    if (options && options?.length) {
        shortcutList = options.filter(option => shortcuts.includes(option.id));
    }

    if (startShortcutOption && shortcuts.length) shortcutList.unshift(startShortcutOption);

    return (
        <div>
            <div className={classes.track} data-cy='segmented-control'>
                {shortcutList.map(option => (
                    <span key={option.id}>
                        <Button
                            key={option.name + 'Button'}
                            className={cx(classes.button, selected === option.id ? classes.active : '')}
                            htmlFor={option.name + '_label'}
                            component='label'
                            onClick={() => handleSelect(option.id)}
                            disableTouchRipple
                        >
                            {option.name}
                        </Button>
                        <input
                            type='radio'
                            value={option.id}
                            id={option.id.toString()}
                            checked={selected === option.id}
                            className={classes.input}
                            readOnly
                        />
                    </span>
                ))}
                {hasDropdown && options && (
                    <SelectField handleChange={handleChange} selected={listSelected.toString()} dense={true}>
                        {options.map(ordre => (
                            <MenuItem
                                key={ordre.id + 'listItem'}
                                value={ordre.id}
                                className={ordre.id === 0 ? classes.firstMenuItem : undefined}
                            >
                                {ordre.name}
                            </MenuItem>
                        ))}
                    </SelectField>
                )}
            </div>
        </div>
    );
};

export default SegmentedControl;
