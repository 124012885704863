import { ReactNode } from 'react';
import { makeStyles } from '../../../state/hooks';

type Props = {
    children: ReactNode;
};

export default function OverlayStack({ children }: Props) {
    const { classes } = useStyles();
    return <div className={classes.root}>{children}</div>;
}

const useStyles = makeStyles()({
    root: {
        maxWidth: '80%',
        zIndex: 2000,
        position: 'absolute',
        top: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});
