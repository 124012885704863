import Order from '../../../models/Order';
import { AppThunk } from '../../reduxTypes';
import { openOrder } from '../pck/SetOpenedOrder';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const OPEN_BOOKING_MODAL = 'OPEN_BOOKING_MODAL';
export const CLOSE_BOOKING_MODAL = 'CLOSE_BOOKING_MODAL';

export const OPEN_NEW_CONVERSATION_MODAL = 'OPEN_NEW_CONVERSATION_MODAL';
export const CLOSE_NEW_CONVERSATION_MODAL = 'CLOSE_NEW_CONVERSATION_MODAL';

export const OPEN_ARTICLE_MODAL = 'OPEN_ARTICLE_MODAL';
export const CLOSE_ARTICLE_MODAL = 'CLOSE_ARTICLE_MODAL';

export const openModal = () => ({
    type: OPEN_MODAL,
});

export const closeModal = () => ({
    type: CLOSE_MODAL,
});

export const openBookingModal = () => ({
    type: OPEN_BOOKING_MODAL,
});

export const closeBookingModal = () => ({
    type: CLOSE_BOOKING_MODAL,
});

export const openNewConversationModal = () => ({
    type: OPEN_NEW_CONVERSATION_MODAL,
});

export const closeNewConversationModal = () => ({
    type: CLOSE_NEW_CONVERSATION_MODAL,
});

export const openArticleModal = () => ({
    type: OPEN_ARTICLE_MODAL,
});
export const closeArticleModal = () => ({
    type: CLOSE_ARTICLE_MODAL,
});

export const openOrderModal =
    (orderId: number, isExternalBooking: boolean, isFutureOrder = true, unsortedOrders?: Order[]): AppThunk =>
    dispatch => {
        isExternalBooking ? dispatch(openBookingModal()) : dispatch(openModal());

        isFutureOrder
            ? dispatch(openOrder(orderId, isFutureOrder, unsortedOrders))
            : dispatch(openOrder(orderId, isFutureOrder));
    };
