import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        border: '1px solid #cccccc',
        borderRadius: 8,
        boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.15)',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            border: 0,
            borderTop: '1px solid #e2e2e2',
            boxShadow: 'none',
            borderRadius: 0,
        },
    },
    textField: {
        width: '100%',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        '& textarea': {
            padding: 20,
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
                paddingTop: theme.spacing(4),
            },
        },
    },
    bottomBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
    },
}));
