import { Timestamp } from 'firebase/firestore';

import firebase from '../../../configs/firebaseConfig';
import Customer from '../../../models/Customer';
import { createFailedAction } from '../../../utils/helpers';
import { AppThunk } from '../../reduxTypes';
import { closeNewConversationModal } from '../system/ModalControl';
import { setActiveConversation } from './ActiveConversation';

export const CREATE_CONVERSATION_STARTED = 'CREATE_CONVERSATION_STARTED';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_FAILED = 'CREATE_CONVERSATION_FAILED';

const createConversationStarted = () => ({
    type: CREATE_CONVERSATION_STARTED,
});

const createConversationSuccess = () => ({
    type: CREATE_CONVERSATION_SUCCESS,
});

const createConversationFailed = createFailedAction(CREATE_CONVERSATION_FAILED);

export const createConversation =
    (customer: Customer): AppThunk =>
    async dispatch => {
        dispatch(createConversationStarted());
        try {
            if (!customer.getFirstPhoneNumber()) {
                throw new Error('Customer does not have a phone number');
            }

            await firebase
                .firestore()
                .collection('customers')
                .doc(customer.customerNumber)
                .set(
                    {
                        customerName: customer.customerName,
                        customerNumber: customer.customerNumber,
                        phoneNumber1: customer.phoneNumber1?.get() ?? '',
                        phoneNumber2: customer.phoneNumber2?.get() ?? '',
                        flagged: false,
                        latestMessageDate: Timestamp.fromDate(new Date()),
                    },
                    { merge: true }
                );
            dispatch(createConversationSuccess());
            dispatch(closeNewConversationModal());
            dispatch(setActiveConversation(customer.customerNumber));
        } catch (error) {
            dispatch(createConversationFailed(error));
        }
    };
