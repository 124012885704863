import { MenuItem, SelectChangeEvent } from '@mui/material';

import { makeStyles } from '../../../state/hooks';
import PhoneNumber from '../../../models/PhoneNumber';
import SelectField from '../../Form/SelectField/SelectField';
import PhoneNumberWithIcon from '../../Graphics/PhoneNumberWithIcon/PhoneNumberWithIcon';

export type Props = {
    phoneNumbers: PhoneNumber[];
    selected: string;
    handleChange: (event: SelectChangeEvent) => void;
};

/**
 * Component for showing multiple phone numbers, and switching between them.
 */
const PhoneNumberDropdown = ({ phoneNumbers, selected, handleChange }: Props) => {
    const { classes } = useStyles();

    const numbersCount = phoneNumbers.length;
    const disabled = numbersCount > 1 ? false : true; // Disable if only a single phone number

    return (
        <SelectField
            selected={selected}
            handleChange={handleChange}
            rounded={false}
            dense={true}
            className={disabled ? '' : classes.select}
            disabled={disabled}
        >
            {phoneNumbers.map((phoneNumber, index) => (
                <MenuItem key={phoneNumber.get() + index} value={phoneNumber.get()}>
                    {numbersCount > 1 ? (
                        <PhoneNumberWithIcon iconNumber={String(index + 1)} phoneNumber={phoneNumber} />
                    ) : (
                        phoneNumber.getPretty()
                    )}
                </MenuItem>
            ))}
        </SelectField>
    );
};
const useStyles = makeStyles()(theme => ({
    select: {
        backgroundColor: 'white',
        boxShadow: theme.shadows[2],
    },
}));

export default PhoneNumberDropdown;
