import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        padding: theme.spacing(4),
        height: 'fit-content',
        maxWidth: 500,
        minWidth: 250,
    },
    header: {
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2),
    },
}));
