import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    title: {
        marginBottom: theme.spacing(3),
    },
    bottomButtons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
