import { ReactNode } from 'react';
import { Select, SelectChangeEvent, SelectProps } from '@mui/material';

import useStyles from './SelectField.styles';

export type Props = {
    handleChange: (event: SelectChangeEvent) => void;
    selected: SelectProps<string>['value'];
    children: ReactNode;
    className?: string;
    dark?: boolean;
    dense?: boolean;
    rounded?: boolean;
    disabled?: boolean;
};

const SelectField = ({
    className = '',
    handleChange,
    selected,
    children,
    dark = false,
    dense = false,
    rounded = true,
    disabled = false,
}: Props) => {
    const { classes, cx } = useStyles();

    return (
        <Select
            value={selected}
            autoWidth={true}
            onChange={handleChange}
            variant='outlined'
            disabled={disabled}
            className={cx(
                classes.select,
                dark ? classes.dark : classes.light,
                !rounded ? classes.notRounded : '',
                className
            )}
            classes={{
                outlined: cx(
                    !rounded ? classes.notRounded : '',
                    classes.outlined,
                    dense ? classes.dense : classes.normal
                ),
            }}
        >
            {children}
        </Select>
    );
};

export default SelectField;
