import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { MoreVertical } from 'react-feather';
import { useState } from 'react';

import { ArticlePlacement, InventoryLocation as InventoryLocationType, TableHeadCell } from '../../../utils/types';
import TableCellMenu from '../TableCellMenu/TableCellMenu';
import { TableHead } from '@mui/material';
type Props = {
    articleRows: ArticlePlacement[];
    locationType: InventoryLocationType['locationType'];
    handleClearPlacement: (placements: { articlePlacement: ArticlePlacement; count: number }[]) => void;
    handleClearMultipleProductLocations: (placements: ArticlePlacement[]) => void;
};

const InventoryPlacementsTable = ({
    articleRows,
    locationType,
    handleClearPlacement,
    handleClearMultipleProductLocations,
}: Props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - articleRows.length) : 0;

    function getHeadCells(locationType: InventoryLocationType['locationType']) {
        const headCells: TableHeadCell<ArticlePlacement>[] = [];
        if (locationType === 'one-to-one') {
            headCells.push({ id: 'placementName', numeric: false, disablePadding: false, label: 'Plassering' });
        }
        headCells.push({ id: 'articleName', numeric: false, disablePadding: false, label: 'Artikkel' });
        headCells.push({ id: 'articleNumber', numeric: false, disablePadding: false, label: 'Artikkelnummer' });
        if (locationType === 'one-to-many') {
            headCells.push({ id: 'colorName', numeric: false, disablePadding: false, label: 'Farge' });
            headCells.push({ id: 'sizeName', numeric: false, disablePadding: false, label: 'Størrelse' });
            headCells.push({ id: 'count', numeric: false, disablePadding: false, label: 'Antall' });
        }
        return headCells;
    }

    function getBodyCells() {
        if (locationType === 'one-to-one') {
            return <TableCell align='left'>{articleRows[0].placementName}</TableCell>;
        }
    }

    const headCells = getHeadCells(locationType);

    return (
        <Box sx={{ width: '100%' }}>
            <Table aria-labelledby='tableTitle' size={'small'}>
                <TableHead>
                    <TableRow>
                        {headCells.map(headCell => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                            >
                                {headCell.label}
                            </TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {articleRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((placement, index) => {
                        let key = `${placement.articleNumber}-${placement.sizeColorId}-${placement.placementId}`;
                        const rowMenuItems = [
                            {
                                name: 'Tøm 1 vare',
                                clickHandler: () => handleClearPlacement([{ articlePlacement: placement, count: 1 }]),
                            },
                        ];
                        if (locationType === 'one-to-many') {
                            key = `${placement.articleNumber}-${placement.sizeColorId}`;
                            rowMenuItems.push({
                                name: 'Tøm flere varer',
                                clickHandler: () => handleClearMultipleProductLocations([placement]),
                            });
                        }
                        return (
                            <TableRow tabIndex={-1} key={key}>
                                {locationType === 'one-to-one' && (
                                    <TableCell component='th' scope='row'>
                                        {placement.placementName}
                                    </TableCell>
                                )}
                                <TableCell component='th' scope='row'>
                                    {placement.supplierArticleNumber ?? placement.articleNumber}
                                </TableCell>
                                <TableCell align='left'>{placement.articleName ?? '-'}</TableCell>
                                {locationType === 'one-to-many' && (
                                    <>
                                        <TableCell align='left'>{placement.colorName ?? '-'}</TableCell>
                                        <TableCell align='left'>{placement.sizeName ?? '-'}</TableCell>
                                        <TableCell align='left'>{placement.count ?? '-'}</TableCell>
                                    </>
                                )}
                                <TableCell align='right'>
                                    <TableCellMenu
                                        icon={<MoreVertical color='#000' size={20} />}
                                        rowMenuItems={rowMenuItems}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: 33 * emptyRows,
                            }}
                        >
                            <TableCell colSpan={5} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={articleRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Rader per side'
            />
        </Box>
    );
};

export default InventoryPlacementsTable;
