import { Article } from '../../../models/Article';
import { authFetch, createFailedAction } from '../../../utils/helpers';
import { InventorySearchObject } from '../../../utils/types';
import { AppThunk } from '../../reduxTypes';

export const INVENTORY_SEARCH_STARTED = 'INVENTORY_SEARCH_STARTED';
export const INVENTORY_SEARCH_SUCCESS = 'INVENTORY_SEARCH_SUCCESS';
export const INVENTORY_SEARCH_FAILED = 'INVENTORY_SEARCH_FAILED';

const inventorySearchStarted = () => ({
    type: INVENTORY_SEARCH_STARTED,
});

const inventorySearchSuccees = (results: Article[]) => ({
    type: INVENTORY_SEARCH_SUCCESS,
    payload: { results },
});

const placeInventoryFailed = createFailedAction(INVENTORY_SEARCH_FAILED);

/**
 * Formats article groups array to URL parameters
 * @param articleGroups Two-dimensional array of article groups
 * @param params The params object to add the article groups to
 * @returns A URLSearchParams object with the article groups added
 */
function getArticleGroupsQueryParams(articleGroups: number[][], params: URLSearchParams): URLSearchParams {
    articleGroups.forEach((articleGroup, i) => {
        articleGroup.forEach(ag => {
            if (ag !== 0) {
                params.append('articleGroup' + (i + 1) + 'Ids[]', ag.toString());
            }
        });
    });

    return params;
}

export const inventorySearch =
    (searchObject: InventorySearchObject): AppThunk =>
    async (dispatch, getState, fb) => {
        dispatch(inventorySearchStarted());
        const apiUrl = getState().system.apiURL;
        if (!apiUrl) throw new Error('Could not fetch API URL.');
        try {
            let params = new URLSearchParams();
            if (searchObject.searchQuery !== null && searchObject.searchQuery !== '') {
                params.append('articleName', encodeURIComponent(searchObject.searchQuery));
            }

            params = getArticleGroupsQueryParams(
                [
                    searchObject.primaryArticleGroups,
                    searchObject.secondaryArticleGroups,
                    searchObject.tertiaryArticleGroups,
                ],
                params
            );

            searchObject.articleAttributes.forEach((attribute, i) => {
                attribute.articleAttributeValues.forEach(value => {
                    if (value !== 0) {
                        params.append('articleAttributeValues[]', value.toString());
                    }
                });
            });

            if (searchObject.manufacturers.length > 0) {
                searchObject.manufacturers.forEach(manufacturer => {
                    if (manufacturer !== 0) {
                        params.append('manufacturerIds[]', manufacturer.toString());
                    }
                });
            }

            const url = '/articles?' + decodeURI(params.toString());

            const results = await authFetch(fb, url, 'GET', undefined, apiUrl);
            const data: Article[] = await results.json();

            dispatch(inventorySearchSuccees(data));
        } catch (error) {
            dispatch(placeInventoryFailed(error));
        }
    };

export const RESET_INVENTORY_SEARCH = 'RESET_INVENTORY_SEARCH';

export const resetInventorySearch = () => ({
    type: RESET_INVENTORY_SEARCH,
});
