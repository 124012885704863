import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import BookingCalendarCapacityContainer from '../../../containers/PageSpecific/Bookings/BookingCalendarCapacityContainer';
import { useSelector } from '../../../state/hooks';

type Props = {};

const BookingCalendar = ({}: Props) => {
    const activeStoreLocation = useSelector(state => state.system.activeStoreLocation);
    return (
        <>
            {!activeStoreLocation ? (
                <SubtleSpinner style={{ marginTop: 50 }} />
            ) : (
                <BookingCalendarCapacityContainer activeStoreLocation={activeStoreLocation} />
            )}
        </>
    );
};

export default BookingCalendar;
