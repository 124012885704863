import { Button, Typography } from '@mui/material';

import useStyles from './ConversationHeader.styles';
import SmallLabel from '../../Typography/SmallLabel/SmallLabel';
import PhoneNumber from '../../../models/PhoneNumber';
import ExpandButton from '../../Buttons/ExpandButton/ExpandButton';
import PromptModal from '../../Modals/PromptModal/PromptModal';

export type Props = {
    customerNumber: string;
    phoneNumbers: PhoneNumber[] | null;
    customerName: string;
    isExpanded: boolean;
    openModal: boolean;
    isUpdatingCustomer: boolean;
    handleSetExpanded: () => void;
    toggleModal: () => void;
    handleDeleteConversation: () => void;
    handleUpdateCustomer: () => void;
};

/**
 * Component representing the header of a conversation, with customer info and interface for deleting the conversation.
 */
const ConversationHeader = ({
    customerNumber,
    phoneNumbers,
    customerName,
    isExpanded,
    openModal,
    isUpdatingCustomer,
    handleSetExpanded,
    toggleModal,
    handleDeleteConversation,
    handleUpdateCustomer,
}: Props) => {
    const { classes, cx } = useStyles();

    const expandedBoxStyles = !isExpanded ? cx(classes.expandedBox, classes.hidden) : classes.expandedBox;

    return (
        <div className={classes.root}>
            <Typography variant='subtitle2'>{'Kundenummer: ' + customerNumber}</Typography>
            <div className={classes.header}>
                <Typography variant='h3'>{customerName ?? 'Intet navn funnet'}</Typography>
                <ExpandButton isExpanded={isExpanded} handleClick={handleSetExpanded} />
            </div>
            <div className={expandedBoxStyles}>
                <div className={classes.phoneNumbers}>
                    {phoneNumbers?.map((phone, index) => (
                        <div key={'customer_phone_' + index}>
                            <SmallLabel text={`Telefon ${index + 1}`} />
                            <Typography className={classes.labelText}>{phone.getPretty() ?? '...'}</Typography>
                        </div>
                    ))}
                </div>
                <div>
                    <Button
                        variant='text'
                        className={classes.deleteButton}
                        onClick={handleUpdateCustomer}
                        disabled={isUpdatingCustomer}
                    >
                        {isUpdatingCustomer ? 'Oppdaterer...' : 'Oppdater kundeinfo'}
                    </Button>
                    <Button
                        variant='contained'
                        color='error'
                        className={classes.deleteButton}
                        onClick={toggleModal}
                        sx={{ ml: 3 }}
                    >
                        Slett samtale
                    </Button>
                </div>
            </div>
            <PromptModal
                openModal={openModal}
                toggleModal={toggleModal}
                maxWidth='sm'
                title='Slett samtale'
                description='Vil du slette denne samtalen? Alle meldinger blir slettet, og dette kan ikke reverseres.'
                rightButton={
                    <Button onClick={toggleModal} variant='text'>
                        Avbryt
                    </Button>
                }
                leftButton={
                    <Button onClick={handleDeleteConversation} variant='contained' color='error'>
                        Slett samtale
                    </Button>
                }
            />
        </div>
    );
};

export default ConversationHeader;
