import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    button: {
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: '#666666',
        transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
        },
        '&:active': {
            opacity: 0.6,
        },
    },
    activeButton: {
        backgroundColor: theme.palette.primary[50],
        color: 'black',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    icon: {
        stroke: theme.palette.grey[400],
    },
    iconAlign: {
        marginLeft: -2,
    },
    activeIcon: {
        stroke: theme.palette.primary.main,
    },
    chip: {
        padding: '0px 7px',
        height: 'auto',
        fontSize: 14,
        marginLeft: 4,
    },
    chipLabel: {
        padding: 0,
        lineHeight: '22px',
    },
}));
