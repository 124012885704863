import { Box, SelectChangeEvent } from '@mui/material';

import { ArticleAttribute, InventorySearchObject } from '../../../../utils/types';
import MultipleSelectDropdown from '../../../Form/MultipleSelectDropdown/MultipleSelectDropdown';
import Column from '../../../Layouts/Column/Column';

export type Props = {
    articleAttributes: ArticleAttribute[];
    activeAttributesValues: InventorySearchObject['articleAttributes'];
    handleChange: (articleAttributeId: number, event: SelectChangeEvent<number[]>) => void;
};

const InventorySearchAttributes = ({ articleAttributes, activeAttributesValues, handleChange }: Props) => {
    return (
        <Box sx={{ mt: 3 }}>
            <Column gap={3}>
                {articleAttributes.map((articleAttribute, index) => {
                    const activeIds = activeAttributesValues.find(
                        a => a.articleAttributeId === articleAttribute.articleAttributeId
                    )?.articleAttributeValues;
                    return (
                        articleAttribute.articleAttributeValues && (
                            <MultipleSelectDropdown
                                key={articleAttribute.articleAttributeId}
                                options={articleAttribute.articleAttributeValues.map(articleAttributeValue => ({
                                    id: articleAttributeValue.articleAttributeValueId,
                                    value: articleAttributeValue.name,
                                }))}
                                name={articleAttribute.name}
                                defaultValueName='Alle attributter'
                                label={articleAttribute.name}
                                activeOptions={activeIds ?? [0]}
                                handleChange={event => handleChange(articleAttribute.articleAttributeId, event)}
                            />
                        )
                    );
                })}
            </Column>
        </Box>
    );
};

export default InventorySearchAttributes;
