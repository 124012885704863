import { ReactElement } from 'react';
import { Button } from '@mui/material';

import { makeStyles } from '../../../state/hooks';

export type Props = {
    handleClick: () => void;
    children: ReactElement<any>;
    color?: 'primary' | 'secondary';
    className?: string;
    disabled?: boolean;
    size?: 'normal' | 'large';
};

/**
 * Circular FAB that contains an icon as children.
 */
const CircularButton = ({ handleClick, color, children, className, disabled, size = 'normal' }: Props) => {
    const { classes, cx } = useStyles();

    return (
        <Button
            className={cx(classes.root, className)}
            color={color}
            variant='contained'
            onClick={handleClick}
            disabled={disabled}
            style={size === 'large' ? { padding: 12 } : {}}
        >
            {children}
        </Button>
    );
};

const useStyles = makeStyles()({
    root: {
        padding: 10,
        borderRadius: 40,
        minWidth: 'auto',
    },
});

export default CircularButton;
