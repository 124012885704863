import { ChangeEvent, ReactNode } from 'react';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { ArrowUp } from 'react-feather';
import { useDispatch } from 'react-redux';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';

import { makeStyles, useSelector } from '../../../../state/hooks';
import { MessageTemplate } from '../../../../utils/types';
import { sendMessage } from '../../../../state/ducks/sms/SendMessage';
import ReplyField from '../../../../components/Form/MessageReplyField/ReplyField';
import PhoneNumber from '../../../../models/PhoneNumber';
import ReplyTemplates from '../../../../components/Form/MessageReplyField/ReplyTemplateButtons/ReplyTemplates';

type Props = {
    employeeName: string;
    customerName: string;
    customerNumber: string;
    phoneNumber: PhoneNumber;
    children: ReactNode;
    inputValue: string;
    setContactInput: (value: string) => void;
};

// TODO Refactor logic and styles from this component
const ContactReplyContainer = ({
    employeeName,
    customerName,
    customerNumber,
    phoneNumber,
    children,
    inputValue,
    setContactInput,
}: Props) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const rawTemplates = useSelector(state => state.firestore.ordered.templates);
    useFirestoreConnect({ collection: 'messageTemplates', storeAs: 'templates' });

    let finalTemplates: MessageTemplate[] = [];

    if (isLoaded(rawTemplates) && !isEmpty(rawTemplates)) {
        const allTemplates = rawTemplates.filter((temp: MessageTemplate) => temp.templateName);
        finalTemplates = [{ templateName: 'Alle maler', text: '', id: 'all', favorite: false }, ...allTemplates];
    }

    const selectTemplate = (value: string) => {
        if (!isEmpty(finalTemplates)) {
            const currentTemplate: MessageTemplate | undefined = finalTemplates.find(
                (temp: MessageTemplate) => temp.id === value
            );

            if (value !== 'all') {
                const global = rawTemplates.find((doc: any) => doc?.id === 'globalText');
                if (currentTemplate && global) {
                    const firstName = customerName.split(' ')[0];
                    let message = `${global?.start} ${firstName}\n\n`;
                    message += currentTemplate.text;
                    message += `\n\n${global?.end} ${employeeName}\n${global?.final}`;

                    setContactInput(message);
                }
            }
        }
    };

    const handleChange = (event: ChangeEvent<{ value: string }>) => {
        setContactInput(event.target.value);
    };

    const handleSendMessage = () => {
        // Check that the message has text content
        if (inputValue?.length > 0 && inputValue.match(/\w/g)) {
            dispatch(sendMessage(inputValue, phoneNumber, customerNumber));

            setContactInput(''); // Reset input field
        }
    };

    const sendButton = (
        <Button
            className={classes.button}
            onClick={handleSendMessage}
            variant='contained'
            color='primary'
            endIcon={<ArrowUp />}
        >
            Send
        </Button>
    );

    return (
        <div className={classes.root}>
            <>{children}</>
            <div className={classes.replyArea}>
                <div className={classes.textareaContainer}>
                    <ReplyField handleChange={handleChange} value={inputValue} templates={finalTemplates}>
                        <ReplyTemplates selectTemplate={selectTemplate} templates={finalTemplates} />
                        {isMobile && <>{sendButton}</>}
                    </ReplyField>
                </div>
                {!isMobile && <>{sendButton}</>}
            </div>
        </div>
    );
};

const useStyles = makeStyles()(theme => ({
    root: {
        maxWidth: 800,
        margin: 'auto',
    },
    replyArea: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    },
    textareaContainer: {
        flex: 1,
    },
    button: {
        paddingTop: '9px',
        paddingBottom: '9px',
        marginBottom: 1,
        marginLeft: theme.spacing(2),
    },
}));

export default ContactReplyContainer;
