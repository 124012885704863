import { useMediaQuery, Theme } from '@mui/material';
import { ReactNode } from 'react';

import { makeStyles } from '../../../state/hooks';

type FlexDirection = 'column' | 'row';

export type GapColumnProps = {
    children: ReactNode;
    gap?: number;
    direction?: FlexDirection;
    isResponsive?: boolean;
};

export default function Axis({ children, gap = 0, direction = 'row', isResponsive = false }: GapColumnProps) {
    const { classes } = useStyles(gap)();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    if (isResponsive) direction = isMobile ? 'column' : 'row';

    return <div className={direction === 'row' ? classes.row : classes.column}>{children}</div>;
}

const useStyles = (gap: number) =>
    makeStyles()(theme => ({
        row: {
            display: 'flex',
            flexDirection: 'row',
            '& > *:first-of-type': {
                marginLeft: 0,
            },
            '& > *': {
                marginLeft: theme.spacing(gap),
            },
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            '& > *:first-of-type': {
                marginTop: 0,
            },
            '& > *': {
                marginTop: theme.spacing(gap),
            },
        },
    }));
