import { Snackbar, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

export type Props = {
    handleClose: (identifier: string) => void;
    message?: string;
    identifier?: string;
};

const ErrorSnackBar = ({ identifier, message, handleClose }: Props) => {
    const close = () => {
        if (identifier) handleClose(identifier);
    };

    return (
        <Snackbar
            sx={{ mt: 2, position: 'relative' }}
            ContentProps={{
                sx: { backgroundColor: 'error.main' },
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={true}
            autoHideDuration={8000}
            message={message}
            action={
                <IconButton size='small' sx={{ color: '#fff', ml: 1 }} onClick={close} data-cy='close-error-snackbar'>
                    <CloseIcon />
                </IconButton>
            }
        />
    );
};

export default ErrorSnackBar;
