import { collection, doc, getFirestore } from 'firebase/firestore';
import { createFailedAction } from '../../../utils/helpers';
import { AppThunk } from '../../reduxTypes';
import { flagSearchResultConversation } from './ConversationSearch';
import { runTransaction } from 'firebase/firestore';
import app from '../../../configs/firebaseConfig';

export const FLAG_CONVERSATION_STARTED = 'FLAG_CONVERSATION_STARTED';
export const FLAG_CONVERSATION_SUCCESS = 'FLAG_CONVERSATION_SUCCESS';
export const FLAG_CONVERSATION_FAILED = 'FLAG_CONVERSATION_FAILED';

const flagConversationStarted = () => ({
    type: FLAG_CONVERSATION_STARTED,
});
const flagConversationSuccess = () => ({
    type: FLAG_CONVERSATION_SUCCESS,
});
const flagConversationFailed = createFailedAction(FLAG_CONVERSATION_FAILED);

export const toggleFlagConversation =
    (customerNumber: string): AppThunk =>
    async dispatch => {
        dispatch(flagConversationStarted());
        try {
            const db = getFirestore(app);
            const customerRef = doc(collection(db, 'customers'), customerNumber);
            const controlRef = doc(collection(db, 'messageControl'), 'flaggedMessages');

            await runTransaction(db, async transaction => {
                const docs = await Promise.all([transaction.get(customerRef), transaction.get(controlRef)]);

                const customer = docs[0];
                const control = docs[1];

                if (!customer.exists() || !control.exists()) throw new Error('Flagging documents does not exist');

                const flaggedStatus = customer.data().flagged;
                transaction.update(customerRef, { flagged: !flaggedStatus });

                const change = flaggedStatus ? -1 : 1;
                transaction.update(controlRef, { count: control.data().count + change });
            });

            dispatch(flagConversationSuccess());
            dispatch(flagSearchResultConversation(customerNumber)); // Set the flag in local redux state (if possible)
        } catch (error) {
            dispatch(flagConversationFailed(error));
        }
    };
