import { Timestamp } from 'firebase/firestore';

import { AppThunk } from '../../reduxTypes';
import { Message } from '../../../utils/types';
import firebase from '../../../configs/firebaseConfig';
import PhoneNumber from '../../../models/PhoneNumber';
import { createFailedAction } from '../../../utils/helpers';

export const SENDING_MESSAGE = 'SENDING_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';

const sendingMessage = () => ({
    type: SENDING_MESSAGE,
});
const sendMessageSuccess = () => ({
    type: SEND_MESSAGE_SUCCESS,
});
const sendMessageFailed = createFailedAction(SEND_MESSAGE_FAILED);

export const sendMessage =
    (message: string, to: PhoneNumber, customerNumber: string): AppThunk =>
    async dispatch => {
        dispatch(sendingMessage());
        try {
            const messageDate = Timestamp.fromDate(new Date());

            const newMessage: Message = {
                associatedWith: to.get(),
                text: message,
                messageId: '0',
                isIncoming: false,
                date: messageDate,
                sent: false,
            };

            await firebase.firestore().collection(`customers/${customerNumber}/messages`).add(newMessage);

            // Update latest message in firestore
            await firebase.firestore().collection('customers').doc(customerNumber).update({
                latestMessage: newMessage,
                latestMessageDate: messageDate,
            });

            dispatch(sendMessageSuccess());
        } catch (error) {
            dispatch(sendMessageFailed(error));
        }
    };
