import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        position: 'relative',
        zIndex: 20,
        width: 240,
        borderRadius: 0,
    },
    sidebar: {
        width: 200,
        padding: '30px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'scroll',
        height: 'calc(100% - 60px)',
    },
    mobileMenu: {
        boxShadow: 'none',
        zIndex: 1150,
        position: 'relative',
    },
    links: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
            marginTop: 10,
        },
        '& > a > div': {
            marginBottom: theme.spacing(1),
        },
    },
    version: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: 'center',
        color: '#ccc',
    },
}));
