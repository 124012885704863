import { ChangeEvent } from 'react';
import { InputBaseComponentProps, OutlinedInput, OutlinedInputProps, Typography } from '@mui/material';

export type Props = {
    id: string;
    value: number | string | null;
    label?: string;
    onChange:
        | ((event: ChangeEvent<{ value: unknown }>) => void)
        | ((event: ChangeEvent<{ value: unknown; id: string; type: string }>) => void);
    type: 'number' | 'text' | 'password' | 'email';
    placeholder?: string;
    className?: string;
    classes?: { [key: string]: string };
    textInputProps?: OutlinedInputProps;
};

const InputField = ({ id, value, label, type, placeholder, className, classes, onChange, textInputProps }: Props) => {
    const inputProps: InputBaseComponentProps = type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*' } : {};
    return (
        <div>
            {label && (
                <Typography variant='subtitle2' sx={{ mb: 1 }}>
                    {label}
                </Typography>
            )}
            <OutlinedInput
                inputProps={inputProps}
                id={id}
                className={className}
                classes={{ ...classes }}
                type={type === 'number' ? 'text' : type}
                value={value}
                onChange={onChange}
                margin='dense'
                fullWidth={true}
                placeholder={placeholder}
                style={{ backgroundColor: 'white' }}
                {...textInputProps}
            />
        </div>
    );
};

export default InputField;
