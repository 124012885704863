import {
    IconButton,
    Menu,
    MenuItem,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useState } from 'react';
import { MoreVertical } from 'react-feather';

export type Props = {
    header: {
        key: string;
        value: string;
    };
    body: {
        key: string;
        value: string;
    }[];
    rowMenuItems: {
        name: string;
        clickHandler: VoidFunction;
    }[];
};

const MobilePlacementsTableRow = ({ header, body, rowMenuItems }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const StyledTableRow: any = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead sx={{ backgroundColor: 'primary.100' }}>
                        <TableRow>
                            <TableCell width={115}>{header.key}</TableCell>
                            <TableCell>{header.value}</TableCell>
                            <TableCell align='right' width={40}>
                                <IconButton onClick={handleClick}>
                                    <MoreVertical color='#000' size={20} />
                                </IconButton>
                                <Menu id='placement-row-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    {rowMenuItems &&
                                        rowMenuItems.map(menuItem => (
                                            <MenuItem
                                                key={menuItem.name}
                                                onClick={() => {
                                                    menuItem.clickHandler();
                                                    handleClose();
                                                }}
                                            >
                                                {menuItem.name}
                                            </MenuItem>
                                        ))}
                                </Menu>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {body.length &&
                            body.map(row => {
                                return (
                                    <StyledTableRow key={row.value}>
                                        <TableCell colSpan={1}>{row.key}</TableCell>
                                        <TableCell colSpan={2}>{row.value}</TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default MobilePlacementsTableRow;
