import { TableRow, TableCell, IconButton, Collapse, Box } from '@mui/material';
import { ReactNode, useState } from 'react';
import { ChevronUp, ChevronDown } from 'react-feather';

import SubtleSpinner from '../../Indicators/SubtleSpinner/SubtleSpinner';
import useStyles from './ExpandableTableRow.styles';

export type Props = {
    children: ReactNode;
    tableCells: ReactNode;
    columnCount: number;
    loading?: boolean;
    onOpen: () => void;
};

const ExpandableTableRow = ({ children, tableCells, columnCount, loading, onOpen }: Props) => {
    const { classes, cx } = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        if (!open) onOpen();
        setOpen(!open);
    };

    return (
        <>
            <TableRow className={classes.tableRow} onClick={handleOpen}>
                <>{tableCells}</>
                <TableCell>
                    <IconButton aria-label='expand row' size='small'>
                        {open ? <ChevronUp size={26} /> : <ChevronDown size={26} />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    className={open ? classes.expandedCell : cx(classes.expandedCell, classes.closedRow)}
                    colSpan={columnCount + 1} // The amount of columns, + 1 for the Chevron-button cell
                >
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box className={classes.expandedRowBox}>{loading ? <SubtleSpinner size={28} /> : children}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ExpandableTableRow;
