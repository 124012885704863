import { ChangeEvent, ReactNode } from 'react';
import { Box, Input, Theme, useMediaQuery } from '@mui/material';

import { MessageTemplate } from '../../../utils/types';
import makeStyles from './ReplyField.styles';

export type PropsReplyField = {
    children: ReactNode;
    templates: MessageTemplate[];
    value: string;
    handleChange: (event: ChangeEvent<{ value: string }>) => void;
};

/**
 * Component representing a reply message field where the user can type a message,
 * and/or choose message-templates to start out from.
 */
const ReplyField = ({ children, value, handleChange }: PropsReplyField) => {
    const { classes } = makeStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <div className={classes.root}>
            <Input
                className={classes.textField}
                value={value}
                onChange={handleChange}
                placeholder='Trykk for å skrive en melding'
                multiline={true}
                minRows={isMobile ? 3 : 5}
                disableUnderline={true}
            />
            <Box className={classes.bottomBar}>{children}</Box>
        </div>
    );
};

export default ReplyField;
