import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        borderRadius: 0,
        zIndex: 100,
    },
    menu: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    links: {
        margin: theme.spacing(3),
        '& > a > div': {
            marginBottom: theme.spacing(1),
        },
    },
    logo: {
        width: 95,
        position: 'relative',
        top: 3,
        marginLeft: theme.spacing(4),
    },
    drawer: {
        top: 55,
        borderRadius: 0,
        zIndex: 1100,
    },
    drawerPaper: {
        borderRadius: 0,
    },
}));
