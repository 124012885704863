import { Box, Theme, Typography, useMediaQuery } from '@mui/material';

import SegmentedControl from '../../../../components/Form/SegmentedControl/SegmentedControl';
import MonthPicker from '../../../../components/Navigation/MonthPicker/MonthPicker';
import { makeStyles } from '../../../../state/hooks';

export type Props = {
    month: number;
    year: number;
    nextMonth: () => void;
    previousMonth: () => void;
    selectedStandardCapacity: number;
    handleSelectStandardCapacity: (id: number) => void;
};

const BookingCalendarMonthPicker = ({
    month,
    year,
    nextMonth,
    previousMonth,
    selectedStandardCapacity,
    handleSelectStandardCapacity,
}: Props) => {
    const { classes } = useStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const capacityOptions = [
        { id: 0, name: 'Standard' },
        { id: 1, name: 'Prioritert' },
    ];

    const date = new Date();
    const currYear = date.getFullYear();
    const currMonth = date.getMonth();

    const controlsActive = {
        left: !(currMonth === month && currYear === year),
        right: !(currMonth - 1 === month && currYear + 1 === year),
    };

    return (
        <div className={classes.root}>
            <Box className={classes.monthPicker} sx={{ mb: { xs: 2, sm: 0 } }}>
                <Typography variant='h2' sx={{ mr: { xs: 4, sm: 2, md: 4 } }}>
                    Kalender
                </Typography>
                <SegmentedControl
                    shortcuts={isMobile ? [] : capacityOptions.map(option => option.id)}
                    selected={selectedStandardCapacity}
                    handleSelect={handleSelectStandardCapacity}
                    options={capacityOptions}
                />
            </Box>
            <MonthPicker
                month={month}
                year={year}
                nextMonth={nextMonth}
                previousMonth={previousMonth}
                controlsActive={controlsActive}
            />
        </div>
    );
};

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    monthPicker: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default BookingCalendarMonthPicker;
