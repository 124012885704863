import { FirebaseError } from 'firebase/app';

import * as handleErrorsTypes from './handleErrors';
import { getErrorMessageByIdentifier } from '../../../utils/errorHelpers';
import { isProdEnvironment } from '../../../utils/helpers';
import AppError from '../../../models/AppError';

interface errorState {
    errors: AppError[];
}

export const initialState: errorState = {
    errors: [],
};

export default function errorReducer(state = initialState, action: any) {
    const { type, payload, authError } = action;

    switch (type) {
        case handleErrorsTypes.CLEAR_ERROR_BY_IDENTIFIER:
            return {
                ...state,
                errors: state.errors.filter(error => error.getIdentifier() !== payload.identifier),
            };
    }


    let newError: AppError;

    if (type.includes('@@reactReduxFirebase') && authError !== undefined) {
        // Handle redux firebase errors
        const errorCode: string = authError.code;
        let errorMessage = '';
        switch (errorCode) {
            case 'auth/user-not-found':
                errorMessage = 'Kunne ikke finne brukeren med denne eposten.';
                break;
            case 'auth/wrong-password':
                errorMessage = 'Feil passord. Prøv igjen eller nullstill passordet.';
                break;
            case 'auth/too-many-requests':
                errorMessage = 'For mange mislykkede innloggingsforsøk. Prøv igjen senere, eller nullstill passordet.';
        }
        newError = new AppError(authError.message, authError);
        newError.setIdentifier(errorCode);
        newError.setPublicMessage(errorMessage);

        const newErrors = state.errors.filter(error => !(error.getIdentifier() === newError.getIdentifier()));
        newErrors.push(newError);

        return {
            ...state,
            errors: newErrors,
        };
    } else if (type.includes('ERROR') || type.includes('FAILED')) {
        // Catch all error actions
        const isClientError = payload.error.status === 200;

        if (isClientError) {
            newError = new AppError(payload.error.title, payload.error, false);
            newError.setIdentifier(type);
            newError.setPublicMessage(payload.error.title);
        } else if (type === 'FETCH_FAILED') {
            newError = new AppError(payload.error.data.message);
            newError.setIdentifier(payload.error.data.error);
        } else if (payload?.error instanceof AppError) {
            newError = payload.error;
            newError.setIdentifier(type);
        } else if (payload?.error instanceof Error) {
            newError = new AppError(payload.error.message, payload.error);
            newError.setIdentifier(type);
        } else {
            newError = new AppError('An unexpected error occurred', action);
            newError.setIdentifier(type);
        }

        const identifier = newError.getIdentifier();
        if (!isClientError) newError.setPublicMessage(getErrorMessageByIdentifier(identifier));

        // Remove errors that are identical to the new error
        const newErrors = state.errors.filter(error => !(error.getIdentifier() === newError.getIdentifier()));
        newErrors.push(newError);

        // For debugging purposes when errors happens
        if (!isProdEnvironment()) {
            console.log(action);
            console.dir(newError);
        } else {
            console.log(newError.getIdentifier(), newError.message);
        }

        return {
            ...state,
            errors: newErrors,
        };
    }

    return state;
}
