import { Component, ReactNode } from 'react';
import { Button } from '@mui/material';

type ErrorState = {
    hasError: boolean;
};

type Props = {
    children: ReactNode;
};

class ErrorBoundary extends Component<Props, ErrorState> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        console.log({ error, errorInfo });
    }

    handleClick = () => {
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{ marginTop: 140, textAlign: 'center' }}>
                    <h1>Det skjedde en feil.</h1>
                    <p style={{ marginTop: 30, marginBottom: 15 }}>Prøv å last siden inn på nytt.</p>
                    <Button onClick={this.handleClick} variant='contained' color='primary'>
                        Last siden inn på nytt
                    </Button>
                </div>
            );
        }

        return <>{this.props.children}</>;
    }
}

export default ErrorBoundary;
