import { ReactNode } from 'react';

import Axis from '../Axis/Axis';

type ColumnProps = {
    children: ReactNode;
    gap?: number;
};

export default function Column({ children, gap }: ColumnProps) {
    return (
        <Axis gap={gap} direction='column'>
            {children}
        </Axis>
    );
}
