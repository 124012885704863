export default class AppError extends Error {
    public readonly original: unknown;
    public readonly isOperational: boolean;
    // TODO find a better solution to the string / undefined problem this causes
    private identifier?: string;
    private publicMessage?: string;

    constructor(message: string, original?: unknown, isOperational: boolean = true) {
        super(message);
        this.original = original;
        this.isOperational = isOperational;

        if (Error.captureStackTrace) Error.captureStackTrace(this);
    }

    setIdentifier(action: string) {
        this.identifier = action;
    }

    getIdentifier() {
        return this.identifier;
    }

    setPublicMessage(message: string) {
        this.publicMessage = message;
    }

    getPublicMessage() {
        return this.publicMessage;
    }
}

export class FetchError extends AppError {
    constructor(original: unknown, isOperational?: boolean) {
        super(FetchError.parseErrorMessage(original), original, isOperational);

        if (Error.captureStackTrace) Error.captureStackTrace(this);
    }

    // TODO write tests for the method bellow:

    /**
     * Get the message from the error if possible
     * @param error unknown error
     * @returns error.message property if js Error, stringified error if not
     */
    static parseErrorMessage(error: unknown): string {
        if (typeof error === 'string') return error;
        if (error instanceof Error) return error.message;
        if (error && typeof error === 'object' && 'message' in error) return (error as any).message;
        return JSON.stringify(error);
    }
}
