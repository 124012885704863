import { ReactNode } from 'react';
import { Button } from '@mui/material';

import useStyles from './ConversationToolbar.styles';

export type Props = {
    flagged: boolean;
    handleFlag: () => void;
    hideFlagButton: boolean;
    children: ReactNode;
    isEmpty?: boolean;
};

const ConversationToolbar = ({ flagged, handleFlag, hideFlagButton, children, isEmpty = false }: Props) => {
    const { classes, cx } = useStyles();

    if (!isEmpty) {
        return (
            <div className={hideFlagButton ? cx(classes.root, classes.hideFlagButton) : classes.root}>
                {!hideFlagButton && (
                    <Button
                        size='small'
                        className={cx(
                            classes.button,
                            classes.flagButton,
                            flagged ? classes.flagged : classes.unflagged
                        )}
                        variant='contained'
                        onClick={handleFlag}
                        disableRipple
                    >
                        {flagged ? 'Fjern flagg' : 'Flagg samtale'}
                    </Button>
                )}
                <>{children}</>
            </div>
        );
    }
    return null;
};

export default ConversationToolbar;
