import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        height: 'fit-content',
        flexGrow: 2,
        minWidth: 300,
    },
    floatingActionButton: {
        position: 'fixed',
        right: 50,
        bottom: 50,
    },
    filterIcon: {
        marginRight: theme.spacing(2),
    },
    filterLayout: {
        maxWidth: 260,
        padding: theme.spacing(4),
    },
    drawerPaper: {
        borderRadius: 0,
    },
    drawerLayout: {
        width: 260,
    },
}));
