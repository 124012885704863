import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        boxShadow: 'inset 0 -1px rgb(0 0 0 / 5%)',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4),
        },
    },
    header: {
        display: 'flex',
        marginBottom: theme.spacing(3),
    },
    phoneNumbers: {
        display: 'flex',
        '& > div:not(:last-child)': {
            marginRight: theme.spacing(4),
        },
        [theme.breakpoints.down('md')]: {
            '& > div:not(:last-child)': {
                marginRight: theme.spacing(3),
            },
        },
    },
    customerName: {
        fontSize: '1.5em',
        fontWeight: 500,
        display: 'block',
        paddingBottom: theme.spacing(2),
    },
    labelText: {
        fontSize: '1.1em',
        '&::first-letter': {
            textTransform: 'uppercase',
        },
    },
    expandedBox: {
        transition: '0.2s all ease',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(3),
    },
    hidden: {
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    deleteButton: {
        alignSelf: 'flex-start',
    },
}));
