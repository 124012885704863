import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import SmallLabel from '../../../components/Typography/SmallLabel/SmallLabel';
import { setActiveStoreLocation } from '../../../state/ducks/system/ActiveStoreLocation';
import { useConfigValues, useSelector } from '../../../state/hooks';

type Props = {};

const ActiveStoreLocationContainer = ({}: Props) => {
    const configValues = useConfigValues();
    const { activeStoreLocation } = useSelector(state => state.system);
    const dispatch = useDispatch();

    const storeOptions = configValues?.system.storeOptions;
    useEffect(() => {
        if (!activeStoreLocation && storeOptions && storeOptions.length > 0) {
            dispatch(setActiveStoreLocation(storeOptions[0].id));
        }
    }, [activeStoreLocation, storeOptions]);

    const handleChange = (event: SelectChangeEvent) => {
        dispatch(setActiveStoreLocation(event.target.value));
    };
    if (!storeOptions || !activeStoreLocation) return <SubtleSpinner text='Henter butikk...' />;
    if (storeOptions && storeOptions.length === 1) return null;
    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <SmallLabel text={'Butikk'} />
            <Select value={activeStoreLocation} onChange={handleChange}>
                {storeOptions &&
                    storeOptions.map(store => (
                        <MenuItem key={store.id} value={store.id}>
                            {store.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default ActiveStoreLocationContainer;
