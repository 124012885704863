import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Plus } from 'react-feather';
import { Link } from 'react-router-dom';

import AttributeModal from '../../../components/Modals/CreateAttributeModal/AttributeModal';
import { ArticleAttribute, ArticleGroup } from '../../../utils/types';

type Props = {
    openCreateAttributeModal: boolean;
    toggleCreateAttributeModal: () => void;
    attributes: ArticleAttribute[];
    primaryArticleGroups: ArticleGroup[];
    secondaryArticleGroups: ArticleGroup[];
    tertiaryArticleGroups: ArticleGroup[];
    handleSaveAttribute: (attribute: ArticleAttribute) => void;
};

const Attributes = ({
    openCreateAttributeModal,
    toggleCreateAttributeModal,
    attributes,
    primaryArticleGroups,
    secondaryArticleGroups,
    tertiaryArticleGroups,
    handleSaveAttribute,
}: Props) => {
    return (
        <Box mt={5}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Typography variant='h3'>Attributter</Typography>
            </Box>
            <Grid container spacing={2}>
                {attributes.map(attribute => {
                    return (
                        <Grid key={attribute.articleAttributeId} item xs={12} sm={'auto'}>
                            <Link to={attribute.articleAttributeId.toString()} style={{ textDecoration: 'none' }}>
                                <Paper sx={{ p: 3 }}>
                                    <Typography variant='h3'>{attribute.name}</Typography>
                                </Paper>
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
            <Box mt={2}>
                <Button onClick={toggleCreateAttributeModal} variant='contained' color='primary' startIcon={<Plus />}>
                    Opprett nytt attributt
                </Button>
            </Box>
            <AttributeModal
                open={openCreateAttributeModal}
                title='Opprett nytt attributt'
                handleToggleModal={toggleCreateAttributeModal}
                handleSaveAttribute={handleSaveAttribute}
                primaryArticleGroups={primaryArticleGroups}
                secondaryArticleGroups={secondaryArticleGroups}
                tertiaryArticleGroups={tertiaryArticleGroups}
            />
        </Box>
    );
};

export default Attributes;
