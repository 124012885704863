import { makeStyles } from '../../../state/hooks';
import constants from '../../../styles/styleConstants';

export default makeStyles()(theme => ({
    track: {
        display: 'inline-flex',
        backgroundColor: '#DEDEDE',
        padding: '3px 2px 3px 1px',
        borderRadius: 30,
    },
    button: {
        backgroundColor: 'transparent',
        color: theme.palette.neutrals[600],
        padding: '5px 18px',
        margin: '0 1px 1px 2px',
        transition: `all ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
        borderRadius: 30,
        height: '100%',

        '&:active': {
            opacity: 0.6,
        },
    },
    active: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        cursor: 'initial',
        boxShadow: constants.longLightBoxShadow,

        '&:hover, &:active': {
            backgroundColor: 'white',
            opacity: 1,
        },
    },
    input: {
        display: 'none',
    },
    firstMenuItem: {
        borderBottom: '2px solid #f2f2f2',
        fontWeight: 500,
        marginBottom: 10,
    },
}));
