import { Button, CircularProgress, Container, Paper, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import InputField from '../components/Form/InputField/InputField';

import BackgroundPaper from '../components/Graphics/BackgroundPaper/BackgroundPaper';
import { makeStyles } from '../state/hooks';

const ResetPassword = () => {
    const { classes } = useStyles();
    const firebase = useFirebase();

    const [email, setEmail] = useState('');
    const [resetting, setResetting] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleReset = async () => {
        if (email !== '') {
            setResetting(true);
            await firebase.auth().sendPasswordResetEmail(email);
            setResetting(false);
            setSuccess(true);
            setEmail('');
        }
    };

    return (
        <Container maxWidth='lg' className={classes.container}>
            <BackgroundPaper className={classes.paper}>
                <Paper className={classes.root}>
                    <Typography variant='h3' sx={{ mb: 3 }}>
                        Glemt passord
                    </Typography>
                    {success && (
                        <Typography variant='body1' sx={{ mb: 3 }}>
                            ✅ Lenke for nullstilling av passord er sendt på epost! Husk å sjekke spam-mappen.
                        </Typography>
                    )}
                    <InputField
                        id='email'
                        textInputProps={{
                            disabled: resetting,
                        }}
                        value={email}
                        label='Email'
                        type='email'
                        onChange={(event: ChangeEvent<{ value: unknown }>) => setEmail(event.target.value as string)}
                        data-cy='login-email-field'
                    />
                    <Button
                        startIcon={resetting && <CircularProgress size={18} />}
                        sx={{ mt: 2 }}
                        variant='contained'
                        onClick={handleReset}
                        disabled={resetting}
                    >
                        Nullstill passordet
                    </Button>
                </Paper>
            </BackgroundPaper>
        </Container>
    );
};

const useStyles = makeStyles()(theme => ({
    container: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        display: 'flex',
        justifyContent: 'center',
    },
    paper: {
        minHeight: 600,
        borderRadius: 8,
    },
    root: {
        borderRadius: 4,
        width: 'clamp(200px, 60%, 400px)',
        padding: 30,
        paddingTop: 40,
    },
}));

export default ResetPassword;
