import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    popoverBox: {
        padding: theme.spacing(4),
        maxWidth: 300,
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    children: {
        marginBottom: theme.spacing(3),
    },
}));
