import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import LockEventHistoryTable from '../../../../../components/Tables/LockEventHistoryTable/LockEventHistoryTable';
import { useGetBikeboxEventHistoryQuery } from '../../../../../services/BikeBoxes';

type Props = {
    bikeBoxId: string;
};

const LockEventHistoryTableContainer = ({ bikeBoxId }: Props) => {
    const { data, isFetching, isError, error } = useGetBikeboxEventHistoryQuery({ id: bikeBoxId });

    if (isFetching) return <SubtleSpinner size={24} text='Laster historikk...' />;
    else if (!isError && data) return <LockEventHistoryTable lockEventHistory={data} />;
    else return <div>{error}</div>;
};

export default LockEventHistoryTableContainer;
