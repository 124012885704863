import { Box } from '@mui/material';

import Axis from '../../../components/Layouts/Axis/Axis';
import PlaceInventoryItemContainer from '../../../containers/PageSpecific/Inventory/PlaceInventoryItemContainer/PlaceInventoryItemContainer';

const PlaceInventoryItem = () => {
    return (
        <Box sx={{ marginTop: 5 }}>
            <Axis isResponsive gap={4}>
                <PlaceInventoryItemContainer />
            </Axis>
        </Box>
    );
};

export default PlaceInventoryItem;
