import { FutureOrders } from './types';
import Order from '../models/Order';
import Conversation from '../models/Conversation';

/**
 * Sorts orders into a two-dimensional array of type FutureOrders, where orders are grouped by their delivery dates.
 * @param orders A list of Order objects
 * @returns A two-dimensional array of type FutureOrders
 */
export function sortFutureOrders(orders: Order[]): FutureOrders {
    let futureOrders: FutureOrders = [];
    let knowDates = new Set<string>();
    let currentColumn = -1; // Has to be -1 because of first iteration (currentColumn + 1 = 0)

    orders.forEach((order: Order) => {
        const date = order?.deliveryDate ?? order?.orderDate;

        if (date) {
            // Date string for comparison between current and previous date in array
            const currentDate = date.toDateString();
            if (knowDates.has(currentDate)) {
                futureOrders[currentColumn].push(order);
            } else {
                // Add new empty "column" array to futureOrders
                futureOrders.push([]);
                currentColumn++;

                futureOrders[currentColumn].push(order);
                knowDates.add(currentDate);
            }
        }
    });
    return futureOrders;
}

/**
 * Filters orders by the given template id
 * @param order The order to filter
 * @param templateId The given template id
 * @returns True or false depending on matching template id.
 */
export function filterOrderByTemplateId(order: Order, templateId: number) {
    if (templateId === 0) return true;
    return order.templateId === templateId;
}

/**
 * Filters and sorts orders on template id
 * @param orders A list of Order objects
 * @param templateId The template id to sort and filter on
 * @returns A sorted and filtered list of Order objets.
 */
// TODO check that mutate removal was successful
export function filterAndSortOrders(orders: Order[], templateId: number): Order[] {
    if (templateId > 0) {
        return [...orders].filter(order => filterOrderByTemplateId(order, templateId));
    }
    return [...orders].sort((a, b) => b.templateId - a.templateId);
}

/**
 * Sort function that sorts Order objects on delivery date
 * @param a Order A
 * @param b Order B
 * @returns 1, -1 or 0
 */
export function sortOnDeliveryDate(a: Order, b: Order) {
    if (a?.deliveryDate && b?.deliveryDate) {
        return a.deliveryDate.getTime() - b.deliveryDate.getTime();
    }
    return 0;
}

/**
 * Sort function for conversations that sort on latestMessageDate desc
 * @param a
 * @param b
 * @returns 1, -1 or 0
 */
export function sortConversationsDesc(a: Conversation, b: Conversation): number {
    if (a?.latestMessageDate && b?.latestMessageDate) {
        if (a.latestMessageDate > b.latestMessageDate) return -1;
        if (a.latestMessageDate < b.latestMessageDate) return 1;
    }
    return 0;
}
