export const initialCriticalFilter = {
    employees: 0,
    orderTemplates: 0,
    errorMessages: 0,
    sort: 'price-asc',
};

export const defaultDateFormat = 'dd/MM/yyyy';

export const versionNumber = '1.9.0';
