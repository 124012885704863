import PhoneNumber from '../../../models/PhoneNumber';

export const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION';
export const SET_ACTIVE_PHONE_NUMBER = 'SET_ACTIVE_PHONE_NUMBER';

export const setActiveConversation = (customerNumber: string | null) => ({
    type: SET_ACTIVE_CONVERSATION,
    payload: { customerNumber },
});

export const setActivePhoneNumber = (phoneNumber: PhoneNumber | null) => ({
    type: SET_ACTIVE_PHONE_NUMBER,
    payload: { phoneNumber },
});
