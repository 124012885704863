import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    monthName: {
        textTransform: 'capitalize',
        color: theme.palette.neutrals[500],
    },
}));
