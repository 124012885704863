import { ButtonBase, Paper, Typography } from '@mui/material';
import useStyles from './ReservationCalendarCard.styles';

export type Props = {
    date: Date | undefined;
    onClick: (date: Date) => void;
    isDisabled: boolean;
    available?: boolean;
    selected?: boolean;
    rangeSelected?: boolean;
    isMobile?: boolean;
};

const ReservationCalendarCard = ({
    onClick,
    isDisabled,
    available = false,
    selected = false,
    rangeSelected = false,
    date,
    isMobile = false,
}: Props) => {
    const { classes, cx } = useStyles();
    if (date === undefined) return <Paper elevation={0} className={cx(classes.root, classes.blank)}></Paper>;

    let styles = classes.root;
    if (available) styles = cx(styles, classes.available, classes.interactable);
    if (selected) styles = cx(styles, classes.selected, classes.interactable);
    if (rangeSelected) styles = cx(styles, classes.rangeSelected, classes.interactable);

    const showSecondaryText = !isMobile && available && !selected && !rangeSelected;
    const secondaryTextClass = showSecondaryText ? '' : classes.hidden;

    return (
        <ButtonBase disabled={isDisabled} disableRipple className={styles} onClick={() => onClick(date)}>
            <Typography>{date.getDate()}.</Typography>
            <Typography className={secondaryTextClass} aria-hidden={!showSecondaryText}>
                'Ledig'
            </Typography>
        </ButtonBase>
    );
};

export default ReservationCalendarCard;
