import { Table, TableHead, TableBody, TableCell, TableRow, Grid, Typography, Chip, ChipTypeMap } from '@mui/material';

import { ArticleVariant } from '../../../models/Article';
import { getArticleVariantName, getStockColor } from '../../../utils/helpers';
import InventoryLocationCard from '../../Cards/InventoryLocationCard/InventoryLocationCard';
import Axis from '../../Layouts/Axis/Axis';
import ExpandableTableRow from '../ExpandableTableRow/ExpandableTableRow';

export type Props = {
    articleName: string;
    articleVariants: ArticleVariant[];
    hasVariants: boolean;
    handleFetchLocations: (sizeColorId: number | null) => void;
    handleRemoveFromProductLocation: (
        locationId: number,
        placementId: number | null,
        sizeColorId: number | null
    ) => void;
};

const ArticleStockTable = ({
    articleName,
    articleVariants,
    hasVariants,
    handleFetchLocations,
    handleRemoveFromProductLocation,
}: Props) => {
    const tableRows = articleVariants.map(variant => {
        const variantStock = variant.stock?.available;
        let inStockColor: ChipTypeMap['props']['color'] = variantStock > 0 ? 'success' : 'default';
        if (variantStock === 1) inStockColor = 'warning';
        if (variantStock < 0) inStockColor = 'error';

        const tableCells = (
            <>
                <TableCell>{variant.articleNumber}</TableCell>
                {hasVariants && (
                    <>
                        <TableCell>{variant.color?.colorName ?? '-'}</TableCell>
                        <TableCell>{variant.size?.sizeName ?? '-'}</TableCell>
                    </>
                )}
                <TableCell sx={{ color: getStockColor(variant.stock.available) }}>
                    <Chip
                        size='small'
                        label={variantStock ?? '-'}
                        color={inStockColor}
                        variant={variantStock < 0 ? 'outlined' : 'filled'}
                    />
                </TableCell>
                <TableCell sx={{ color: getStockColor(variant.stock.inTransit, true) }}>
                    {variant.stock?.inTransit ?? '-'}
                </TableCell>
                <TableCell>{variant.stock?.inOrder ?? '-'}</TableCell>
            </>
        );

        const noPlacements = !variant.inventoryLocations || variant.inventoryLocations.length === 0;

        return (
            <ExpandableTableRow
                key={variant.articleNumber}
                columnCount={hasVariants ? 6 : 4}
                tableCells={tableCells}
                onOpen={() => handleFetchLocations(variant.sizeColorId)}
            >
                <Typography variant='h5' sx={{ mb: 2, mt: 2 }}>
                    Plasseringer:
                </Typography>
                {!noPlacements ? (
                    <Grid container spacing={2}>
                        {variant.inventoryLocations.map(location => {
                            const articleVariantName = getArticleVariantName(
                                articleName,
                                variant.color?.colorName ?? null,
                                variant.size?.sizeName ?? null
                            );
                            return (
                                <Grid item key={location.placementId ?? location.locationId}>
                                    <InventoryLocationCard
                                        locationId={location.locationId}
                                        placementId={location.placementId}
                                        sizeColorId={variant.sizeColorId}
                                        locationName={location.placementName ?? location.locationName}
                                        count={location.count}
                                        articleName={articleVariantName}
                                        handleMoveArticle={() => {}}
                                        handleRemoveArticle={handleRemoveFromProductLocation}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    <Typography variant='body1' sx={{ mb: 2 }}>
                        Ingen plasseringer
                    </Typography>
                )}
            </ExpandableTableRow>
        );
    });

    return (
        <Table aria-label='orderlines table' stickyHeader={true} sx={{ overflowX: 'auto' }}>
            <TableHead sx={{ '& th': { backgroundColor: '#fff' } }}>
                <TableRow>
                    <TableCell>Artikkelnummer</TableCell>
                    {hasVariants && (
                        <>
                            <TableCell>Farge</TableCell>
                            <TableCell>I Størrelse</TableCell>
                        </>
                    )}
                    <TableCell>Disponibel</TableCell>
                    <TableCell>I bestilling</TableCell>
                    <TableCell>I ordre</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>{articleVariants && tableRows}</TableBody>
        </Table>
    );
};

export default ArticleStockTable;
