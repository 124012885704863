import { ArrowLeft, ArrowRight } from 'react-feather';

import CircularButton from '../CircularButton/CircularButton';
import constants from '../../../styles/styleConstants';
import { makeStyles } from '../../../state/hooks';

export type Props = {
    navigateRight: () => void;
    navigateLeft: () => void;
    controlsActive: {
        left: boolean;
        right: boolean;
    };
    size?: 'normal' | 'small';
};

/**
 * Component representing horizontal navigation using left and right buttons.
 */
const HorizontalNavigationButtons = ({ navigateRight, navigateLeft, controlsActive, size = 'normal' }: Props) => {
    const { classes } = useStyles();

    const iconSize = size === 'small' ? 26 : 28;

    return (
        <div className={classes.root}>
            <div className={classes.buttonBackground} style={{ marginRight: 0 }}>
                <CircularButton
                    color='primary'
                    className={classes.button}
                    handleClick={navigateLeft}
                    disabled={!controlsActive.left}
                    size={size === 'normal' ? 'large' : 'normal'}
                >
                    <ArrowLeft size={iconSize} />
                </CircularButton>
            </div>
            <div className={classes.buttonBackground}>
                <CircularButton
                    color='primary'
                    className={classes.button}
                    handleClick={navigateRight}
                    disabled={!controlsActive.right}
                    size={size === 'normal' ? 'large' : 'normal'}
                >
                    <ArrowRight size={iconSize} />
                </CircularButton>
            </div>
        </div>
    );
};
const useStyles = makeStyles()({
    root: {
        borderRadius: 40,
        backgroundColor: 'white',
        boxShadow: constants.longLightBoxShadow,
        width: 'fit-content',
    },
    buttonBackground: {
        display: 'inline-block',
        backgroundColor: 'white',
        borderRadius: 30,
        margin: 3,
    },
    button: {
        pointerEvents: 'initial !important' as any,
    },
});

export default HorizontalNavigationButtons;
