import { Box, Button, Card, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { Edit } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArticleAttributeValuesModal, {
    ArticleAttributeValueWithStatus,
} from '../../../../components/Modals/ArticleAttributeValuesModal/ArticleAttributeValuesModal';
import PromptModal from '../../../../components/Modals/PromptModal/PromptModal';

import NestedBreadcrumbs from '../../../../components/Navigation/NestedBreadcrumbs/NestedBreadcrumbs';
import {
    deleteArticleAttribute,
    saveArticleAttributeValues,
} from '../../../../state/ducks/inventory/ArticleAttributes';
import { ArticleAttribute as ArticleAttributeType } from '../../../../utils/types';

export type Props = {
    articleAttribute: ArticleAttributeType;
};

const ArticleAttribute = ({ articleAttribute }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDeleteAttributeModal, setShowDeleteAttributeModal] = useState(false);
    const [showEditLocationModal, setShowEditLocationModal] = useState(false);

    const handleDeleteAttribute = () => {
        dispatch(deleteArticleAttribute(articleAttribute.articleAttributeId, handleDeleteAttributeCallback));
    };

    const handleDeleteAttributeCallback = () => {
        navigate('../', { replace: true });
    };

    const toggleEditAttributeModal = () => {
        setShowEditLocationModal(!showEditLocationModal);
    };

    const handleSaveAttribute = (attributeValues: ArticleAttributeValueWithStatus[]) => {
        dispatch(
            saveArticleAttributeValues(articleAttribute.articleAttributeId, attributeValues, toggleEditAttributeModal)
        );
    };

    return (
        <Box sx={{ mt: 5 }}>
            <NestedBreadcrumbs currentPathname={articleAttribute.name} parentPath='Attributter' />
            <Paper
                sx={{
                    mt: 4,
                    p: {
                        xs: 4,
                        sm: 5,
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h2'>{articleAttribute.name}</Typography>
                    <Button
                        variant='text'
                        color='error'
                        onClick={() => setShowDeleteAttributeModal(!showDeleteAttributeModal)}
                    >
                        Slett attributt
                    </Button>
                </Box>
                <Typography variant='h3' sx={{ mt: 4, mb: 2 }}>
                    Attributt verdier
                </Typography>
                <Grid container spacing={3}>
                    {articleAttribute.articleAttributeValues &&
                        articleAttribute.articleAttributeValues.map(attributeValue => (
                            <Grid item key={attributeValue.articleAttributeValueId}>
                                <Card
                                    sx={{
                                        p: 3,
                                        backgroundColor: 'neutrals.50',
                                    }}
                                >
                                    <Typography variant='h5'>{attributeValue.name}</Typography>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
                <Box sx={{ mt: 5 }}>
                    <Button onClick={toggleEditAttributeModal} variant='contained' startIcon={<Edit />}>
                        Endre attributt verdier
                    </Button>
                </Box>
            </Paper>
            <PromptModal
                maxWidth='sm'
                openModal={showDeleteAttributeModal}
                title={`Slette attributten "${articleAttribute.name}"?`}
                description='Er du sikker på at du vil slette denne attributten? Dette vil også slette alle verdier knyttet opp mot artikler.'
                toggleModal={() => setShowDeleteAttributeModal(!showDeleteAttributeModal)}
                leftButton={
                    <Button onClick={() => setShowDeleteAttributeModal(!showDeleteAttributeModal)}>Avbryt</Button>
                }
                rightButton={
                    <Button onClick={handleDeleteAttribute} variant='contained' color='error'>
                        Slett attributten
                    </Button>
                }
            />
            {articleAttribute.articleAttributeValues && (
                <ArticleAttributeValuesModal
                    open={showEditLocationModal}
                    toggleOpen={toggleEditAttributeModal}
                    initialValues={articleAttribute.articleAttributeValues}
                    handleSaveAttribute={handleSaveAttribute}
                />
            )}
        </Box>
    );
};

export default ArticleAttribute;
